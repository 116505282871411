import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

interface ChartState {
  data: any;
}

const initialState: ChartState = { data: {} };

const chartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    setChart(state, action: PayloadAction<any>) {
      state.data = action.payload;
    },
  },
});

export const { setChart } = chartSlice.actions;
export default chartSlice.reducer;

export const selectChart = (state: RootState) => state.chart.data;
