import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { setAmountSent, setCurrencyTransfer } from 'store/slice/walletSlice';
import { ReactComponent as ChangeSVG } from 'pages/DEX-exchange/svg/change.svg';

const AmountInput: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rate = useSelector((state: RootState) => state.exchangeRate.rate) || 1;
  const { amount } = useSelector((state: RootState) => state.wallet);
  const [inputAmount, setInputAmount] = useState<string>('0.0');
  const [isUSD, setIsUSD] = useState<boolean>(false);

  const handleToggleCurrency = () => {
    setIsUSD(!isUSD);
    dispatch(setCurrencyTransfer(isUSD ? 'SOL' : 'USD'));
  };

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // Разрешаем ввод только чисел и одной точки
    if (!/^\d*\.?\d*$/.test(value)) return;

    let [integerPart, decimalPart] = value.split('.');

    // Убираем лишние ведущие нули
    if (integerPart.length > 1 && integerPart.startsWith('0') && integerPart !== '0') {
      integerPart = '0';
    }

    // Обрезаем число после точки
    if (decimalPart !== undefined) {
      decimalPart = isUSD ? decimalPart.slice(0, 2) : decimalPart.slice(0, 6);
      value = decimalPart.length > 0 ? `${integerPart}.${decimalPart}` : `${integerPart}.`;
    } else {
      value = integerPart;
    }

    // Проверяем на максимальное значение
    const numericValue = parseFloat(value);
    const max = isUSD ? amount * rate : amount;

    if (!isNaN(numericValue)) {
      setInputAmount(numericValue > max ? max.toString() : value);
    } else {
      setInputAmount(value);
    }
  };

  const handleContinueClick = () => {
    dispatch(setAmountSent(isUSD ? +inputAmount / rate : +inputAmount));
    navigate('/dex/withdraw-confirmation');
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#000',
        color: '#fff',
        padding: '32px 16px 0',
      }}>
      <Box sx={{ width: '100%', display: 'flex', marginBottom: '24px' }}>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#888',
            marginRight: '8px',
            lineHeight: '12px',
          }}>
          Available: {isUSD ? `${(amount * rate)?.toFixed(2)} USD` : `${amount?.toFixed(6)} SOL`}
        </Typography>
        <Button
          onClick={() => setInputAmount(isUSD ? (amount * rate).toString() : amount.toString())}
          sx={{
            fontSize: '12px',
            color: '#0049F0',
            textTransform: 'none',
            padding: 0,
            minWidth: 'auto',
            lineHeight: '12px',
          }}>
          Max
        </Button>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '40px',
        }}>
        <TextField
          type="text"
          value={inputAmount}
          onChange={handleChangeAmount}
          variant="standard"
          inputProps={{
            inputMode: 'decimal',
            pattern: '[0-9]*[.,]?[0-9]*',
            style: {
              fontSize: '40px',
              fontWeight: 600,
              color: '#fff',
              textAlign: 'left',
              height: '40px',
            },
          }}
          sx={{
            flex: 1,
            marginRight: '8px',
            '& .MuiInput-underline:before': { borderBottom: 'none' },
            '& .MuiInput-underline:after': { borderBottom: 'none' },
          }}
        />
        <Typography
          sx={{
            fontSize: '40px',
            fontWeight: 600,
            color: '#888',
            whiteSpace: 'nowrap',
            lineHeight: '40px',
          }}>
          {isUSD ? 'USD' : 'SOL'}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '16px',
          width: '100%',
        }}>
        <Typography sx={{
          fontSize: '14px',
          marginRight: '10px',
          marginBottom: 0,
        }}>{isUSD ? `${inputAmount ? (parseFloat(inputAmount) / rate).toFixed(6) : 0} SOL` : `${inputAmount ? (parseFloat(inputAmount) * rate).toFixed(2) : 0} USD`}</Typography>
        <ChangeSVG onClick={handleToggleCurrency} style={{ width: '12px' }} />
      </Box>
      <Button
        fullWidth
        onClick={handleContinueClick}
        disabled={!inputAmount || parseFloat(inputAmount) <= 0}
        sx={{
          maxWidth: '400px',
          backgroundColor: '#2163FA',
          color: '#fff',
          fontSize: '14px',
          textTransform: 'none',
          borderRadius: '8px',
          height: '40px',
          margin: '0 16px',
          '&:hover': { backgroundColor: '#0035A1' },
        }}>
        Continue
      </Button>
    </Box>
  );
};

export default AmountInput;
