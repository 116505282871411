import { motion } from 'framer-motion'
import React, { useContext, useEffect, useRef, useState } from 'react'
import GameEnergy from 'UI/GameEnergy'
import PageWrapper from 'UI/PageWrapper'
import appContext from 'utils/app-context'
import tg from 'utils/tg'
import sendTaps from 'api/send-taps'
import GameField from './GameField'
import startGame from 'api/start-game'
import PendingButton from 'UI/PendingButton'
import { toast } from 'react-toastify'
const storage = process.env.REACT_APP_STORAGE_URL || '';

interface TapAnimation {
  id: number;
  x: number;
  y: number;
}

const Game = () => {
  const [gameState, setGameState] = useState(false)
  const [tapCount, setTapCount] = useState(0);
  const [tapAnimations, setTapAnimations] = useState<TapAnimation[]>([]);
  const [nextId, setNextId] = useState(1);
  const {user,setUser,t,updateUserInfo,setshowGameRules} = useContext(appContext)
  const text = t('gamePage')
  const currentEnergy = user?.energy || 0
  const currentBalance = user?.balance
  const timer = useRef<NodeJS.Timeout | null>(null)
  
  const setcurrentEnergy = (e:number) => {
    if (user) setUser({...user,energy:e})
  } 
  const setcurrentBalance = (b:number) => {
    if (user) setUser({...user,balance:b})
  } 
  const toggleGameState = async () => {
    tg.HapticFeedback.selectionChanged()
    if (gameState) {
      setGameState(false)
    } else {
      const res = await startGame()
      console.log("🚀 ~ toggleGameState ~ res:", res)
      // updateUserInfo()
      if (res?.success) {
        setGameState(true)
      } else {
      tg.HapticFeedback.notificationOccurred('error')
      tg.showAlert(text.nonEnergyFroStart)
    }
    }
  } 

  const handleTap = (e:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if(!gameState) return
    if (!currentEnergy || !user) {
      tg.HapticFeedback?.notificationOccurred('error')
      return
    }
    setTapCount(tapCount+1)
    const {balance,energy} = user

    const nextEnergy = energy-1
    setUser({...user,balance:balance+user.tap_power,energy:nextEnergy})
    tg.HapticFeedback?.impactOccurred('soft')

    const x = e.clientX;
    const y = e.clientY - 60;

    const newAnimation = {
      id: nextId,
      x: x,
      y: y,
    };

    setTapAnimations([...tapAnimations, newAnimation]);
    setNextId(nextId + 1);
    setTimeout(() => {
      setTapAnimations((prev) => prev.filter((anim) => anim.id !== newAnimation.id));
    }, 1000);
  }


 const handleSendTaps = async () => {
  const result = await sendTaps(tapCount)
  console.log('result',result);
  if (result && result?.balance && user) {
    const {balance,energy,level} = result
    if(result.alert) toast(result.alert,{autoClose:1000,hideProgressBar:true,type:'success'})
    setUser({...user,balance,energy,level})
    setTapCount(0)
  // } else if(result?.errorMessage) tg.showAlert(result.errorMessage)
  } else if(result?.errorMessage) toast(result.errorMessage,{autoClose:1000,hideProgressBar:true,type:'warning'})
}

  useEffect(() => {
    if (!tapCount) return
    if (currentEnergy === 0) {
      setGameState(false)
      handleSendTaps().then(() => updateUserInfo())
      return
    }
    if(timer.current) clearTimeout(timer.current)
    timer.current = setTimeout(handleSendTaps, 300);

  }, [tapCount]);
  useEffect(() => {
    if (currentBalance === null && typeof user?.balance === 'number') {
      setcurrentBalance(user?.balance)
    }
    if (currentEnergy === null && typeof user?.energy === 'number') {
      setcurrentEnergy(user?.energy)
    }
  }, [user])
  if(!user) return null
  return (
    <PageWrapper>
      <div className="game-contain">
      <div>
        <div className="game-avatar">
          <div className="game-avatar__status">
            <img src={`${storage}/${user?.avatar}`} className="game-avatar__img" />
            <div className="game-avatar__contain">
              <p className="game-avatar__title">{user?.first_name}</p>
              <div className="game-avatar__block">
                <img src="./img/star-cup.svg" />
                <p className="game-avatar__text" onClick={() => setshowGameRules(true)}>{user?.level} {text.level}</p>
              </div>
            </div>
          </div>
            <button className="game-avatar__button" onClick={() => setshowGameRules(true)}>{ user.lang === 1 ? 'Правила': 'Rules'}</button>
        </div>
       <GameEnergy showEnergyCount showBalance/>
      </div>
      <GameField gameState={gameState} handleTap={handleTap} skin={user.skin} level={user.level}/>
      {tapAnimations.map((anim) => (
        <motion.div
          key={anim.id}
          transition={{duration:0.3}}
          animate={{translateY:-100,opacity:0}}
          style={{
            position:'absolute',
            left: anim.x,
            top: anim.y,
            fontSize:35,
            userSelect:'none'
          }}
        >
          +{user?.tap_power}
        </motion.div>
      ))}
      {!user?.energy_seconds
      ? <button className="game-button" onClick={toggleGameState}>{gameState ? text.stopGame : text.startGame}</button>
      : <PendingButton text={text.energyRecovery} timeStart={user.energy_seconds} callback={updateUserInfo}/> 
      }
    </div>
    </PageWrapper>
  )
}


export default Game