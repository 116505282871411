import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { ReactComponent as CopySVG } from 'pages/DEX-exchange/svg/copy.svg';
import { ReactComponent as WithdrawSVG } from 'pages/DEX-exchange/svg/withdrawConfirmation.svg';
import { ReactComponent as DoneSVG } from 'pages/DEX-exchange/svg/done.svg';
import { ReactComponent as FromSVG } from 'pages/DEX-exchange/svg/fromWallet.svg';
import { ReactComponent as ToSVG } from 'pages/DEX-exchange/svg/ToWallet.svg';
import { SolanaService } from './services/SolanaService';
import { EthereumService } from './services/EthereumService';
import { BlockchainService } from './services/BlockchainService';

const WithdrawConfirmation: React.FC = () => {
  const [transactionSignature, setTransactionSignature] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { destinationWalletAddress, address, amountSent } = useSelector((state: RootState) => state.wallet);
  const { blockchain } = useSelector((state: RootState) => state.wallet);
  const rate = useSelector((state: RootState) => state.exchangeRate.rate) || 1;
  const usdEquivalent = (amountSent * rate).toFixed(2);
  const [isSuccess, setIsSuccess] = useState(false);
  const [fees, setFees] = useState({
    gasFee: 'Calculating...',
    receivedAmount: 'Calculating...',
    slippage: 'Calculating...',
  });

  const blockchainService = useMemo(() => {
    if (!blockchain) {
      return;
    }
    if (blockchain.value === 'solana') {
      return SolanaService.getInstance(process.env.REACT_APP_HELIUS_RPC_URL!) as BlockchainService;
    } else if (blockchain.value === 'ethereum') {
      return EthereumService.getInstance() as BlockchainService;
    }
  }, [blockchain]);

  useEffect(() => {
    if (!blockchainService) {
      return;
    }

    const calculateFees = async () => {
      try {
        // Стандартный Slippage для solana (обычно 0 для обычных переводов)
        const slippage = 0;
        //Стандартный Gas для solana 0.000005 - https://solana.stackexchange.com/questions/1476/calculating-solana-fees
        setFees({
          gasFee: `$${(0.000005 * rate).toFixed(6)}`,
          receivedAmount: `${(amountSent - 0.000005)?.toFixed(6)} SOL`,
          slippage: `${slippage.toFixed(0)} %`,
        });
      } catch (error) {
        console.error('Ошибка вычисления комиссий и slippage:', error);
        setFees({ gasFee: 'N/A', receivedAmount: 'N/A', slippage: 'N/A' });
      }
    };

    calculateFees();
  }, [address, destinationWalletAddress, amountSent, blockchainService]);

  const handleReject = () => {
    navigate('/dex/scan-wallet');
  };

  const handleConfirmSending = async () => {
    try {
      setIsLoading(true);

      const balanceBefore = await blockchainService!.getBalance(address);
      const signature = await blockchainService!.sendTransaction(address, destinationWalletAddress, amountSent);

      // Проверяем статус транзакции до её полного подтверждения
      let status = null;
      while (true) {
        const statusResult = await blockchainService!.connection.getSignatureStatus(signature);
        status = statusResult.value?.confirmationStatus;

        if (status === 'finalized') {
          break;
        }

        await new Promise(resolve => setTimeout(resolve, 5000));
      }

      setTransactionSignature(signature);

      const balanceAfter = await blockchainService!.getBalance(address);
      const receivedBalance = await blockchainService!.getBalance(destinationWalletAddress);

      const gasFee = balanceBefore - balanceAfter - amountSent;
      const receivedAmount = receivedBalance;
      const slippage = 0;

      setFees({
        gasFee: gasFee.toFixed(6) + ' SOL',
        receivedAmount: receivedAmount.toFixed(6) + ' SOL',
        slippage: slippage.toFixed(2) + '%',
      });

      setIsSuccess(true);
    } catch (error) {
      console.error('❌ Ошибка отправки транзакции:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = (copyAddress: string) => {
    navigator.clipboard.writeText(copyAddress);
  };

  const goToHome = () => {
    navigate('/');
  };

  const handleOpenTransactionDetails = () => {
    if (transactionSignature) {
      window.open(`https://solscan.io/tx/${transactionSignature}`, '_blank');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#000',
        color: '#fff',
        padding: '32px 16px 28px',
      }}>
      {isSuccess ? (
        <DoneSVG
          style={{
            width: '40px',
            height: '40px',
            minHeight: '50px',
            marginBottom: '14px',
          }}
        />
      ) : (
        <WithdrawSVG
          style={{
            width: '30px',
            height: '35px',
            minHeight: '35px',
            marginBottom: '18px',
          }}
        />
      )}
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 500,
          color: isSuccess ? '#6EE297' : '#0049F0',
          marginBottom: '60px',
          lineHeight: '26px',
        }}>
        {isSuccess ? 'Completed' : 'Withdraw'}
      </Typography>
      <Typography
        sx={{
          fontSize: '40px',
          fontWeight: 600,
          color: '#fff',
          marginBottom: '16px',
          lineHeight: '40px',
        }}>
        -{amountSent.toFixed(6)} SOL
      </Typography>
      <Typography sx={{ fontSize: '14px', marginBottom: '50px', lineHeight: '14px' }}>${usdEquivalent}</Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '8px',
          marginBottom: '24px',
          width: '100%',
        }}>
        <Box
          sx={{
            backgroundColor: '#000',
            padding: '12px',
            borderRadius: '8px',
            textAlign: 'center',
            border: '1px solid #FFFFFF33',
          }}>
          <Typography sx={{ fontSize: '12px', color: '#888' }}>Sent</Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#fff' }}>{amountSent.toFixed(6)} SOL</Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: '#000',
            padding: '12px',
            borderRadius: '8px',
            textAlign: 'center',
            border: '1px solid #FFFFFF33',
          }}>
          <Typography sx={{ fontSize: '12px', color: '#888' }}>Received</Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#fff' }}>{fees.receivedAmount}</Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: '#000',
            padding: '12px',
            borderRadius: '8px',
            textAlign: 'center',
            border: '1px solid #FFFFFF33',
          }}>
          <Typography sx={{ fontSize: '12px', color: '#888' }}>Slippage</Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#fff' }}>{fees.slippage}</Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: '#000',
            padding: '12px',
            borderRadius: '8px',
            textAlign: 'center',
            border: '1px solid #FFFFFF33',
          }}>
          <Typography sx={{ fontSize: '12px', color: '#888' }}>Gas</Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#fff' }}>{fees.gasFee}</Typography>
        </Box>
      </Box>
      <Box sx={{ marginBottom: '16px', width: '100%' }}>
        <Typography sx={{ fontSize: '12px', color: '#888' }}>
          <FromSVG style={{ width: '8px', height: '8px' }} />
          <span className="tw-ml-2">From the wallet</span>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '8px 0 16px 16px',
            borderRadius: '8px',
          }}>
          <Typography
            sx={{
              maxWidth: '217px',
              fontSize: '14px',
              wordBreak: 'break-word',
              color: '#fff',
            }}>
            {address}
          </Typography>
          <IconButton onClick={() => handleCopy(address)} sx={{ color: '#fff' }}>
            <CopySVG style={{ width: '14px' }} />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ marginBottom: '16px', width: '100%' }}>
        <Typography sx={{ fontSize: '12px', color: '#888' }}>
          <ToSVG style={{ width: '8px', height: '8px' }} />
          <span className="tw-ml-2">To another wallet</span>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '8px 0 16px 16px',
            borderRadius: '8px',
          }}>
          <Typography
            sx={{
              maxWidth: '217px',
              fontSize: '14px',
              wordBreak: 'break-word',
              color: '#fff',
            }}>
            {destinationWalletAddress}
          </Typography>
          <IconButton onClick={() => handleCopy(destinationWalletAddress)} sx={{ color: '#fff' }}>
            <CopySVG style={{ width: '14px' }} />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          padding: '0 16px',
        }}>
        {isSuccess ? (
          <>
            <Button
              fullWidth
              onClick={handleOpenTransactionDetails}
              sx={{
                backgroundColor: '#222',
                color: '#fff',
                fontSize: '14px',
                textTransform: 'none',
                borderRadius: '8px',
                height: '40px',
                marginRight: '8px',
                '&:hover': { backgroundColor: '#444' },
              }}>
              Status
            </Button>
            <Button
              fullWidth
              onClick={goToHome}
              sx={{
                backgroundColor: '#2163FA',
                color: '#fff',
                fontSize: '14px',
                textTransform: 'none',
                borderRadius: '8px',
                height: '40px',
                '&:hover': { backgroundColor: '#0035A1' },
              }}>
              Close
            </Button>
          </>
        ) : (
          <>
            <Button
              fullWidth
              onClick={handleReject}
              sx={{
                backgroundColor: '#222',
                color: '#fff',
                fontSize: '14px',
                textTransform: 'none',
                borderRadius: '8px',
                height: '40px',
                marginRight: '8px',
                '&:hover': { backgroundColor: '#444' },
              }}>
              Reject
            </Button>
            <Button
              fullWidth
              onClick={handleConfirmSending}
              sx={{
                backgroundColor: '#2163FA',
                color: '#fff',
                fontSize: '14px',
                textTransform: 'none',
                borderRadius: '8px',
                height: '40px',
                '&:hover': { backgroundColor: '#0035A1' },
              }}>
              {isLoading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Confirm sending'}
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default WithdrawConfirmation;
