import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomSelect from '../../common/CustomSelect/CustomSelect';
import WalletCard from './WalletCard';
import HistoryTable from './HistoryTable';
import { RootState } from 'store/store';
import { Transaction } from './services/BlockchainService';
import { setAmount, setBlockchain } from 'store/slice/walletSlice';
import { openModal } from 'store/slice/modalSlice';
import AddFounds from 'pages/DEX-exchange/Modals/AddFounds';
import { useBlockchainService } from 'pages/DEX-exchange/helpers/blockchainServiceContext';

export type Option = {
  value: string;
  label: string;
  amount?: number;
  currency?: string;
};

const Wallet = () => {
  const dispatch = useDispatch();
  const [blockchainOptions, setBlockchainOptions] = useState<Option[]>([
    { value: 'solana', label: 'SOL', currency: '$' },
    // { value: "ethereum", label: "Ethereum", currency: "$" },
  ]);

  const [balance, setBalance] = useState<number>(0);
  const [transactionHistory, setTransactionHistory] = useState<Transaction[]>([]);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const rate = useSelector((state: RootState) => state.exchangeRate.rate);
  const { address, blockchain } = useSelector((state: RootState) => state.wallet);
  const blockchainService = useBlockchainService();
  // Функция получения баланса
  const fetchBalance = async () => {
    if (!address || !blockchainService) return;
    try {
      const bal = await blockchainService.getBalance(address);
      setBalance(bal);
    } catch (error) {
      console.error('Ошибка получения баланса:', error);
    }
  };

  // Функция получения истории транзакций
  const fetchHistory = async () => {
    if (!address || !blockchainService) return;
    try {
      const history = await blockchainService.getTransactionHistory(address);
      setTransactionHistory(history.slice(0,20));
    } catch (error) {
      console.error('Ошибка получения истории:', error);
    } finally {
      setLoadingHistory(false)
    }
  };

  // Подписка на обновление баланса
  useEffect(() => {
    if (address && blockchainService) {
      fetchBalance();
      fetchHistory();
      const subscriptionId = blockchainService.subscribeToBalanceChange(address, (newBalance: number) => {
        setBalance(newBalance);
      });
      return () => {
        blockchainService.unsubscribeFromBalanceChange(subscriptionId);
      };
    }
  }, [address, blockchainService]);

  // Обновляем blockchainOptions с актуальным балансом (с учетом курса)
  useEffect(() => {
    const updatedOptions = blockchainOptions.map(option => {
      if (option.value === blockchain?.value) {
        return {
          ...option,
          amount: balance * (rate || 1),
        };
      }
      return option;
    });
    setBlockchainOptions(updatedOptions);
    setBlockchainOption(updatedOptions.find(option => option.value === blockchain.value)!);
  }, [balance, rate]);

  const setBlockchainOption = (data: Option) => {
    dispatch(setBlockchain(data));
  };

  useEffect(() => {
    dispatch(setAmount(balance));
  }, [balance]);

  return (
    <div className="bg-black text-white flex flex-col">
      {blockchain && <CustomSelect options={blockchainOptions} value={blockchain!} onChange={setBlockchainOption} disabled={true} />}
      <div className="tw-mb-4 tw-bg-[#FFFFFF1A] tw-h-[1px]" />
      <WalletCard
        balance={balance}
        setIsModalOpen={() => {
          dispatch(
            openModal({
              title: 'Add founds',
              component: <AddFounds walletAddress={address}
                                    payLink={blockchainService?.generatePaymentLink(address)!} />,
            }),
          );
        }}
      />
      <HistoryTable transactions={transactionHistory} loading={loadingHistory} />
    </div>
  );
};

export default Wallet;
