import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Skeleton, Typography } from '@mui/material';
import { Connection, PublicKey } from '@solana/web3.js';
import { styled } from '@mui/material/styles';
import { ReactComponent as SolanaSVG } from 'pages/DEX-exchange/svg/solana-mock.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { getAllTransactions } from 'pages/DEX-exchange/Components/Positions/services/transactions';
import { getCoinsByAddressesFromGecko } from 'pages/DEX-exchange/api/coinGeckoApi';
import { useNavigate } from 'react-router-dom';

const AvatarImg = styled('img')({
  width: 36,
  height: 36,
  borderRadius: '50%',
  objectFit: 'cover',
});

const Positions: React.FC = () => {
  const navigate = useNavigate();
  const walletAddress = useSelector((state: RootState) => state.wallet.address);
  const [positions, setPositions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [hasRequest, setHasRequest] = useState(false);
  const rate = useSelector((state: RootState) => state.exchangeRate.rate);

  useEffect(() => {
    const fetchTokenPositions = async () => {
      if(hasRequest){
        return;
      }
      setHasRequest(true);
      try {
        const connection = new Connection(process.env.REACT_APP_HELIUS_RPC_URL!, 'confirmed');
        const ownerPublicKey = new PublicKey(walletAddress);

        // Запрашиваем все токеновые счета владельца
        const tokenAccountsResponse = await connection.getParsedTokenAccountsByOwner(ownerPublicKey, {
          programId: new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'),
        });
        // Фильтруем токеновые счета с нулевым балансом
        const tokenAccounts = tokenAccountsResponse.value.filter(token =>
          token.account.data.parsed.info.tokenAmount.uiAmount > 0
        );

        const tokenResults: Record<string, any> = {};

        // Обрабатываем каждый токеновый счет параллельно, ограничивая количество транзакций
        await Promise.all(
          tokenAccounts.map(async (token) => {
            const mint = token.account.data.parsed.info.mint;
            if (!tokenResults[mint]) {
              tokenResults[mint] = {
                mint,
                invested: 0,
                sold: 0,
                tokensBought: 0,
                tokensSold: 0,
                remainingTokens: token.account.data.parsed.info.tokenAmount.uiAmount,
              };
            }
            // Используем лимит последних 10 транзакций для ускорения
            const tokenAddress = new PublicKey(token.pubkey);
            const signaturesInfo = await connection.getSignaturesForAddress(tokenAddress, { limit: 100 });
            const allSignatures = signaturesInfo.map(item => item.signature);

            // Запрашиваем данные по транзакциям параллельно
            const allTransactionsArrays = await Promise.all(
              allSignatures.map(signature => getAllTransactions([signature]))
            );
            // Если getAllTransactions возвращает массивы, объединяем их
            const allTransactions = allTransactionsArrays.flat();

            allTransactions.forEach((tx) => {
              // Предполагаем, что tx.accountData – массив объектов с полем nativeBalanceChange
              const transfer = tx.accountData.find((t: any) => t.account === walletAddress);
              if (!transfer) return;
              if (transfer.nativeBalanceChange < 0) {
                tokenResults[mint].invested += Math.abs(transfer.nativeBalanceChange / 1e9);
                tokenResults[mint].tokensBought += Math.abs(transfer.nativeBalanceChange);
              } else {
                tokenResults[mint].sold += Math.abs(transfer.nativeBalanceChange / 1e9);
                tokenResults[mint].tokensSold += Math.abs(transfer.nativeBalanceChange);
              }
            });
          })
        );

        // Собираем данные по токенам
        const tokens = Object.values(tokenResults);
        const tokenDataResult = await getCoinsByAddressesFromGecko(tokens.map((token: any) => token.mint));
        const positionsResult = tokens.map((token: any) => {
          const tokenData = tokenDataResult.data.find((e: any) =>
            e?.attributes?.address?.toLowerCase() === token.mint.toLowerCase()
          );
          const relation = tokenDataResult.included.find((e: any) =>
            e.relationships.base_token.data.id.replace('solana_', '').toLowerCase() === token.mint.toLowerCase()
          );
          const remaining = token.remainingTokens * tokenData?.attributes?.price_usd;
          const investedInUsd = token.invested * rate!;
          const soldUsd = token.sold * rate!;
          const pnl = remaining + soldUsd - investedInUsd;
          const pnlPercent = investedInUsd > 0 ? (pnl / investedInUsd) * 100 : 0;
          return {
            ...token,
            ...tokenData?.attributes,
            remaining,
            poolAddress: relation?.attributes?.address,
            pnl,
            pnlPercent,
            sold: token.sold,
          };
        }).filter(e => !!e.remaining);

        setPositions(positionsResult);
      } catch (error) {
        console.error('Ошибка при получении токеновых позиций:', error);
      } finally {
        setLoading(false);
        setHasRequest(false)
      }
    };

    fetchTokenPositions();
  }, [walletAddress, rate]);

  function goTo(token: string) {
    navigate(`token/${token}`);
  }

  return (
    <Box sx={{ width: 'calc(100% + 16px)', maxHeight: 'calc(100vh - 187px)', display: 'flex', flexDirection: 'column', margin: '0 -8px' }}>
      <Typography sx={{
        display: 'flex',
        justifyContent: 'space-between',
        color: '#fff',
        marginBottom: '16px',
        padding: '0 16px 17px',
        fontSize: '16px',
        fontWeight: 'bold',
        borderBottom: '1px solid #ffffff',
      }}>
        Positions
      </Typography>
      {!loading && !positions.length ? (
        <Typography sx={{ display: 'flex', justifyContent: 'center', color: '#fff' }}>
          No tokens found in wallet.
        </Typography>
      ) : (
        (loading ? Array.from(new Array(20)) : positions).map((position: any, index: number) => (
          <Box
            key={position?.id || index}
            sx={{ backgroundColor: '#15161B', borderRadius: '10px', padding: '8px 12px 8px 8px', marginBottom: '16px', cursor: 'pointer' }}
            onClick={() => !loading && goTo(position.poolAddress)}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '8px',
                marginBottom: '8px',
                borderBottom: '1px solid #FFFFFF1A',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Box display="flex" alignItems="center">
                  <Box position="relative" mr={2}>
                    {loading ? (
                      <Skeleton variant="circular" width={36} height={36} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                    ) : (
                      <>
                        <AvatarImg src={position.image_url} alt="token avatar" />
                        <SolanaSVG
                          style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            width: '12px',
                            height: '12px',
                          }}
                        />
                      </>
                    )}
                  </Box>
                  <Box display="flex" flexDirection="column">
                    {loading ? (
                      <Skeleton variant="text" width={80} height={12} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                    ) : (
                      <Typography sx={{ fontSize: 12, color: '#fff' }}>
                        {position.name.length > 15 ? position.name.slice(0, 15) + '...' : position.name} / SOL
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '12px',
                  color: '#FFFFFF66',
                }}
              >
                {loading ? (
                  <Skeleton variant="text" width={50} height={12} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                ) : (
                  <>
                    PnL
                    <Typography sx={{ color: +position.pnlPercent > 0 ? '#6EE297' : '#C35268', marginLeft: '4px', fontSize: '12px' }}>
                      ${(+position.pnl).toFixed(2)}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '12px', color: '#fff' }}>
              <Box sx={{ minWidth: '25%', width: '25%', maxWidth: '25%' }}>
                <Typography sx={{ color: '#FFFFFF99', fontSize: '10px' }}>Invested</Typography>
                {loading ? (
                  <Skeleton variant="text" width="50%" height={18} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                ) : (
                  <Typography sx={{ fontSize: '12px' }}>
                    {(+position.invested * rate!).toFixed(2)}
                  </Typography>
                )}
              </Box>
              <Box sx={{ minWidth: '25%', width: '25%', maxWidth: '25%' }}>
                <Typography sx={{ color: '#FFFFFF99', fontSize: '10px' }}>Sold</Typography>
                {loading ? (
                  <Skeleton variant="text" width="50%" height={18} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                ) : (
                  <Typography sx={{ color: '#C35268', fontSize: '12px' }}>
                    {(+position.sold).toFixed(2)}
                  </Typography>
                )}
              </Box>
              <Box sx={{ minWidth: '25%', width: '25%', maxWidth: '25%' }}>
                <Typography sx={{ color: '#FFFFFF99', fontSize: '10px' }}>Remaining</Typography>
                {loading ? (
                  <Skeleton variant="text" width="50%" height={18} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                ) : (
                  <Typography sx={{ fontSize: '12px' }}>
                    {(+position.remaining).toFixed(2)}
                  </Typography>
                )}
              </Box>
              <Box sx={{ minWidth: '25%', width: '25%', maxWidth: '25%' }}>
                <Typography sx={{ color: '#FFFFFF99', fontSize: '10px' }}>PnL%</Typography>
                {loading ? (
                  <Skeleton variant="text" width="50%" height={18} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                ) : (
                  <Typography
                    sx={{
                      color: +position.pnlPercent > 0 ? '#6EE297' : '#C35268',
                      fontSize: '12px',
                    }}
                  >
                    {(+position.pnlPercent).toFixed(2)}%
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        ))
      )}
    </Box>
  );
};

export default Positions;
