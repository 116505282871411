import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

interface TokenState {
  data: any[];
}

const initialState: TokenState = { data: [] };

const tokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    setTokens(state, action: PayloadAction<any[]>) {
      state.data = action.payload;
    },
  },
});

export const { setTokens } = tokensSlice.actions;
export default tokensSlice.reducer;

export const selectTokens = (state: RootState) => state.tokens.data;
