import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

interface TokenStatsState {
  data: any;
}

const initialState: TokenStatsState = { data: {} };

const tokenStatsSlice = createSlice({
  name: 'tokenStats',
  initialState,
  reducers: {
    setTokenStats(state, action: PayloadAction<any>) {
      state.data = action.payload;
    },
  },
});

export const { setTokenStats } = tokenStatsSlice.actions;
export default tokenStatsSlice.reducer;

export const selectTokenStats = (state: RootState) => state.tokenStats.data;
