import buyProduct from 'api/buy-product';
import getGoods from 'api/get-products';
import React, { useContext, useEffect, useState } from 'react'
import { Slide, toast } from 'react-toastify';
import Product from 'types/product';
import Icon from 'UI/Icon';
import PageWrapper from 'UI/PageWrapper'
import UserProfile from 'UI/UserProfile';
import appContext from 'utils/app-context';
import formatNumber from 'utils/format-number';
import tg from 'utils/tg';


const Shop = () => {
  const [goods, setGoods] = useState<Product[] | null>(null);
  const [pendingGoods, setPendingGoods] = useState<Product['id'][]>([]);
  const {t,user,updateUserInfo} = useContext(appContext)
  const text = t('upgradePage')
  
  const updateGoods = async () => {
    const result = await getGoods();
    if (result) setGoods(result)
  };

  const handleBuy = async (id:Product['id'],isAvailable:boolean) => {
    if(!isAvailable) {
      tg.HapticFeedback.notificationOccurred('warning')
      toast(text.notEnough,{type:'warning',hideProgressBar:true,autoClose:1000})
      return
    }
    setPendingGoods([...pendingGoods,id])
    const result = await buyProduct(id)
    if (result?.result) {
      tg.HapticFeedback.notificationOccurred('success')
    if(result.alert) toast(result.alert,{autoClose:1000,hideProgressBar:true,type:'success'})
  
      await updateGoods()
      await updateUserInfo()
    }
    setPendingGoods(prev => prev.filter(p => p !== id))
  }

  const handleShowGoodDesc = (desc:string) => {
    // tg.showPopup({message:desc})
    toast(desc,{
      closeButton:<button className='develop-button' style={{marginTop:10}}>{text.close}</button>,
      autoClose:5000,
      hideProgressBar:true,
      transition:Slide,
    })
  }

  useEffect(() => {
    updateGoods()
  }, [])
  return (
    <PageWrapper>
      <UserProfile/>
      <div className="develop__text">
      <p className="develop__title">{text.title}</p>
      <p className="develop__subtitle">
      {text.desc}
      </p>
    </div>
    <ul className="develop-list">
      {goods && goods.map(({id,image,in_inventory,price,description}) => {
        if(!user) return
        const isAvailable = user.balance >= price
        return (
          <li className="develop-item" key={id}>
            <button className='develop-item-info' onClick={() => handleShowGoodDesc(description)}>
              <Icon icon='info'/>
            </button>
          <img src={image} className="develop-proton__icon" onClick={() => handleShowGoodDesc(description)}/>
          <div className="develop-item__block">
            <img src="./img/coin.svg" className="develop-coin__icon" />
            <p className="develop-item__number">{formatNumber(price)}</p>
          </div>
          {in_inventory
            ? <p className='develop__subtitle'>{text.Bought}</p>
            : <button
                style={{opacity:isAvailable ? 1 : 0.5}}
                onClick={() => handleBuy(id,isAvailable)} 
                className="develop-button">{text.Buy}</button>
          }
        </li>
        )
      })}
    </ul>
    </PageWrapper>
  )
}

export default Shop