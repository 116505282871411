import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { ReactComponent as QRSVG } from 'pages/DEX-exchange/svg/qr.svg';
import { ReactComponent as PutByBufferSVG } from 'pages/DEX-exchange/svg/putByBuffer.svg';
import { PublicKey } from '@solana/web3.js';
import { useDispatch, useSelector } from 'react-redux';
import { setDestinationWallet } from 'store/slice/walletSlice';
import tg from 'utils/tg';
import { RootState } from 'store/store';
import { showSnackbar } from 'store/slice/snackbarSlice';

const InsertWalletAddress: React.FC = () => {
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { address } = useSelector((state: RootState) => state.wallet);
  // Проверка валидности адреса (Solana)
  const isValidSolanaAddress = (address: string): boolean => {
    try {
      new PublicKey(address);
      return true;
    } catch (_) {
      return false;
    }
  };

  // Валидация в реальном времени
  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAddress = e.target.value;
    setWalletAddress(newAddress);
    if (newAddress.trim() === '') {
      setError(null);
    } else if (newAddress === address) {
      setError('This is your wallet address. Please enter another address.');
    } else if (!isValidSolanaAddress(newAddress)) {
      setError('Invalid wallet address. Please enter a valid Solana address.');
    } else {
      setError(null);
    }
  };

  const handleSubmit = () => {
    if (!error) {
      dispatch(setDestinationWallet(walletAddress));
      navigate('/dex/amount-input');
    }
  };

  const handlePaste = () => {
    navigator.clipboard.readText().then(
      (text) => {
        setWalletAddress(text);
        dispatch(showSnackbar({ message: 'Wallet address pasted from clipboard!' }));
      },
      () => {
        dispatch(showSnackbar({ message: 'Failed to paste wallet address.', status: 'error' }));
      },
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleScanQRCode = () => {
    if (window.Telegram.WebApp) {
      tg?.showScanQrPopup({});
      tg?.onEvent('qrTextReceived', (data: { data: string }) => {
        if (data?.data) {
          setWalletAddress(data.data);
          tg.closeScanQrPopup();
        }
      });
    } else {
      dispatch(showSnackbar({ message: 'QR scanning is not supported in this environment.', status: 'error' }));
    }
  };

  return (
<>
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: 'calc(100vh - 328px)',
      backgroundColor: '#000',
      color: '#fff',
      padding: '0px 38px',
      marginTop: '56px',
    }}>
    <Typography sx={{ fontSize: '24px', fontWeight: 510, marginBottom: '40px' }}>Insert the wallet
      address</Typography>

    {/* Поле ввода с валидацией */}
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: error ? '9px' : '30px',
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#FFFFFF1A',
          borderRadius: '8px',
          border: `1px solid ${error ? 'red' : '#2163FA'}`,
          padding: '0 13px',
        }}>
        <TextField
          fullWidth
          placeholder="Wallet address"
          value={walletAddress}
          onChange={handleAddressChange}
          onKeyDown={handleKeyDown}
          variant="standard"
          error={!!error}
          InputProps={{
            disableUnderline: true,
            sx: {
              color: '#fff',
              fontSize: '14px',
              fontWeight: 500,
              height: '36px',
              '&::placeholder': { color: '#888' },
            },
          }}
        />
        <Button onClick={handlePaste} sx={{ minWidth: 'auto', padding: '0 10px', color: '#fff' }}>
          <PutByBufferSVG style={{ width: '14px' }} />
        </Button>
      </Box>

      {error && <Typography sx={{ color: 'red', fontSize: '10px', marginTop: '6px' }}>{error}</Typography>}
    </Box>

    {/* Разделитель */}
    <Divider
      sx={{
        width: '100%',
        marginBottom: '36px',
        height: '1px',
        backgroundColor: '#333',
      }}>
      <Typography
        sx={{
          color: '#aaa',
          fontSize: '12px',
          textAlign: 'center',
          padding: '0 8px',
          marginTop: '-9px',
          backgroundColor: 'black',
        }}>
        or
      </Typography>
    </Divider>

    {/* Кнопка сканирования QR-кода */}
    <Button
      onClick={handleScanQRCode}
      sx={{
        color: '#0049F0',
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '0',
      }}>
      <QRSVG style={{ width: '12px', height: '12px' }} />
      Scan the QR code
    </Button>
  </Box>
  <Button
    variant="contained"
    fullWidth
    onClick={handleSubmit}
    sx={{
      backgroundColor: '#0049F0',
      textTransform: 'none',
      fontSize: '14px',
      borderRadius: '8px',
      marginTop:"auto",
      marginBottom:'30px',
      height:'40px',
      width:'calc(100vw - 42px)',
      margin: "0 16px",
      '&:hover': { backgroundColor: '#0030A1' },
    }}>
    Continue
  </Button>
</>
  );
};

export default InsertWalletAddress;
