// Обобщённая функция для разбиения массива на чанки
export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

// Тип для ответа от Helius API можно уточнить, если известна структура данных.
// Здесь указан тип any для простоты, но лучше создать интерфейс по документации API.
export const fetchTransactions = async (signaturesChunk: string[]): Promise<any> => {
  const response = await fetch(
    `https://api.helius.xyz/v0/transactions?api-key=5d1c2779-2bbf-46eb-8c84-df70dc6d1bac`,
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        transactions: signaturesChunk
      })
    }
  );
  if (!response.ok) {
    throw new Error('Ошибка при запросе транзакций');
  }
  const data = await response.json();
  return data;
};

export const getAllTransactions = async (allSignatures: string[]): Promise<any[]> => {
  const chunks = chunkArray<string>(allSignatures, 100);
  const transactionsData: any[] = [];

  // Параллельное выполнение запросов:
  const results = await Promise.all(chunks.map(chunk => fetchTransactions(chunk)));
  results.forEach(result => {
    transactionsData.push(...result);
  });

  return transactionsData;
};
