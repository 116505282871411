export function parseChartData(percentChanges: Record<string, string>): number[] {
  const order = [
    'last_24h',
    'last_6h',
    'last_1h',
    'last_30m',
    'last_15m',
    'last_5m',
  ];

  return order.map(key => parseFloat(percentChanges[key].replace('%', '')));
}

export function generateChartData(changes: Record<string, string>, currentPrice = 1): number[] {
  const orderedKeys = ['last_24h', 'last_6h', 'last_1h', 'last_30m', 'last_15m', 'last_5m'];
  const data = [currentPrice];

  for (let i = orderedKeys.length - 1; i > 0; i--) {
    const prevChange = parseFloat(changes[orderedKeys[i]]);
    const prevPrice = data[0] / (1 + prevChange / 100);
    data.unshift(prevPrice);
  }

  return data;
}

export function calculatePercentChange(chartData: number[]): string {
  const startPrice = chartData[0];
  const currentPrice = chartData[chartData.length - 1];

  const change = ((currentPrice - startPrice) / startPrice) * 100;
  return change.toFixed(2);
}
