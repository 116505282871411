import tg from 'utils/tg';
import UserData from 'types/user-data';
import Language from 'types/language';
// TODO: remove tg_id
const tg_id = tg?.initDataUnsafe?.user?.id || 1891271272;
export default async (lang: Language): Promise<UserData | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string);
    API_URL.pathname += 'balance';
    API_URL.searchParams.set('tg_id', String(tg_id));
    API_URL.searchParams.set('lang', String(lang));
    API_URL.searchParams.set('initData', String(tg.initData));

    const responce = await fetch(API_URL);
    return await responce.json();
  } catch (error) {
    console.log('🚀 ~ error:', error);
    return null;
  }
};
