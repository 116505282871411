import React, { useEffect, useState } from 'react';
import {
  Box, CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
} from '@mui/material';
import { ReactComponent as SellSVG } from 'pages/DEX-exchange/svg/sell-trending.svg';
import { ReactComponent as BuySVG } from 'pages/DEX-exchange/svg/buy-trending.svg';
import { getHistory, getHistoryFromGecko } from 'pages/DEX-exchange/api/coinGeckoApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { formatSmallPrice } from 'pages/DEX-exchange/helpers/formatSmallPrice';

interface UiTokenAmount {
  uiAmount: number | null;
  decimals: number;
  amount: string;
  uiAmountString: string;
}

interface TokenBalanceInfo {
  accountIndex: number;
  mint: string;
  uiTokenAmount: UiTokenAmount;
  owner: string;
  programId: string;
}

interface TransactionMeta {
  err: any;
  fee: number;
  preTokenBalances: TokenBalanceInfo[];
  postTokenBalances: TokenBalanceInfo[];
  logMessages: string[];
}

interface TransactionData {
  blockTime: number;
  slot: number;
  meta: TransactionMeta;
  transaction: {
    signatures: string[];
  };
}

interface TrendingHistoryProps {
  address: string;
  name: string;
}

// Функция форматирования «времени назад» с использованием текущего времени
function formatTimeDifference(blockTimestamp: string, current: number = Date.now()) {
  const txTime = new Date(blockTimestamp).getTime();
  const diffMs = current - txTime;
  const diffSeconds = Math.floor(diffMs / 1000);

  if (diffSeconds < 60) {
    return `${diffSeconds}s ago`;
  }
  const diffMinutes = Math.floor(diffSeconds / 60);
  if (diffMinutes < 60) {
    return `${diffMinutes}m ago`;
  }
  const diffHours = Math.floor(diffMinutes / 60);
  if (diffHours < 24) {
    return `${diffHours}h ago`;
  }
  const diffDays = Math.floor(diffHours / 24);
  return `${diffDays}d ago`;
}

function getTokenDelta(meta: TransactionMeta, mintAddress: string): number {
  const pre = meta.preTokenBalances?.find((b) => b.mint === mintAddress);
  const post = meta.postTokenBalances?.find((b) => b.mint === mintAddress);
  const preAmount = pre?.uiTokenAmount?.uiAmount || 0;
  const postAmount = post?.uiTokenAmount?.uiAmount || 0;
  return postAmount - preAmount;
}

const TrendingHistory: React.FC<TrendingHistoryProps> = ({ address, name }) => {
  const [transactions, setTransactions] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(Date.now());
  const solPriceInUsd = useSelector((state: RootState) => state.exchangeRate.rate)!;

  // Обновление текущего времени каждую секунду
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchDeveloperTokens = async () => {
      if (!isMounted) return; // если компонент размонтирован, не продолжаем

      try {
        const history = await getHistoryFromGecko(address);
        const transactions = history.data.map((element: any) => {
          const { attributes } = element;
          const signature = attributes.tx_hash;
          const typeLabel = attributes.kind.toUpperCase();

          let tokenMintAddress, tokensCount, mainTokenCount;
          if (typeLabel === "BUY") {
            tokenMintAddress = attributes.to_token_address;
            tokensCount = attributes.to_token_amount;
            mainTokenCount = attributes.from_token_amount;
          } else {
            tokenMintAddress = attributes.from_token_address;
            tokensCount = attributes.from_token_amount;
            mainTokenCount = attributes.to_token_amount;
          }

          const formattedTokenMintAddress =
            tokenMintAddress.slice(0, 4) + '...' + tokenMintAddress.slice(-4);
          const formattedFromAddress =
            attributes.tx_from_address.slice(0, 4) +
            '...' +
            attributes.tx_from_address.slice(-4);

          return {
            signature,
            typeLabel,
            tokenMintAddress: formattedTokenMintAddress,
            fromAddress: formattedFromAddress,
            // Сохраняем исходное время транзакции вместо сразу вычисленного diffSeconds
            blockTimestamp: attributes.block_timestamp,
            solSymbol: 'Solana',
            tokenSymbol: name,
            mainTokenCount,
            tokensCount,
            priceInUsd: typeLabel === "BUY"
              ? (+attributes.price_to_in_usd).toFixed(4)
              : (+attributes.price_from_in_usd).toFixed(4),
            totalValueUsd: (+attributes.volume_in_usd).toFixed(3),
          };
        });
        if (isMounted) {
          setTransactions(transactions);
        }
      } catch (e) {
        console.error('Ошибка при получении токенов разработчика:', e);
      } finally {
        if (isMounted) {
          setLoading(false);
          // Запускаем следующий запрос через 3 секунды после завершения
          setTimeout(fetchDeveloperTokens, 1000);
        }
      }
    };

    if (solPriceInUsd && address) {
      fetchDeveloperTokens();
    }

    return () => {
      isMounted = false;
    };
  }, [solPriceInUsd, address]);

  // Если loading – генерируем 20 пустых элементов, иначе – используем полученные транзакции
  const rowsToDisplay = loading ? Array.from({ length: 20 }) : transactions;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: '100%', backgroundColor: '#000' }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                color: '#FFFFFF99',
                fontSize: 10,
                lineHeight: '10px',
                borderBottom: '1px solid #333',
                padding: '21px 0px 13px 0px',
              }}
            >
              Type/Time
            </TableCell>
            <TableCell
              sx={{
                color: '#FFFFFF99',
                fontSize: 10,
                lineHeight: '10px',
                borderBottom: '1px solid #333',
                padding: '21px 0px 13px 0px',
              }}
            >
              Amount
            </TableCell>
            <TableCell
              sx={{
                color: '#FFFFFF99',
                fontSize: 10,
                lineHeight: '10px',
                borderBottom: '1px solid #333',
                padding: '21px 0px 13px 0px',
              }}
            >
            </TableCell>
            <TableCell
              sx={{
                color: '#FFFFFF99',
                fontSize: 10,
                lineHeight: '10px',
                borderBottom: '1px solid #333',
                padding: '21px 0px 13px 0px',
              }}
            >
              Value/Price
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsToDisplay && rowsToDisplay.length > 0 ? rowsToDisplay.map((element: any, index: number) => {
              return (
                <TableRow
                  key={loading ? index : element.signature}
                  sx={{
                    borderBottom: '1px solid #333',
                    '&:last-of-type': { borderBottom: '1px solid #333' },
                  }}
                >
                  <TableCell
                    sx={{
                      color: loading ? undefined : '#FFFFFF66',
                      fontSize: 10,
                      borderBottom: '1px solid #333',
                      verticalAlign: 'middle',
                      padding: '8px 0',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {loading ? (
                        <Skeleton variant="rectangular" width={23} height={23} sx={{ mr: "7px", bgcolor: 'rgba(255,255,255,0.8)' }} />
                      ) : (
                        element.typeLabel === 'SELL' ? <SellSVG style={{ width: '23px', height: '23px' }} /> : <BuySVG style={{ width: '23px', height: '23px' }} />
                      )}
                      <Box sx={{ marginLeft: '7px' }}>
                        <Typography sx={{ fontSize: '12px', color: '#fff', lineHeight: '12px' }}>
                          {loading ? (
                            <Skeleton variant="text" width={30} height={14} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                          ) : (
                            element.typeLabel
                          )}
                        </Typography>
                        <Typography sx={{ fontSize: '10px', lineHeight: '10px' }}>
                          {loading ? (
                            <Skeleton variant="text" width={50} height={10} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                          ) : (
                            element.fromAddress
                          )}
                        </Typography>
                        <Typography sx={{ fontSize: '10px', lineHeight: '10px' }}>
                          {loading ? (
                            <Skeleton variant="text" width={40} height={10} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                          ) : (
                            // Вместо заранее вычисленного diffSeconds вычисляем его "на лету" с использованием currentTime
                            formatTimeDifference(element.blockTimestamp, currentTime)
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: loading ? undefined : element.typeLabel === 'SELL' ? '#C35268' : '#6EE297',
                      borderBottom: '1px solid #333',
                      verticalAlign: 'middle',
                      padding: '8px 0',
                    }}
                  >
                    <Box sx={{ display: 'flex', flexBasis: '50%' }}>
                      {element ? (
                        <>
                          <Typography sx={{ color: element.typeLabel === 'SELL' ? '#C35268' : '#6EE297', fontSize: '12px', marginRight: '4px' }}>
                            {element.typeLabel === 'SELL'
                              ? `-${Math.abs(+element.tokensCount).toFixed(6)}`
                              : `-${Math.abs(+element.mainTokenCount).toFixed(6)}`}
                          </Typography>
                          <Typography sx={{ color: '#FFFFFF', fontSize: '12px' }}>
                            {element.typeLabel === 'SELL' ? element.tokenSymbol : element.solSymbol}
                          </Typography>
                        </>
                      ) : (
                        <Skeleton variant="text" width={80} height={12} />
                      )}
                    </Box>
                    <Box sx={{ display: 'flex', flexBasis: '50%' }}>
                      {element ? (
                        <>
                          <Typography sx={{ color: '#FFFFFF', fontSize: '10px', marginRight: '4px' }}>
                            {element.typeLabel === 'SELL'
                              ? `+${Math.abs(+element.mainTokenCount).toFixed(6)}`
                              : `+${Math.abs(+element.tokensCount).toFixed(6)}`}
                          </Typography>
                          <Typography sx={{ color: '#FFFFFF66', fontSize: '10px' }}>
                            {element.typeLabel === 'SELL' ? element.solSymbol : element.tokenSymbol}
                          </Typography>
                        </>
                      ) : (
                        <Skeleton variant="text" width={80} height={12} />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: loading ? undefined : '#FFFFFF',
                      fontSize: 10,
                      borderBottom: '1px solid #333',
                      verticalAlign: 'middle',
                      padding: '8px 0',
                    }}
                  >
                    {loading ? (
                      <Skeleton variant="text" width={60} height={14} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                    ) : (
                      element.totalValueUsd
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: loading ? undefined : '#FFFFFF',
                      fontSize: 10,
                      borderBottom: '1px solid #333',
                      verticalAlign: 'middle',
                      padding: '8px 0',
                      textAlign: 'end',
                    }}
                  >
                    {loading ? (
                      <Skeleton variant="text" width={50} height={14} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                    ) : (
                      element.priceInUsd
                    )}
                  </TableCell>
                </TableRow>
              );
            })
            : (
              <TableRow>
                <TableCell colSpan={5} sx={{ color: '#fff', textAlign: 'center' }}>
                  Ничего не найдено
                </TableCell>
              </TableRow>
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TrendingHistory;
