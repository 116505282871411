import { TranslationKeys } from 'i18n';
import DEXexchange from 'pages/DEX-exchange/Dex-exchange';
import AmountInput from 'pages/DEX-exchange/Components/Wallet/AmountInput';
import InsertWalletAddress from 'pages/DEX-exchange/Components/Wallet/InsertWalletAddress';
import WithdrawConfirmation from 'pages/DEX-exchange/Components/Wallet/WithdrawConfirmation';
import Entry from 'pages/Entry/Entry';
import Friends from 'pages/Friends/Friends';
import Game from 'pages/Game/Game';
import Home from 'pages/Home/Home';
import Shop from 'pages/Shop/Shop';
import Tasks from 'pages/Tasks/Tasks';
import { Navigate, RouteObject } from 'react-router-dom';
import Icon from 'UI/Icon';
import Token from 'pages/DEX-exchange/Components/Token/Token';

type Route = {
  route: RouteObject;
  titleKey?: keyof TranslationKeys['pageTitles'];
  navBarIcon?: JSX.Element;
};

const routes: Route[] = [
  {
    route: {
      path: '/',
      element: <Navigate to='/dex' replace />,
    },
  },
  {
    route: {
      path: '/dex',
      // element: <Home />,
      element: <DEXexchange />,
      children: [
        {
          path: 'scan-wallet',
          element: <InsertWalletAddress />,
        },

        {
          path: 'token/:address',
          element: <Token />,
        },
        {
          path: 'amount-input',
          element: <AmountInput />,
        },
        {
          path: 'withdraw-confirmation',
          element: <WithdrawConfirmation />,
        },
      ],
    },
    titleKey: 'home',
    navBarIcon: <Icon icon='home' />,
  },
  {
    route: {
      path: '/game',
      element: <Game />,
    },
    navBarIcon: <Icon icon='game' />,
    titleKey: 'game',
  },
  {
    route: {
      path: '/tasks',
      element: <Tasks />,
    },
    navBarIcon: <Icon icon='tasks' />,
    titleKey: 'tasks',
  },
  {
    route: {
      path: '/shop',
      element: <Shop />,
    },
    navBarIcon: <Icon icon='shop' />,
    titleKey: 'upgrade',
  },
  {
    route: {
      path: '/friends',
      element: <Friends />,
    },
    navBarIcon: <Icon icon='friends' />,
    titleKey: 'friends',
  },
  {
    route: {
      path: '/entry',
      element: <Entry />,
    },
  },
];
export default routes;
