import { AnyAction } from "@reduxjs/toolkit";

export interface ExchangeRateState {
  rate: number | null;
}

const initialState: ExchangeRateState = {
  rate: null,
};

const exchangeRateReducer = (
  state = initialState,
  action: AnyAction
): ExchangeRateState => {
  switch (action.type) {
    case "SET_EXCHANGE_RATE":
      return { ...state, rate: action.payload };
    default:
      return state;
  }
};

export default exchangeRateReducer;
