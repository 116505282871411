// Маппинг цифр в субскрипт
const subscriptMap: { [digit: string]: string } = {
  '0': '₀',
  '1': '₁',
  '2': '₂',
  '3': '₃',
  '4': '₄',
  '5': '₅',
  '6': '₆',
  '7': '₇',
  '8': '₈',
  '9': '₉',
};

export function formatSmallPrice(num: number, fixed: number = 15): string {
  // Приводим число к фиксированному формату (например, 10 знаков после запятой)
  const s = num.toFixed(fixed);
  // Если число не начинается с "0.", возвращаем как есть
  if (!s.startsWith("0.")) {
    return s;
  }
  // Извлекаем дробную часть без "0."
  const fractional = s.slice(2);
  // Подсчитываем количество нулей подряд в начале дробной части
  let zeroCount = 0;
  while (zeroCount < fractional.length && fractional[zeroCount] === '0') {
    zeroCount++;
  }
  // Если нулей меньше или равны 1 – просто возвращаем число
  if (zeroCount <= 1) {
    return s;
  }
  // Вычисляем субскрипт для (zeroCount - 1)
  const subscriptStr = (zeroCount - 1).toString().split('').map(d => subscriptMap[d]).join('');
  // Остаток значащих цифр после удалённых нулей
  const remainder = fractional.slice(zeroCount);
  return `0.0${subscriptStr}${remainder}`;
}
