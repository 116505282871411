import React, { useState } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { closeMiniModal } from 'store/slice/miniModalSlice';
import { motion } from 'framer-motion';
import { ReactComponent as SuccessIcon } from 'pages/DEX-exchange/svg/success-mini-alert.svg';
import { ReactComponent as ErrorIcon } from 'pages/DEX-exchange/svg/error-mini-alert.svg';
import { ReactComponent as InfoIcon } from 'pages/DEX-exchange/svg/info-mini-alert.svg';

const MiniModal: React.FC = () => {
  const dispatch = useDispatch();
  const {
    isOpen,
    status,
    title,
    description,
    buttonText,
    onButtonClick,
  } = useSelector((state: RootState) => state.miniModal);

  const [dragging, setDragging] = useState(false);

  const getStatusIcon = () => {
    switch (status) {
      case 'success':
        return <SuccessIcon style={{ width: '40px', height: '40px', color: '#6EE297' }} />;
      case 'error':
        return <ErrorIcon style={{ width: '40px', height: '40px', color: '#C35268' }} />;
      case 'info':
        return <InfoIcon style={{ width: '48px', height: '40px', color: '#FFFFFF' }} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => dispatch(closeMiniModal())}
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      }}>
      <motion.div
        initial={{ y: '100%' }}
        animate={{ y: 0 }}
        exit={{ y: '100%' }}
        transition={{ type: 'spring', stiffness: 150, damping: 20 }}
        drag="y"
        dragConstraints={{ top: 0, bottom: 100 }}
        onDragStart={() => setDragging(true)}
        onDragEnd={(_, info) => {
          if (info.offset.y > 50) {
            dispatch(closeMiniModal());
          }
          setDragging(false);
        }}
        style={{
          position: 'absolute',
          bottom: 0,
          left: '0',
          transform: 'translateX(-50%)',
          width: '100%',
          maxWidth: '420px',
          backgroundColor: '#000',
          color: '#fff',
          borderRadius: '16px 16px 0 0',
          boxShadow: '0px -2px 10px rgba(0,0,0,0.5)',
          padding: '29px 16px 48px',
          textAlign: 'center',
          touchAction: 'none', // Отключаем стандартное поведение свайпа
        }}>
        {/* Верхний ползунок для жеста */}
        <motion.div
          drag="y"
          dragConstraints={{ top: 0, bottom: 100 }}
          className="tw-cursor-pointer"
          style={{
            position: 'absolute',
            top: '5px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '32px',
            height: '4px',
            backgroundColor: dragging ? '#FFFFFF80' : '#FFFFFF33',
            borderRadius: '8px',
          }}
        />

        {/* Иконка статуса */}
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>{getStatusIcon()}</Box>

        {/* Заголовок */}
        <Typography sx={{ fontSize: '20px', fontWeight: 700, marginBottom: '8px' }}>{title}</Typography>

        {/* Описание */}
        <Typography sx={{ fontSize: '16px', color: '#888', marginBottom: '40px' }}>{description}</Typography>

        {/* Кнопка */}
        <Button
          variant="contained"
          sx={{
            width: '100%',
            background: '#2163FA',
            color: '#fff',
            borderRadius: '8px',
            height: '44px',
            textTransform: 'none',
            fontSize: '14px',
          }}
          onClick={() => {
            if (onButtonClick) onButtonClick();
            dispatch(closeMiniModal());
          }}>
          {buttonText}
        </Button>
      </motion.div>
    </Modal>
  );
};

export default MiniModal;
