import { Box, IconButton, styled, Typography } from '@mui/material';
import { ReactComponent as SolanaSVG } from 'pages/DEX-exchange/svg/solana-mock.svg';
import { ReactComponent as TabletSVG } from 'pages/DEX-exchange/svg/tablet.svg';
import { ReactComponent as CopySVG } from 'pages/DEX-exchange/svg/copy.svg';
import { ReactComponent as SocietySolanaSVG } from 'pages/DEX-exchange/svg/society/solana.svg';
import { ReactComponent as SocietyTabletSVG } from 'pages/DEX-exchange/svg/society/tablet.svg';
import { ReactComponent as SocietyTgSVG } from 'pages/DEX-exchange/svg/society/tg.svg';
import { ReactComponent as SocietyXSVG } from 'pages/DEX-exchange/svg/society/x.svg';
import { ReactComponent as SocietyWebSVG } from 'pages/DEX-exchange/svg/society/web.svg';
import { useDispatch } from 'react-redux';
import { showSnackbar } from 'store/slice/snackbarSlice';

interface TokenInfoProps {
  token: any;
}

const TokenInfo: React.FC<TokenInfoProps> = ({ token }) => {
  const dispatch = useDispatch();

  const AvatarImg = styled('img')({
    width: 36,
    height: 36,
    borderRadius: '50%',
    objectFit: 'cover',
  });

  const shortAddress = (address: string) => {
    if (address?.length <= 8) return address;
    return address?.slice(0, 4) + '...' + address?.slice(-4);
  };

  const handleCopy = (event: React.MouseEvent<HTMLButtonElement>, data: string) => {
    event.stopPropagation();
    navigator.clipboard.writeText(data).then(
      () => dispatch(showSnackbar({ message: 'Wallet address copied to clipboard!' })),
      () => dispatch(showSnackbar({ message: 'Failed to copy wallet address.', status: 'error' })),
    );
  };

  function goToCoinGecko() {
    window.open(`https://www.geckoterminal.com/solana/pools/${token.address}`, '_blank');
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#15161B',
      borderRadius: '10px',
      padding: '12px 8px 12px 12px',
      margin: '8px 8px 17px',
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '12px',
        marginBottom: '13px',
        borderBottom: '1px solid #FFFFFF1A',
      }}>
        {/* <FavoriteSVG style={{ color: token.isFavorite ? '#FBEB74' : '#FFFFFF33', marginRight: '10px' }} /> */}
        {/* Аватар + SolanaSVG */}
        <Box position="relative" mr={2}>
          <AvatarImg src={token.avatar} alt="token avatar" />
          <SolanaSVG
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: '12px',
              height: '12px',
            }}
          />
        </Box>

        {/* Название + доп. инфо */}
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center">
            <TabletSVG style={{ width: '12px', height: '12px', marginRight: '4px' }} />
            <Typography
              sx={{
                fontSize: 12,
                color: '#fff',
              }}>
              {token.name?.slice(0, 6) + '...'}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <Typography sx={{ fontSize: 10, color: '#888' }}>
              {token.time} • {shortAddress(token.pumpAddress)}
            </Typography>
            <IconButton onClick={event => handleCopy(event, token.pumpAddress)} sx={{ m: 0.5, ml: 1, p: 0 }}>
              <CopySVG style={{ width: '14px', height: '14px' }} />
            </IconButton>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" sx={{ marginLeft: 'auto' }} gap="8px">
          <Box display="flex" alignItems="center" sx={{ color: '#FBEB74', fontSize: '10px', marginLeft: 'auto' }}>
            Safe <Typography sx={{
            borderRadius: '4px',
            marginLeft: '4px',
            padding: '4px',
            backgroundColor: '#FBEB7433',
            fontSize: '10px',
            lineHeight: '10px',
          }} onClick={()=>goToCoinGecko()}>{token.gt_score ?? "-"}/100</Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end" gap="10px" sx={{color:'#fff'}}>
            {token.solana ? <SocietySolanaSVG style={{ width: '12px', height: '10px' }}
                                              onClick={() => window.open(token.solana, '_blank')} /> : ''}
            {token.pump ? <SocietyTabletSVG style={{ width: '12px', height: '12px' }}
                                            onClick={() => window.open(token.pump, '_blank')} /> : ''}
            {token.twitter ? <SocietyXSVG style={{ width: '12px', height: '12px' }}
                                          onClick={() => window.open(token.twitter, '_blank')} /> : ''}
            {token.website ? <SocietyWebSVG style={{ width: '12px', height: '12px' }}
                                        onClick={() => window.open(token.website, '_blank')} /> : ''}
            {token.telegram ? <SocietyTgSVG style={{ width: '12px', height: '11px' }}
                                            onClick={() => window.open(token.telegram, '_blank')} /> : ''}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '12px', color: '#fff' }}>
        <Box>
          <Typography sx={{ color: '#FFFFFF99', fontSize: '10px' }}>Price</Typography>
          <Typography sx={{ fontSize: '12px' }}>{token.priceUsd || 0.0}</Typography>
        </Box>
        <Box>
          <Typography sx={{ color: '#FFFFFF99', fontSize: '10px' }}>Mkt Cap</Typography>
          <Typography sx={{ fontSize: '12px' }}>{token.marketCap || 0.0}</Typography>
        </Box>
        <Box>
          <Typography sx={{ color: '#FFFFFF99', fontSize: '10px' }}>Liquidity</Typography>
          <Typography sx={{ color: !token.liquidityOriginal ? "#c94064": "#fff", fontSize: '12px' }}>{token.liquidity || 0.0}</Typography>
        </Box>
        <Box>
          <Typography sx={{ color: '#FFFFFF99', fontSize: '10px' }}>Supply</Typography>
          <Typography sx={{ fontSize: '12px' }}>{token.supply || 0.0}</Typography>
        </Box>
        <Box>
          <Typography sx={{ color: '#FFFFFF99', fontSize: '10px' }}>B.Curve</Typography>
          <Typography sx={{ color: '#6EE297', fontSize: '12px' }}>{token.bCurve || 0.0}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TokenInfo;
