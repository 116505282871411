import { ApolloClient, InMemoryCache, split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
const token = 'ory_at_qqA7IB57iKWfN5o-ldcXSC4hnAe9UNI_O7MF-KDsA68.EdXdUEqCwHpxI9toG5Xro_nh8FYU7JJreOjfCd8jr-g';
const wsLink = new GraphQLWsLink(
  createClient({
    url: `https://streaming.bitquery.io/eap?token=${token}`,
  }),
);

const httpLink = new HttpLink({
  uri: `https://streaming.bitquery.io/eap?token=${token}`,
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
