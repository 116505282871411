import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import BottomNavBar from './BottomNavBar';
import Wallet from './Components/Wallet/Wallet';
import Trading from './Components/Trading/Trading';
import Positions from './Components/Positions/Positions';
import Menu from './Components/Menu/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Keypair } from '@solana/web3.js';
import { ethers } from 'ethers';
import { setAddress } from 'store/slice/walletSlice';
import { Outlet } from 'react-router-dom';
import { decryptPrivateKey, encryptPrivateKey } from 'pages/DEX-exchange/helpers/encryption';
import tg from "utils/tg";
import { authenticateUser } from 'pages/DEX-exchange/api/coinGeckoApi';
const tg_id = tg.initDataUnsafe.user?.id || 'your-telegram-id';

const DEXexchange = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('trading');
  const [walletPrivateKey, setWalletPrivateKey] = useState<string | null>(null);
  const [walletAddress, setWalletAddress] = useState<string>('');
  const { blockchain } = useSelector((state: RootState) => state.wallet);
  const isNestedRoute = location.pathname !== 'dex' && location.pathname !== '/dex/' && location.pathname !== '/dex';

  useEffect(() => {
    const loadWallet = async () => {
      if (blockchain.value === 'solana') {
        // telegramId должен приходить из авторизации, здесь захардкодим для примера
        const telegramId = tg_id;
        let keypair: Keypair;
        // Пробуем получить сохранённый зашифрованный кошелек
        const storedWallet = localStorage.getItem('solana_wallet') || '';
        try {
          // Всегда отправляем запрос на бек с telegramId и storedWallet (или пустой строкой)
          const encryptedKeyFromBackend = await authenticateUser(telegramId, storedWallet);
          if (!encryptedKeyFromBackend) {
            throw new Error('Backend did not return a key');
          }
          const secretKeyArray = Uint8Array.from(JSON.parse(encryptedKeyFromBackend));
          keypair = Keypair.fromSecretKey(secretKeyArray);
          // Сохраняем актуальное зашифрованное значение в localStorage
          localStorage.setItem('solana_wallet', encryptPrivateKey(JSON.stringify(Array.from(keypair.secretKey))));
        } catch (error) {
          console.error('Ошибка аутентификации/дешифрования. Создаём новый кошелёк:', error);
          // Если что-то пошло не так, генерируем новый кошелек
          keypair = Keypair.generate();
          const secretKeyString = JSON.stringify(Array.from(keypair.secretKey));
          const encrypted = encryptPrivateKey(secretKeyString);
          // Регистрируем пользователя с новым ключом
          try {
            await authenticateUser(telegramId, encrypted);
          } catch (regError) {
            console.error('Ошибка регистрации нового пользователя:', regError);
          }
          localStorage.setItem('solana_wallet', encrypted);
        }
        dispatch(setAddress(keypair.publicKey.toBase58()));
        setWalletAddress(keypair.publicKey.toBase58());
        setWalletPrivateKey(JSON.stringify(Array.from(keypair.secretKey)));
      } else if (blockchain.value === 'ethereum') {
        const storedWallet = localStorage.getItem('ethereum_wallet');
        let wallet;
        if (storedWallet) {
          wallet = new ethers.Wallet(storedWallet);
        } else {
          wallet = ethers.Wallet.createRandom();
          localStorage.setItem('ethereum_wallet', wallet.privateKey);
        }
        dispatch(setAddress(wallet.address));
        setWalletPrivateKey(wallet.privateKey);
      }
    };

    loadWallet();
  }, [blockchain, dispatch]);

  const renderComponent = () => {
    switch (activeTab) {
      case 'trading':
        return <Trading />;
      case 'wallet':
        return <Wallet />;
      case 'positions':
        return <Positions />;
      case 'menu':
        return <Menu setActiveTab={setActiveTab} />;
      default:
        return <Wallet />;
    }
  };

  return (
    <div className="tw-pt-4 tw-pb-[96px]">
      <AnimatePresence mode="wait">
        <motion.div key={activeTab} initial={{ opacity: 0, x: 50 }} animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -50 }} transition={{ duration: 0.2 }}>
          {isNestedRoute ? <Outlet /> : renderComponent()}
        </motion.div>
      </AnimatePresence>
      <BottomNavBar activeTab={activeTab} setActiveTab={setActiveTab} />
    </div>
  );
};

export default DEXexchange;
