import React from 'react';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { Option } from 'pages/DEX-exchange/Components/Wallet/Wallet';

const menuProps = {
  PaperProps: {
    sx: {
      backgroundColor: '#1a1a1a',
      color: '#fff',
    },
  },
  MenuListProps: {
    sx: {
      padding: 0,
    },
  },
};

type CustomSelectProps = {
  options: Option[];
  value: Option;
  onChange: (value: Option) => void;
  disabled: boolean;
};

const CustomSelect: React.FC<CustomSelectProps> = ({
                                                     options,
                                                     value,
                                                     onChange,
                                                     disabled
                                                   }) => {
  const handleChange = (option: Option) => {
    onChange(option);
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #FFFFFF33',
        borderRadius: '8px',
        backgroundColor: '#000',
        color: '#fff',
        marginBottom: '17px',
      }}
    >
      {/* Текст слева */}
      <Typography
        variant="body1"
        sx={{
          marginRight: '8px',
          flexShrink: 0,
          fontSize: '12px',
          fontWeight: 510,
          lineHeight: '12px',
          padding: '11px 8px',
          textAlign: 'left',
          textUnderlinePosition: 'from-font',
          textDecorationSkipInk: 'none',
          color: '#FFFFFF99',
          borderRight: '1px solid #FFFFFF33',
        }}
      >
        {value.label}
      </Typography>
      {/* Селектор */}
      <Select
        value={value.value}
        variant="standard"
        MenuProps={menuProps}
        sx={{
          color: '#fff',
          '& .MuiSelect-icon': { color: '#fff' },
          '& .MuiSelect-select': {
            pointerEvents: disabled? "none":"all",
            fontSize: '12px',
            fontWeight: 510,
            lineHeight: '17px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            padding: 0,
          },
          flex: 1,
        }}
        disableUnderline
        renderValue={() => `${value.amount?.toFixed(2)} ${value.currency}`}
      >
        {options.map((option: Option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            onClick={() => handleChange(option)}
            sx={{
              fontSize: '12px',
              fontWeight: 510,
              lineHeight: '17px',
              textAlign: 'left',
              textUnderlinePosition: 'from-font',
              textDecorationSkipInk: 'none',
              display: 'flex',
              alignItems: 'center',
              height: '30px',
              maxHeight: '30px',
              minHeight: 'auto',
              borderBottom: '1px solid #000',
            }}
          >
            {option.label} {option.amount?.toFixed(2)} {option.currency}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default CustomSelect;
