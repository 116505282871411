import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { ReactComponent as GasSVG } from 'pages/DEX-exchange/svg/gas.svg';
import { ReactComponent as SlippageSVG } from 'pages/DEX-exchange/svg/slippage.svg';
import { ReactComponent as CommissionSVG } from 'pages/DEX-exchange/svg/commission.svg';
import { ReactComponent as TokenSettingsSVG } from 'pages/DEX-exchange/svg/token-settings.svg';
import { ReactComponent as SolanaSVG } from 'pages/DEX-exchange/svg/solana-grey.svg';
import { ReactComponent as LightningSVG } from 'pages/DEX-exchange/svg/lighting.svg';
import { ReactComponent as SellSVG } from 'pages/DEX-exchange/svg/sell.svg';
import { ReactComponent as BuySellArrowSVG } from 'pages/DEX-exchange/svg/buySellArrow.svg';
import StandartInput from 'pages/DEX-exchange/common/StandartInput/StandartInput';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'store/slice/modalSlice';
import TradesSettings from 'pages/DEX-exchange/Modals/TradesSettings';
import { useBlockchainService } from 'pages/DEX-exchange/helpers/blockchainServiceContext';
import { RootState } from 'store/store';
import { buyOrSellCoin } from 'pages/DEX-exchange/Components/Token/services/buy';
import { showSnackbar } from 'store/slice/snackbarSlice';

const TokenNavbar: React.FC<{ pumpAddress: string, decimals: number }> = ({ pumpAddress, decimals }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('0');
  const blockchainService = useBlockchainService();
  const { address: walletAddress } = useSelector((state: RootState) => state.wallet);
  const mintAddress = useSelector((state: RootState) => state.activeAddress.address);
  const [loadingBuy, setLoadingBuy] = useState(false);
  const [loadingSell, setLoadingSell] = useState(false);
  const [activeButton, setActiveButton] = useState('BUY');
  const rate = useSelector((state: RootState) => state.exchangeRate.rate);
  const slippage = 5;

  const [presetSettings, setPresetSettings] = useState({
    buyPreset: ["0.01", "0.02", "0.5", "1"],
    sellPreset: ["25", "50", "75", "100"],
  });

  // При монтировании пытаемся получить настройки из localStorage
  useEffect(() => {
    const savedSettings = localStorage.getItem('settings');
    if (savedSettings) {
      try {
        const parsed = JSON.parse(savedSettings);
        setPresetSettings({
          buyPreset: (parsed.buyPreset && parsed.buyPreset.inputs) || ["0.01", "0.02", "0.5", "1"],
          sellPreset: (parsed.sellPreset && parsed.sellPreset.inputs) || ["25", "50", "75", "100"],
        });
      } catch (e) {
        console.error('Error parsing settings from localStorage', e);
      }
    }
  }, []);

  const order = (message: string, status?: any) => {
    dispatch(showSnackbar({ message: message, status: status ?? "default" }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    if (activeButton === 'SELL') {
      inputValue = inputValue.replace(/[^0-9]/g, '');
      if (!inputValue) {
        setValue('');
        return;
      }
      let num = parseInt(inputValue, 10);
      if (num < 1) num = 1;
      if (num > 100) num = 100;
      setValue(num.toString());
    } else {
      inputValue = inputValue.replace(/[^0-9.]/g, '');
      const parts = inputValue.split('.');
      if (parts.length > 2) {
        inputValue = parts[0] + '.' + parts.slice(1).join('');
      }
      setValue(inputValue);
    }
  };

  // Отступ для нижней панели (на случай, если клавиатура открыта)
  const [bottomOffset, setBottomOffset] = useState(0);
  const initialHeightRef = React.useRef(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      const currentHeight = window.innerHeight;
      // Если текущее значение меньше изначального – клавиатура, рассчитываем отступ
      if (currentHeight < initialHeightRef.current) {
        setBottomOffset(50); // можно подобрать нужное значение
      } else {
        setBottomOffset(0);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: `${bottomOffset}px`,
        zIndex: '1',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#15161B',
        padding: '13px 8px 37px',
        marginTop: 'auto',
        gap: '8px',
        width: '100%',
        left: 0,
      }}>
      <Box sx={{ display: 'flex', gap: '12px' }}>
        <Typography sx={{ display: 'flex', marginRight: 'auto', fontSize: '10px', color: '#FFFFFF66' }}>
          Gas: <Typography sx={{ color: '#6EE297', fontSize: '10px', marginLeft: '3px' }}>
          ~${(5000 / 1e9 * rate!).toFixed(8)}
        </Typography>
        </Typography>
        <Typography sx={{ display: 'flex', alignItems: 'center', gap: '3px', fontSize: '10px', color: '#FFFFFF66' }}>
          <GasSVG style={{ width: '10px', height: '11px' }} />
          {5000 / 1e9}
        </Typography>
        <Typography sx={{ display: 'flex', alignItems: 'center', gap: '3px', fontSize: '10px', color: '#FFFFFF66' }}>
          <SlippageSVG style={{ width: '12px', height: '12px' }} />
          {slippage}%
        </Typography>
        <Typography sx={{ display: 'flex', alignItems: 'center', gap: '3px', fontSize: '10px', color: '#FFFFFF66' }}>
          <CommissionSVG style={{ width: '11px', height: '11px' }} />
          0.1
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '8px', marginBottom: '4px' }}>
        <StandartInput
          className="tw-h-[36px] tw-w-[100%] tw-order-1"
          value={value}
          onChange={handleInputChange}
          endAdornment={
            activeButton === 'BUY'
              ? <SolanaSVG style={{ width: '11px', height: '10px', marginLeft: '6px' }} />
              : <Box sx={{ color: '#FFFFFF66', fontSize: '8px' }}>%</Box>
          }
        />
        <Button
          variant="contained"
          sx={
            activeButton === 'BUY'
              ? {
                minWidth: '80px',
                width: '80px',
                background: '#2163FA',
                color: '#fff',
                paddingX: '0',
                borderRadius: '8px',
                height: '36px',
                textTransform: 'none',
                fontSize: '12px',
                order: '2'
              }
              : {
                minWidth: '59px',
                width: '59px',
                background: '#2163FA33',
                paddingX: '0',
                borderRadius: '8px',
                height: '36px',
                textTransform: 'none',
                fontSize: '12px',
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0,
                border: '1px solid #2163FA',
                color: '#2163FA',
                order: '3'
              }
          }
          onClick={() => {
            if (activeButton === 'BUY') {
              if (!value || +value === 0) {
                return;
              }
              setLoadingBuy(true);
              buyOrSellCoin(pumpAddress, +value, setLoadingBuy, 'buy', order);
            } else {
              setActiveButton('BUY');
            }
          }}>
          {loadingBuy ? <CircularProgress size={20} /> :
            <>
              {activeButton === "BUY" ? <LightningSVG style={{ width: '10px', height: '15px', marginRight: '4px' }} /> : ''}
              Buy
              {activeButton === "BUY" ? '' : <BuySellArrowSVG style={{ width: '8px', height: '5px', marginLeft: '7px' }} />}
            </>
          }
        </Button>
        <Button
          variant="contained"
          sx={
            activeButton === 'SELL'
              ? {
                minWidth: '80px',
                width: '80px',
                background: '#C3526833',
                color: '#fff',
                borderRadius: '8px',
                height: '36px',
                textTransform: 'none',
                fontSize: '12px',
                paddingX: '0',
                border: '1px solid #C35268',
                order: '2'
              }
              : {
                minWidth: '59px',
                width: '59px',
                background: '#C3526833',
                color: '#C35268',
                borderRadius: '8px',
                height: '36px',
                textTransform: 'none',
                fontSize: '12px',
                paddingX: '0',
                border: '1px solid #C35268',
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0,
                order: '3'
              }
          }
          onClick={() => {
            if (activeButton === 'SELL') {
              if (!value || +value === 0) {
                return;
              }
              setLoadingSell(true);
              buyOrSellCoin(pumpAddress, +value, setLoadingSell, 'sell', order);
            } else {
              setActiveButton('SELL');
            }
          }}>
          {loadingSell ? <CircularProgress size={20} /> :
            <>
              {activeButton === "SELL" ? <SellSVG style={{ width: '12px', height: '14px', marginRight: '4px' }} /> : ''}
              Sell
              {activeButton === "SELL" ? "" : <BuySellArrowSVG style={{ width: '8px', height: '5px', marginLeft: '7px' }} />}
            </>
          }
        </Button>
      </Box>
      <Box sx={{ display: 'flex', gap: '8px' }}>
        {activeButton === 'SELL'
          ? presetSettings.sellPreset.map((preset, idx) => (
            <Button
              key={`sell-preset-${idx}`}
              onClick={() => setValue(preset)}
              sx={{
                flex: '1',
                fontSize: '12px',
                lineHeight: '12px',
                background: '#333',
                border: '1px solid #FFFFFF0D',
                color: '#FFFFFF99',
                borderRadius: '6px',
                textTransform: 'none',
              }}>
              {preset}%
            </Button>
          ))
          : presetSettings.buyPreset.map((preset, idx) => (
            <Button
              key={`buy-preset-${idx}`}
              onClick={() => setValue(preset)}
              sx={{
                flex: '1',
                fontSize: '12px',
                lineHeight: '12px',
                background: '#333',
                border: '1px solid #FFFFFF0D',
                color: '#FFFFFF99',
                borderRadius: '6px',
                textTransform: 'none',
              }}>
              {preset} <SolanaSVG style={{ width: '11px', height: '10px', marginLeft: '6px' }} />
            </Button>
          ))
        }
        <Button
          sx={{
            fontSize: '12px',
            lineHeight: '12px',
            background: '#333',
            border: '1px solid #FFFFFF0D',
            color: '#FFFFFF99',
            borderRadius: '6px',
            textTransform: 'none',
          }}
          onClick={() => {
            dispatch(
              openModal({
                title: 'Trades settings',
                component: <TradesSettings />,
              }),
            );
          }}>
          <TokenSettingsSVG style={{ height: '14px', width: '14px' }} />
        </Button>
      </Box>
    </Box>
  );
};

export default TokenNavbar;
