import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ReactComponent as SolanaSVG } from 'pages/DEX-exchange/svg/solana-mock.svg';
import { ReactComponent as CopySVG } from 'pages/DEX-exchange/svg/copy.svg';
import { showSnackbar } from 'store/slice/snackbarSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { getDeveloperTokens } from 'pages/DEX-exchange/api/coinGeckoApi';
import { formatTimeDifference } from 'pages/DEX-exchange/helpers/formatTimeDifference';
import { formatMarketCap } from 'pages/DEX-exchange/helpers/formatToThausandOrMillions';

const DevTokens: React.FC = () => {
  const dispatch = useDispatch();
  const address = useSelector((state: RootState) => state.activeAddress.address);
  const AvatarImg = styled('img')({
    width: 24,
    height: 24,
    borderRadius: '50%',
    objectFit: 'cover',
  });

  const [tokens, setTokens] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const handleCopy = (event: React.MouseEvent<HTMLButtonElement>, data: string) => {
    event.stopPropagation();
    navigator.clipboard.writeText(data).then(
      () => dispatch(showSnackbar({ message: 'Wallet address copied to clipboard!' })),
      () => dispatch(showSnackbar({ message: 'Failed to copy wallet address.', status: 'error' })),
    );
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;

    const fetchDeveloperTokens = async () => {
      try {
        const tokensData = await getDeveloperTokens(address);
        let images = {} as any;

        tokensData.forEach((token: any) => {
          token.included
            .filter((include: any) => include.type === 'token')
            .forEach((e: any) => {
              images[e.id] = e.attributes.image_url;
            });
        });
        const data = tokensData.map((token: any) => {
          let supply = 0;
          token.included
            .filter((include: any) => include.type === 'token')
            .forEach((e: any) => {
              images[e.id] = e.attributes.image_url;
              if (token.data.attributes.base_token_id === e.id) {
                supply = e.attributes.circulating_supply;
              }
            });
          const priceInUsd = parseFloat(token.data.attributes.price_in_usd);
          const purchasePrice = parseFloat(
            token.data.attributes.historical_data?.last_24h?.price_in_usd || '0',
          );
          const pnlPercent = ((priceInUsd - purchasePrice) / purchasePrice) * 100;
          const rawMarketCap = supply && priceInUsd ? supply * priceInUsd : 0;
          const marketCap = rawMarketCap ? formatMarketCap(rawMarketCap) : 'N/A';

          return {
            avatar: images[token.data.attributes.base_token_id],
            name: token.data.attributes.name,
            time: formatTimeDifference(token.data.attributes.pool_created_at),
            address: token.data.attributes.address,
            priceUsd: `$${(+token.data.attributes.price_in_usd)?.toFixed(7)}`,
            liquidity: `$${formatMarketCap(token.data.attributes.reserve_in_usd) || 'N/A'}`,
            marketCap: marketCap ? `$${marketCap}` : 'N/A',
            pnl: purchasePrice ? `${pnlPercent.toFixed(2)}%` : 'N/A',
          };
        });

        setTokens(data);
        setLoading(false);
      } catch (e) {
        console.error('Ошибка при получении токенов разработчика:', e);
        setLoading(false);
      }
    };

    fetchDeveloperTokens();
    interval = setInterval(fetchDeveloperTokens, 1000);

    return () => clearInterval(interval);
  }, [address]);

  // Если загрузка или токены отсутствуют – генерируем 10 моковых строк (dataToDisplay)
  const dataToDisplay = (loading || tokens.length === 0)
    ? Array.from({ length: 10 })
    : tokens;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: '100%', backgroundColor: '#000' }}>
        <TableHead>
          <TableRow>
            {['Token', 'Market cap', 'Amount', 'liquidity', 'PnL'].map(header => (
              <TableCell
                key={header}
                sx={{
                  color: '#FFFFFF99',
                  fontSize: 10,
                  lineHeight: '10px',
                  borderBottom: '1px solid #333',
                  padding: '21px 0px 13px 0px',
                  ...(header === 'PnL' && { textAlign: 'end' }),
                }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataToDisplay.map((element: any, idx: number) => {
            const isFake = loading || tokens.length === 0;
            return (
              <TableRow key={isFake ? idx : idx} sx={{ borderBottom: '1px solid #333' }}>
                <TableCell sx={{ color: '#fff', fontSize: 12, padding: '14px 0', borderColor: '#333' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box position="relative" mr={2}>
                      {isFake ? (
                        <Skeleton variant="circular" width={24} height={24} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                      ) : (
                        <AvatarImg src={element.avatar} alt="token avatar" />
                      )}
                      {!isFake && (
                        <SolanaSVG
                          style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            width: '12px',
                            height: '12px',
                          }}
                        />
                      )}
                    </Box>
                    <Typography fontSize={12} color="#fff">
                      {isFake ? (
                        <Skeleton variant="text" width={52} height={18} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                      ) : (
                        element.name.slice(0, 6) + '...'
                      )}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ color: '#FFFFFF66', fontSize: 10, padding: '14px 0', borderColor: '#333' }}>
                  {isFake ? (
                    <Skeleton variant="text" width={38} height={12} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                  ) : (
                    element.marketCap
                  )}
                </TableCell>
                <TableCell sx={{ color: '#fff', fontSize: 10, padding: '14px 0', borderColor: '#333' }}>
                  <div className="tw-flex tw-items-center">
                    {isFake ? (
                      <Skeleton variant="text" width={55} height={12} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                    ) : (
                      <>
                        {element.priceUsd}
                      </>
                    )}
                    <IconButton onClick={e => handleCopy(e, element.address)} sx={{ m: 0.5, ml: 1, p: 0 }}>
                      <CopySVG style={{ width: '14px', height: '14px' }} />
                    </IconButton>
                  </div>
                </TableCell>
                <TableCell sx={{ color: '#FFFFFF66', fontSize: 10, padding: '14px 0', borderColor: '#333' }}>
                  {isFake ? (
                    <Skeleton variant="text" width={34} height={12} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                  ) : (
                    element.liquidity
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    color: isFake ? undefined : element.pnl?.startsWith('-') ? '#C35268' : '#6EE297',
                    fontSize: 10,
                    padding: '14px 0',
                    textAlign: 'end',
                    borderColor: '#333',
                  }}
                >
                  {isFake ? (
                    <Skeleton variant="text" width={27} height={12} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                  ) : (
                    element.pnl || '—'
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DevTokens;
