import {
  Box, CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import MiniChart from 'pages/DEX-exchange/common/MiniChart/MiniChart';
import { ReactComponent as CopySVG } from 'pages/DEX-exchange/svg/copy.svg';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from 'store/slice/snackbarSlice';
import React, { useEffect, useState } from 'react';
import { getHolders } from 'pages/DEX-exchange/api/coinGeckoApi';
import { formatMarketCap } from 'pages/DEX-exchange/helpers/formatToThausandOrMillions';
import { RootState } from '../../../../../store/store';

const Holders: React.FC<{ pumpAddress: string }> = ({ pumpAddress }) => {
  const dispatch = useDispatch();
  const [holdersList, setHoldersList] = useState([] as any[]);
  const [summary, setSummary] = useState({
    holdersCount: 0,
    top10Percentage: 0,
    botUsers: 0,
    insidersHolding: 0,
  });
  const [loading, setLoading] = useState(true);
  const address = useSelector((state: RootState) => state.activeAddress.address);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    const fetchHoldersData = async () => {
      try {
        const holdersData = await getHolders(pumpAddress);
        const attributes = holdersData.topHolders.data.attributes;

        // Предполагаем, что response может содержать поля holders_count, bot_users, insiders_holding.
        // Если их нет, используем fallback.
        const holdersCount = holdersData.historicalHolders?.data.at(-1)?.attributes?.holder_count;
        const topHolders = attributes.top_holders || [];
        // Суммируем owner_percent для первых 10 элементов
        const top10Percentage = topHolders
          .slice(0, 10)
          .reduce((acc: number, cur: any) => acc + cur.owner_percent, 0);
        const botUsers = attributes.bot_users || 0;
        const insidersHolding = attributes.insiders_holding || 0;

        // Обновляем summary
        setSummary({
          holdersCount,
          top10Percentage,
          botUsers,
          insidersHolding,
        });

        // Если нужно отобразить таблицу с топ холдерами
        setHoldersList(topHolders);
        setLoading(false);
      } catch (e) {
        console.error('Ошибка при получении токенов разработчика:', e);
        setLoading(false);
      }
    };

    fetchHoldersData();
    interval = setInterval(fetchHoldersData, 1000);

    return () => clearInterval(interval);
  }, [pumpAddress, address]);

  const handleCopy = (event: React.MouseEvent<HTMLButtonElement>, data: string) => {
    event.stopPropagation();
    navigator.clipboard.writeText(data).then(
      () => dispatch(showSnackbar({ message: 'Wallet address copied to clipboard!' })),
      () => dispatch(showSnackbar({ message: 'Failed to copy wallet address.', status: 'error' })),
    );
  };

  const getPercentColorValue = (percentColor: string) =>
    percentColor === 'positive' ? '#4caf50' : percentColor === 'negative' ? '#f44336' : '#aaa';

  const shortAddress = (address: string) => {
    if (address.length <= 8) return address;
    return address.slice(0, 4) + '...' + address.slice(-4);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress
          size={100}
          sx={{
            '& .MuiCircularProgress-svg': {
              height: 'auto',
              width: 'auto',
            },
          }}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start',gap:'16px', marginTop: '16px', marginBottom: '8px' }}>
        {summary.holdersCount &&
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '8px 0',
            border: '1px solid #FFFFFF33',
            flex: '1',
            maxWidth: '88px',
            height: '84px',
            borderRadius: '8px',
          }}
        >
          <Typography sx={{ fontSize: '10px', color: '#FFFFFF66', marginBottom: '4px' }}>Holders</Typography>
          <Typography sx={{ fontSize: '12px', color: '#FFF', marginBottom: '8px' }}>
            {summary.holdersCount}
          </Typography>
          {/*<span className="tw-flex tw-justify-end">*/}
          {/*  <MiniChart*/}
          {/*    data={[0, 15, 45, 30, 30, 29, 40]}*/}
          {/*    strokeColor={getPercentColorValue('positive')}*/}
          {/*    fillColor={getPercentColorValue('positive')}*/}
          {/*    chartMargin={[5, 5, 5, 5]}*/}
          {/*    width={42}*/}
          {/*    height={13}*/}
          {/*    tension={1}*/}
          {/*  />*/}
          {/*</span>*/}
        </Box>
        }
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '8px 0',
            border: '1px solid #FFFFFF33',
            flex: '1',
            maxWidth: '88px',
            height: '84px',
            borderRadius: '8px',
          }}
        >
          <Typography sx={{ fontSize: '10px', color: '#FFFFFF66', marginBottom: '4px' }}>
            Top 10 holders
          </Typography>
          <Typography sx={{ fontSize: '12px', color: '#FFF', marginBottom: '8px' }}>
            {summary.top10Percentage.toFixed(2)}%
          </Typography>
          {/*<span className="tw-flex tw-justify-end">*/}
          {/*  <MiniChart*/}
          {/*    data={[0, 15, 45, 30, 30, 29, 40]}*/}
          {/*    strokeColor={getPercentColorValue('positive')}*/}
          {/*    fillColor={getPercentColorValue('positive')}*/}
          {/*    chartMargin={[5, 5, 5, 5]}*/}
          {/*    width={42}*/}
          {/*    height={13}*/}
          {/*    tension={1}*/}
          {/*  />*/}
          {/*</span>*/}
        </Box>
        {/*<Box*/}
        {/*  sx={{*/}
        {/*    display: 'flex',*/}
        {/*    flexDirection: 'column',*/}
        {/*    alignItems: 'center',*/}
        {/*    justifyContent: 'center',*/}
        {/*    padding: '8px 0',*/}
        {/*    border: '1px solid #FFFFFF33',*/}
        {/*    flex: '1',*/}
        {/*    maxWidth: '88px',*/}
        {/*    height: '84px',*/}
        {/*    borderRadius: '8px',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Typography sx={{ fontSize: '10px', color: '#FFFFFF66', marginBottom: '4px' }}>Bot users</Typography>*/}
        {/*  <Typography sx={{ fontSize: '12px', color: '#FFF', marginBottom: '8px' }}>*/}
        {/*    {summary.botUsers}*/}
        {/*  </Typography>*/}
        {/*  /!*<span className="tw-flex tw-justify-end">*!/*/}
        {/*  /!*  <MiniChart*!/*/}
        {/*  /!*    data={[0, 15, 45, 30, 30, 29, 40]}*!/*/}
        {/*  /!*    strokeColor={getPercentColorValue('positive')}*!/*/}
        {/*  /!*    fillColor={getPercentColorValue('positive')}*!/*/}
        {/*  /!*    chartMargin={[5, 5, 5, 5]}*!/*/}
        {/*  /!*    width={42}*!/*/}
        {/*  /!*    height={13}*!/*/}
        {/*  /!*    tension={1}*!/*/}
        {/*  /!*  />*!/*/}
        {/*  /!*</span>*!/*/}
        {/*</Box>*/}
        {/*<Box*/}
        {/*  sx={{*/}
        {/*    display: 'flex',*/}
        {/*    flexDirection: 'column',*/}
        {/*    alignItems: 'center',*/}
        {/*    justifyContent: 'center',*/}
        {/*    padding: '8px 0',*/}
        {/*    border: '1px solid #FFFFFF33',*/}
        {/*    flex: '1',*/}
        {/*    maxWidth: '88px',*/}
        {/*    height: '84px',*/}
        {/*    borderRadius: '8px',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Typography sx={{ fontSize: '10px', color: '#FFFFFF66', marginBottom: '4px' }}>*/}
        {/*    Insiders holding*/}
        {/*  </Typography>*/}
        {/*  <Typography sx={{ fontSize: '12px', color: '#FFF', marginBottom: '8px' }}>*/}
        {/*    {summary.insidersHolding.toFixed(2)}%*/}
        {/*  </Typography>*/}
        {/*  /!*<span className="tw-flex tw-justify-end">*!/*/}
        {/*  /!*  <MiniChart*!/*/}
        {/*  /!*    data={[0, 15, 45, 30, 30, 29, 40]}*!/*/}
        {/*  /!*    strokeColor={getPercentColorValue('positive')}*!/*/}
        {/*  /!*    fillColor={getPercentColorValue('positive')}*!/*/}
        {/*  /!*    chartMargin={[5, 5, 5, 5]}*!/*/}
        {/*  /!*    width={42}*!/*/}
        {/*  /!*    height={13}*!/*/}
        {/*  /!*    tension={1}*!/*/}
        {/*  /!*  />*!/*/}
        {/*  /!*</span>*!/*/}
        {/*</Box>*/}
      </Box>
      <TableContainer component={Paper}>
        <Table
          sx={{
            width: '100%',
            backgroundColor: '#000',
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: '#FFFFFF99',
                  fontSize: 10,
                  lineHeight: '10px',
                  borderBottom: '1px solid #333',
                  padding: '21px 0px 13px 0px',
                  width: '20%',
                }}
              >
                №
              </TableCell>
              <TableCell
                sx={{
                  color: '#FFFFFF99',
                  fontSize: 10,
                  lineHeight: '10px',
                  borderBottom: '1px solid #333',
                  padding: '21px 0px 13px 0px',
                }}
              >
                Address
              </TableCell>
              <TableCell
                sx={{
                  color: '#FFFFFF99',
                  fontSize: 10,
                  lineHeight: '10px',
                  borderBottom: '1px solid #333',
                  padding: '21px 0px 13px 0px',
                  width: '25%',
                }}
              >
                Holding
              </TableCell>
              <TableCell
                sx={{
                  color: '#FFFFFF99',
                  fontSize: 10,
                  lineHeight: '10px',
                  borderBottom: '1px solid #333',
                  padding: '21px 0px 13px 0px',
                  textAlign: 'end',
                }}
              >
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {holdersList.map((element: any, index: number) => (
              <TableRow
                key={index}
                sx={{
                  borderBottom: '1px solid #333',
                  '&:last-of-type': {
                    borderBottom: '1px solid #333',
                  },
                }}
              >
                <TableCell
                  sx={{
                    color: '#fff',
                    fontSize: 12,
                    borderBottom: '1px solid #333',
                    verticalAlign: 'middle',
                    padding: '14px 0',
                    width: '20%',
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    color: '#fff',
                    fontSize: 12,
                    borderBottom: '1px solid #333',
                    verticalAlign: 'middle',
                    padding: '14px 0',
                  }}
                >
                  {shortAddress(element.wallet_address)}
                  <IconButton
                    onClick={event => handleCopy(event, element.wallet_address)}
                    sx={{ m: 0.5, ml: 1, p: 0 }}
                  >
                    <CopySVG style={{ width: '14px', height: '14px' }} />
                  </IconButton>
                </TableCell>
                <TableCell
                  sx={{
                    color: '#fff',
                    fontSize: 12,
                    borderBottom: '1px solid #333',
                    verticalAlign: 'middle',
                    padding: '14px 0',
                    width: '25%',
                  }}
                >
                  {`${element.owner_percent?.toFixed(2)}%`}
                </TableCell>
                <TableCell
                  sx={{
                    color: '#fff',
                    fontSize: 12,
                    borderBottom: '1px solid #333',
                    verticalAlign: 'middle',
                    padding: '14px 0',
                    textAlign: 'end',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {formatMarketCap(element.amount)}
                    <Typography sx={{ color: '#FFFFFF66', fontSize: '10px' }}>
                      {element.value_in_usd}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Holders;
