import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import useAppData from './hooks/use-app-data';
import routes from './routes';
import { AnimatePresence } from 'framer-motion';
import tg from 'utils/tg';
import Navigation from 'UI/Navigation';
import GameRules from 'UI/GameRules';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isMobileDevice } from 'services/check-mobile';
import IsNotMobile from 'UI/IsNotMobile';
import Onboarding from './UI/Onboarding';
import Trading from 'pages/DEX-exchange/Components/Trading/Trading';
import DEXexchange from 'pages/DEX-exchange/Dex-exchange';
import { ReactComponent as LogoSVG } from 'pages/DEX-exchange/svg/main-logo.svg';
import { Box, Button, CircularProgress, createTheme, Snackbar, ThemeProvider, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { ReactComponent as SuccessAlertSVG } from 'pages/DEX-exchange/svg/success-alert.svg';
import { ReactComponent as ErrorAlertSVG } from 'pages/DEX-exchange/svg/error-alert.svg';
import { ReactComponent as DoneAlertSVG } from 'pages/DEX-exchange/svg/done-alert.svg';
import { hideSnackbar } from 'store/slice/snackbarSlice';
import StandartModal from 'pages/DEX-exchange/common/StandartModal/StandartModal';
import MiniModal from 'pages/DEX-exchange/common/MiniModal/MiniModal';
import { ApolloProvider } from '@apollo/client';
import { client } from './apolloClient';
import { BlockchainServiceProvider } from 'pages/DEX-exchange/helpers/blockchainServiceContext';

function App() {
  const { AppContext, AppContextValue } = useAppData();
  const element = useRoutes(routes.map(({ route }) => route));
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState<boolean | null>(null);
  const [showOnboarding, setShowOnboarding] = useState<boolean>(!hasCompletedOnboarding());
  const dispatch = useDispatch();

  // Получаем данные из Redux
  const snackbarMessage = useSelector((state: RootState) => state.snackbar.message);
  const snackbarOpen = useSelector((state: RootState) => state.snackbar.isOpen);
  const snackbarStatus = useSelector((state: RootState) => state.snackbar.status);

  useEffect(() => {
    if (tg && tg.initData) {
      tg.BackButton.onClick(() => {
        window.history.back();
      }); // Показываем кнопку "Назад" в Telegram
      (tg as any).requestFullscreen(); // Запрашивает полноэкранный режим

      tg.expand();
      tg.disableVerticalSwipes();
    }
  }, []);

  useEffect(() => {
    // Проверка, показывать ли кнопку назад (не на главной странице)
    if (location.pathname !== '/' && location.pathname !== '/dex') {
      tg.BackButton.show();
    } else {
      tg.BackButton.hide();
    }

    const onBack = () => {
      if (location.pathname !== '/') {
        navigate(-1);
      } else {
        tg.close();
      }
    };

    tg.BackButton.onClick(onBack);

    return () => {
      tg.BackButton.offClick(onBack);
    };
  }, [location.pathname, navigate]);

  useEffect(() => {
    const checkDevice = async () => {
      const result = await isMobileDevice();
      setIsMobile(result);
    };

    checkDevice();
  }, []);

  const handleOnboardingFinish = () => {
    setOnboardingCompleted();
    setShowOnboarding(false);
  };

  function hasCompletedOnboarding(): boolean {
    return localStorage.getItem('onboardingCompleted') === 'true';
  }

  function setOnboardingCompleted(): void {
    localStorage.setItem('onboardingCompleted', 'true');
  }

  if (!element) return null;

  const { showGameRules, setshowGameRules } = AppContextValue;

  // Определяем цвет рамки и текста в зависимости от статуса
  const getSnackbarStyles = () => {
    switch (snackbarStatus) {
      case 'success':
        return { border: '2px solid #6EE297', color: '#6EE297', icon: <SuccessAlertSVG style={{ width: '18px', height: '14px' }} /> }; // Зелёный
      case 'error':
        return { border: '2px solid #C35268', color: '#C35268', icon: <ErrorAlertSVG style={{ width: '16px', height: '16px' }} /> }; // Красный
      case 'warning':
        return { border: '2px solid #CFBB61', color: '#CFBB61', icon: '' }; // Желтый
      case 'done':
        return { border: '2px solid #FFFFFF', color: '#FFFFFF', icon: <DoneAlertSVG style={{ width: '22px', height: '13px' }} /> }; // Сделано
      case 'waiting':
        return { border: '2px solid #FFFFFF', color: '#FFFFFF', icon: <CircularProgress style={{ width: '16px', height: '16px' }} /> }; // Ожидание
      default:
        return { border: '2px solid #FFFFFF', color: '#FFFFFF', icon: '' }; // Серый
    }
  };

  const { border, color, icon } = getSnackbarStyles();

  const theme = createTheme({
    components: {
      MuiSkeleton: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(255,255,255,0.15)',
          },
        },
      },
    },
  });

  if (false) {
    return <IsNotMobile />;
  } else {
    return (
      <div>
        <ThemeProvider theme={theme}>
        <BlockchainServiceProvider>
          <div className='tw-flex tw-justify-center tw-pt-[65px] tw-mb-[-25px] tw-pb-4.5'>
            <LogoSVG style={{ width: '32px', height: '32px' }} />
          </div>
          <ApolloProvider client={client}>
            <AppContext.Provider value={AppContextValue}>
              <AnimatePresence mode='sync'>{false ? <Onboarding onFinish={handleOnboardingFinish} /> : React.cloneElement(element, { key: location.pathname })}</AnimatePresence>
              {/* {!showOnboarding && <Navigation />}
        {!showOnboarding && showGameRules && (
          <GameRules close={() => setshowGameRules(false)} />
        )} */}
              {/* <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            draggableDirection="y"
            pauseOnHover
            theme="dark"
            transition={Bounce}
          /> */}
            </AppContext.Provider>
          </ApolloProvider>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={2000}
            onClose={() => {
              console.log('Snackbar onClose triggered, reason:');
              dispatch(hideSnackbar());
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{
              '& .MuiSnackbar-root': { zIndex: '999999' },
              '& .MuiSnackbarContent-root': {
                backgroundColor: '#1a1a1a',
                fontSize: '14px',
                fontWeight: '500',
                borderRadius: '8px',
                padding: '10px 16px 10px',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                border,
                color,
                marginTop:'85px'
              },
            }}
            message={
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  {icon}
                  <Typography sx={{ color: 'inherit' }}>{snackbarMessage}</Typography>
                </Box>
                {snackbarStatus === 'done' ? (
                  <>
                    <Button>Кнопка</Button>
                  </>
                ) : (
                  ''
                )}
              </>
            }
          />
          <StandartModal />
        </BlockchainServiceProvider>
        <MiniModal />
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
