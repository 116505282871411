export enum TrendingEnum {
  TRENDING = 'Trending',
  NEW_PAIRS = 'New pairs',
  WATCHLIST = 'Watchlist',
}

export enum PeriodEnum {
  TIME_1M = '1m',
  TIME_5M = '5m',
  TIME_1H = '1h',
  TIME_6H = '6h',
  TIME_12H = '12h',
  TIME_24H = '24h',
}

export enum SortOrder {
  ASC = 'asc',
  DESK = 'desk',
}
