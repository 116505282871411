import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { startExchangeRatePolling } from "store/actions/exchangeRate";
import { Buffer } from "buffer";
window.Buffer = Buffer;
//Запускаем запрос курса и обновляем его каждые 15 секунд
startExchangeRatePolling(15000)(store.dispatch);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
