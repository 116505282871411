import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as TrendingIcon } from 'pages/DEX-exchange/svg/tranding-menu.svg';
import { ReactComponent as WalletIcon } from 'pages/DEX-exchange/svg/wallet-menu.svg';
import { ReactComponent as PositionsIcon } from 'pages/DEX-exchange/svg/positions-menu.svg';
import { ReactComponent as SettingsIcon } from 'pages/DEX-exchange/svg/settings-menu.svg';
import { ReactComponent as SupportIcon } from 'pages/DEX-exchange/svg/support.svg';
import { ReactComponent as TelegramIcon } from 'pages/DEX-exchange/svg/telegram.svg';
import { ReactComponent as ArrowRightIcon } from 'pages/DEX-exchange/svg/arrow-right.svg';
import { openModal } from 'store/slice/modalSlice';
import Settings from 'pages/DEX-exchange/Modals/Settings';
import { useDispatch } from 'react-redux';

interface MenuProps {
  setActiveTab: (tab: string) => void;
}

const Menu: React.FC<MenuProps> = ({ setActiveTab }) => {
  const dispatch = useDispatch();

  const menuItems = [
    { icon: <TrendingIcon style={{ height: '19px' }} />, label: 'Trending', tab: 'trading' },
    { icon: <WalletIcon style={{ height: '19px' }} />, label: 'Wallet', tab: 'wallet' },
    { icon: <PositionsIcon style={{ height: '19px' }} />, label: 'Positions', tab: 'positions' },
    {
      icon: <SettingsIcon style={{ height: '19px' }} />,
      label: 'Settings',
      func: () => {
        dispatch(
          openModal({
            title: 'Settings',
            component: <Settings />,
          }),
        );
      },
    },
    {
      icon: <SupportIcon style={{ height: '19px' }} />,
      label: 'Technical support',
      func: () => {
        window.open('https://t.me/protonsyst_chat', '_blank', 'noopener,noreferrer');
      },
    },
    {
      icon: <TelegramIcon style={{ height: '19px' }} />,
      label: 'Telegram channel',
      func: () => {
        window.open('https://t.me/protonsyst', '_blank', 'noopener,noreferrer');
      },
    },
  ];

  return (
    <Box sx={{ width: 'calc(100% + 16px)', display: 'flex', flexDirection: 'column', margin: '0 -8px' }}>
      <Typography variant="h6" sx={{
        display: 'flex',
        justifyContent: 'space-between',
        color: '#fff',
        marginBottom: '16px',
        padding: '0 16px 17px',
        fontSize: '16px',
        borderBottom: '1px solid #ffffff',
      }}>
        Menu
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto', padding: '0 8px' }}>
        {menuItems.map((item, index) => (
          <Box
            key={index}
            onClick={() => (item.func ? item.func() : setActiveTab(item.tab))}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '12px 16px',
              cursor: 'pointer',
              height: '48px',
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              {item.icon}
              <Typography sx={{ color: '#fff', fontSize: '17px' }}>{item.label}</Typography>
            </Box>
            <ArrowRightIcon style={{ width: '5px' }} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Menu;
