import { PeriodEnum, TrendingEnum } from 'pages/DEX-exchange/Components/Trading/ts/TradingEnums';
import { FilterData } from 'pages/DEX-exchange/Components/Trading/trading.interfaces';
import { decryptPrivateKey } from 'pages/DEX-exchange/helpers/encryption';
import { Connection, PublicKey } from '@solana/web3.js';
import { getAllTransactions } from 'pages/DEX-exchange/Components/Positions/services/transactions';

const baseUrl = process.env.REACT_APP_BACK_URL;


export async function authenticateUser(telegramId: string | number, encryptedKey?: string): Promise<string> {
  const response = await fetch(`${baseUrl}/api/auth`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ telegram_id: telegramId, private_key: encryptedKey }),
  });
  const data = await response.json();
  // data.private_key приходит зашифрованным, дешифруем его
  return decryptPrivateKey(data.private_key);
}

export async function getTrends({
                                  activeTrending,
                                  activePeriod,
                                  searchQuery,
                                  appliedFilters,
                                }: {
  activeTrending: TrendingEnum;
  activePeriod: PeriodEnum;
  searchQuery: string;
  appliedFilters: FilterData | null;
}): Promise<any> {
  if (searchQuery) {
    const url = `${baseUrl}/api/search?query=${encodeURIComponent(searchQuery)}`;
    const response = await fetch(url);
    if (!response.ok) throw new Error(`Error fetching pools: ${response.statusText}`);
    return await response.json();
  }

  const response = await fetch(`${baseUrl}/api/pools`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ trending: activeTrending, period: activePeriod, filters: appliedFilters }),
  });

  if (!response.ok) throw new Error(`Error fetching pools: ${response.statusText}`);
  return await response.json();
}

export async function getTokenDetails(address: string): Promise<any> {
  const url = `${baseUrl}/api/token/${encodeURIComponent(address)}`;

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error(`Error fetching token details: ${response.statusText}`);
  }

  return await response.json();
}

export async function getDeveloperTokens(address: string){
  const response = await fetch(`${baseUrl}/api/developer-tokens`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ tokenAddress: address }),
  });
  if (!response.ok) {
    console.error('Ошибка при получении данных:', response.statusText);
    return;
  }
  return await response.json();
}
export async function getHolders(address: string){
  const response = await fetch(`${baseUrl}/api/getHolders/${address}`);
  if (!response.ok) {
    console.error('Ошибка при получении данных:', response.statusText);
    return;
  }
  return await response.json();
}

export async function getHistory(address: string, page = 1, pageSize = 10) {
  const response = await fetch(`${baseUrl}/api/transactions/${address}?page=${page}&pageSize=${pageSize}`);
  if (!response.ok) {
    console.error('Ошибка при получении данных:', response.statusText);
    return;
  }
  return await response.json();
}


export async function getCandlesticks(
  poolId: string,
  pairId: string,
  resolution = '720',
  from_timestamp: string,
  to_timestamp: string,
  for_update = false,
  count_back = 329,
  currency = 'usd',
  is_inverted = false
) {
  // Формируем строку query параметров
  const queryParams = new URLSearchParams({
    resolution: String(resolution),
    from_timestamp: String(from_timestamp),
    to_timestamp: String(to_timestamp),
    for_update: String(for_update),
    count_back: String(count_back),
    currency: String(currency),
    is_inverted: String(is_inverted)
  });

  const url = `${baseUrl}/api/candlesticks/${poolId}/${pairId}?${queryParams.toString()}`;

  const response = await fetch(url);
  if (!response.ok) {
    console.error('Ошибка при получении данных:', response.statusText);
    return;
  }
  return await response.json();
}

export async function swapCoin(transaction: string) {
  try {
    const response = await fetch(`${baseUrl}/api/swap`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ transaction})
    });

    if (!response.ok) {
      console.error('Ошибка при свапе монеты:', response.statusText);
      return;
    }

    return await response.json();
  } catch (error) {
    console.error('Ошибка при вызове API swap:', error);
  }
}
export async function getStats(pumpAddress: string) {
  try {
    const url = `https://api.dexscreener.com/latest/dex/tokens/${pumpAddress}`;

    const response = await fetch(url);
    if (!response.ok) {
      console.error('Ошибка при получении данных:', response.statusText);
      return;
    }

    return await response.json();
  } catch (error) {
    console.error('Ошибка при вызове API swap:', error);
  }
}

export async function getHistoryFromGecko(address: string) {
  try {
    const options = {
      method: 'GET',
      headers: {accept: 'application/json', 'x-cg-pro-api-key': 'CG-egrwjs5PC9Z6AdmqVJZNp9Me'}
    };

    const response = await fetch(`https://pro-api.coingecko.com/api/v3/onchain/networks/solana/pools/${address}/trades`, options)

    if (!response.ok) {
      console.error('Ошибка при получении данных:', response.statusText);
      return;
    }

    return response.json();
  } catch (error) {
    console.error('Ошибка при вызове API swap:', error);
  }
}

export async function getCoinsByAddressesFromGecko(addresses: string[]) {
  try {
    const options = {
      method: 'GET',
      headers: {accept: 'application/json', 'x-cg-pro-api-key': 'CG-egrwjs5PC9Z6AdmqVJZNp9Me'}
    };

    const response = await fetch(`https://pro-api.coingecko.com/api/v3/onchain/networks/solana/tokens/multi/${addresses.join('%2C')}?include=top_pools`, options)

    if (!response.ok) {
      console.error('Ошибка при получении данных:', response.statusText);
      return;
    }

    return response.json();
  } catch (error) {
    console.error('Ошибка при вызове API swap:', error);
  }
}

export async function getMyPosition({ walletAddress, tokenMint,rate }: any) {
  // Запрос для получения токенового счета пользователя по mint
  const getTokenAccountBody = {
    jsonrpc: "2.0",
    id: "getTokenAccounts",
    method: "getTokenAccountsByOwner",
    params: [
      walletAddress,
      { mint: tokenMint },
      { encoding: "jsonParsed" }
    ]
  };

  try {
    // 1. Получаем баланс токена (Remaining)
    const balanceRes = await fetch(process.env.REACT_APP_HELIUS_RPC_URL!, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(getTokenAccountBody)
    });
    const balanceData = await balanceRes.json();
    const token = balanceData?.result?.value?.[0];
    let result = {} as any;

    if(!token.account.data?.parsed?.info?.mint){
      return null
    }

      const mint = token.account.data.parsed.info.mint;
        result = {
          mint,
          invested: 0,
          sold: 0,
          tokensBought: 0,
          tokensSold: 0,
          remainingTokens: token.account.data.parsed.info.tokenAmount.uiAmount
        };

      // Получаем адрес токенового счёта и транзакции:
      const tokenAddress = new PublicKey(token.pubkey);
      const signaturesInfo = await connection.getSignaturesForAddress(tokenAddress);
      const allSignatures = signaturesInfo.map(item => item.signature);
      const allTransactions = await getAllTransactions(allSignatures);

      // Перебираем транзакции для данного токена
      for (const tx of allTransactions) {
        // Отбираем tokenTransfers, которые касаются данного токена
        const transfer = tx.accountData.find((t: any) => t.account === walletAddress);
        if (!transfer) continue;
        if (transfer.nativeBalanceChange < 0) {
          result.invested += Math.abs((transfer.nativeBalanceChange / 1e9));
          result.tokensBought += Math.abs(transfer.nativeBalanceChange);
        } else {
          result.sold += Math.abs((transfer.nativeBalanceChange / 1e9));
          result.tokensSold += Math.abs(transfer.nativeBalanceChange);
        }
      }

    const tokenDataResult = await getCoinsByAddressesFromGecko([result].map(token => token.mint));
    const data = [result].map(token => {
      const tokenData = tokenDataResult.data.find((e: any) => e.attributes.address.toLowerCase() === token.mint.toLowerCase());
      const relation = tokenDataResult.included.find((e: any) => e.relationships.base_token.data.id.replace('solana_', '').toLowerCase() === token.mint.toLowerCase());
      const remaining = token.remainingTokens * tokenData.attributes.price_usd;
      const investedInUsd = token.invested * rate!;
      const sold = token.sold * rate!;
      const pnl = remaining + sold - investedInUsd;
      const pnlPercent = investedInUsd > 0 ? (pnl / investedInUsd) * 100 : 0;
      return {
        ...token,
        ...tokenData.attributes,
        remaining,
        poolAddress: relation.attributes.address,
        pnl,
        pnlPercent,
        sold,
        investedInUsd
      };
    })[0];

    // 4. Возвращаем объект позиции для UI
    return {
      label: "My position",
      items: [
        { title: "Invested", value: `$${data.investedInUsd.toFixed(2)}` },
        { title: "Sold", value: data.sold > 0 ? `$${data.sold.toFixed(2)}` : "-" },
        { title: "Remaining", value: `$${data.remaining.toFixed(2)}` },
        {
          title: "PnL",
          value: `$${data.pnl.toFixed(6)}`,
          color: data.pnl >= 0 ? "#6EE297" : "#C35268"
        },
        {
          title: "PnL (%)",
          value: `${data.pnl >= 0 ? "+" : ""}${data.pnlPercent.toFixed(2)}%`,
          color: data.pnl >= 0 ? "#6EE297" : "#C35268"
        }
      ]
    };
  } catch (error) {
    console.error("Ошибка при вызове getMyPosition: ", error);
  }
}


const connection = new Connection(process.env.REACT_APP_HELIUS_RPC_URL!);

// Определяем предполагаемую структуру (layout) данных аккаунта
// Предположим, что контракт хранит следующие поля, по 8 байт каждое, в little-endian:
// 0:  iVTOKEN
// 8:  iVSOL
// 16: current_vTOKEN
// 24: current_vSOL
// 32: tokens_sold
const BONDING_CURVE_LAYOUT = {
  iVTOKEN: { offset: 0, length: 8 },
  iVSOL: { offset: 8, length: 8 },
  current_vTOKEN: { offset: 16, length: 8 },
  current_vSOL: { offset: 24, length: 8 },
  tokens_sold: { offset: 32, length: 8 },
};

// Функция для чтения 64-битного числа (unsigned, little-endian) из Buffer
function readUInt64LE(buffer: any, offset: any) {
  return buffer.readBigUInt64LE(offset);
}

export async function getBondingCurveData(address: string) {
  const poolAddress = new PublicKey(address);
  const accountInfo = await connection.getAccountInfo(poolAddress);
  if (!accountInfo) {
    console.error("Account not found");
    return;
  }
  const data = accountInfo.data;
  if (data.length < 40) {
    console.error("Account data too short. Проверьте layout аккаунта.");
    return;
  }

  // Разбираем данные согласно layout
  const iVTOKEN = Number(readUInt64LE(data, BONDING_CURVE_LAYOUT.iVTOKEN.offset));
  const iVSOL = Number(readUInt64LE(data, BONDING_CURVE_LAYOUT.iVSOL.offset));
  const current_vTOKEN = Number(readUInt64LE(data, BONDING_CURVE_LAYOUT.current_vTOKEN.offset));
  const current_vSOL = Number(readUInt64LE(data, BONDING_CURVE_LAYOUT.current_vSOL.offset));
  const tokens_sold = Number(readUInt64LE(data, BONDING_CURVE_LAYOUT.tokens_sold.offset));

  // Вычисляем коэффициент масштабирования:
  // Ожидаемое iVTOKEN в человекочитаемом виде ≈ 1.073e9
  const expected_iVTOKEN = 1.073e9;
  const scale = iVTOKEN / expected_iVTOKEN;

  // Нормализуем tokens_sold:
  const normalized_tokens_sold = tokens_sold / scale;

  // Вместо того, чтобы вычислять порог через decimals, посчитаем raw общий выпуск:
  // Ожидаемый общий выпуск (человекочитаемый) = 1e9 токенов
  const totalSupply = 1_000_000_000;
  const totalSupplyRaw = totalSupply * scale;
  const migrationThresholdRaw = 0.8 * totalSupplyRaw;
  const normalized_migrationThreshold = migrationThresholdRaw / scale;

  // Расчёт процента прохождения bonding curve:
  const bondingCurvePercent = (normalized_tokens_sold / normalized_migrationThreshold) * 100;
  return bondingCurvePercent.toFixed(2);
}





