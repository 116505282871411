import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import TokenInfo from './TokenInfo';
import TokenNavbar from './TokenNavbar';
import Trending from './Tabs/Trending';
import Stats from './Tabs/Stats';
import Holders from './Tabs/Holders';
import DevTokens from './Tabs/DevTokens';
import { ReactComponent as TrendingTabSVG } from 'pages/DEX-exchange/svg/trending-tab.svg';
import { ReactComponent as StatsTabSVG } from 'pages/DEX-exchange/svg/stats-tab.svg';
import { ReactComponent as HoldersTabSVG } from 'pages/DEX-exchange/svg/holders-tab.svg';
import { ReactComponent as DevTokensTabSVG } from 'pages/DEX-exchange/svg/dev-tokens-tab.svg';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setActiveAddress } from '../../../../store/slice/activeAddressSlice';
import { getBondingCurveData, getTokenDetails } from 'pages/DEX-exchange/api/coinGeckoApi';
import { formatTimeDifference } from 'pages/DEX-exchange/helpers/formatTimeDifference';
import { formatMarketCap } from 'pages/DEX-exchange/helpers/formatToThausandOrMillions';
import { formatSmallPrice } from 'pages/DEX-exchange/helpers/formatSmallPrice';


const Token: React.FC = () => {
  const dispatch = useDispatch();
  const { address } = useParams<{ address: string }>();
  const [token, setToken] = useState({} as any);
  const [activeTab, setActiveTab] = useState('Trending');
  const [loading, setLoading] = useState<any>(true);


  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    dispatch(setActiveAddress(address!));

    const fetchData = async (withLoading = false) => {
      if (withLoading) setLoading(true);

      try {
        const trend = await getTokenDetails(address!);
        const baseTokenId = trend.data.attributes.base_token_id;
        const priceInUsd = parseFloat(trend.data.attributes.price_in_usd);
        const baseToken = trend.included.find(
          (item: any) => item.type === 'token' && item.id === baseTokenId
        );
        let supply = baseToken.attributes.circulating_supply;

        const rawMarketCap = supply && priceInUsd ? supply * priceInUsd : 0;
        const marketCap = rawMarketCap ? formatMarketCap(rawMarketCap) : 'N/A';

        const pairRelationship = trend.data.relationships.pairs.data;
        const pairId = pairRelationship && pairRelationship.length > 0 ? pairRelationship[0].id : null;

        const links = baseToken?.attributes?.links || {};

        const socials = {
          website: links.websites?.[0] || null,
          twitter: links.twitter_handle ? `https://twitter.com/${links.twitter_handle}` : null,
          telegram: links.telegram_handle ? `https://t.me/${links.telegram_handle}` : null,
          discord: links.discord_url || null,
          github: links.github_repo_name ? `https://github.com/${links.github_repo_name}` : null,
          medium: links.medium_handle ? `https://medium.com/@${links.medium_handle}` : null,
          youtube: links.youtube_handle ? `https://youtube.com/@${links.youtube_handle}` : null,
          facebook: links.facebook_handle ? `https://facebook.com/${links.facebook_handle}` : null,
          instagram: links.instagram_handle ? `https://instagram.com/${links.instagram_handle}` : null,
          reddit: links.subreddit_handle ? `https://reddit.com/r/${links.subreddit_handle}` : null,
          pump: trend.data.attributes.swap_url ? `https://pump.fun/coin/${baseToken.attributes.address}`  : null,
        };
        let bCurve =  await getBondingCurveData(trend.data.attributes.address).catch(console.error);
        // let bCurve = await fetchBCurve(trend.data.attributes.address);

        const data = {
          id: trend.data.id,
          pairId,
          avatar: baseToken?.attributes?.image_url,
          pumpAddress: baseToken.attributes.address,
          name: trend.data.attributes.name,
          time: formatTimeDifference(trend.data.attributes.pool_created_at),
          address: trend.data.attributes.address,
          priceUsd: `$${formatSmallPrice(priceInUsd,7)}`,
          priceInUsdOriginal: trend.data.attributes.price_in_usd,
          price: `$${formatMarketCap(trend.data.attributes.to_volume_in_usd) || 'N/A'}`,
          liquidity: `$${formatMarketCap(trend.data.attributes.reserve_in_usd) || 'N/A'}`,
          liquidityOriginal: (+trend.data.attributes.reserve_in_usd)?.toFixed(2),
          marketCap: marketCap ? `$${marketCap}` : 'N/A',
          supply: supply ? formatMarketCap(supply) : 'N/A',
          bCurve: bCurve && +bCurve < 100 ?`${bCurve}%`: '-',
          gt_score: trend.data.attributes.gt_score?.toFixed(0),
          ...socials
        };
        setToken(data);
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      } finally {
        if (withLoading) setLoading(false);
      }
    };

    fetchData(true);

    intervalId = setInterval(() => {
      fetchData(false);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [address]);

  const tabs = [
    {
      label: 'Trending',
      icon: <TrendingTabSVG style={{ width: '13px', height: '14px', marginRight: '7px' }} />,
      component: token ? <Trending poolId={token?.id} pairId={token?.pairId} address={token.address} name={token.name ? token.name.split(' / ')[0]: ''}/>: '',
    },
    {
      label: 'Stats',
      icon: <StatsTabSVG style={{ width: '12px', height: '10px', marginRight: '7px' }} />,
      component: <Stats address={token.pumpAddress} pairAddress={token.address} priceInUsd={token.priceInUsdOriginal}/>,
    },
    {
      label: 'Holders',
      icon: <HoldersTabSVG style={{ width: '19px', height: '14px', marginRight: '7px' }} />,
      component: <Holders pumpAddress={token.pumpAddress}/>,
    },
    {
      label: 'Dev tokens',
      icon: <DevTokensTabSVG style={{ width: '13px', height: '14px', marginRight: '7px' }} />,
      component: <DevTokens />,
    },
  ];

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress
          size={100}
          sx={{
            '& .MuiCircularProgress-svg': {
              height: 'auto',
              width: 'auto',
            },
          }}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 255px)', overflow: 'hidden' }}>
      <TokenInfo token={token} />
      <Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 10px 9px',
          borderBottom: '1px solid #FFFFFF',
        }}>
          {tabs.map(tab => (
            <Typography
              key={tab.label}
              onClick={() => setActiveTab(tab.label)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '12px',
                height: '20px',
                borderRight: tab.label !== 'Dev tokens' ? '1px solid #FFFFFF1A' : 'none',
                flex: 1,
                cursor: 'pointer',
                color: activeTab === tab.label ? '#2163FA' : '#fff',
              }}>
              {tab.icon}
              {tab.label}
            </Typography>
          ))}
        </Box>
        <Box sx={{
          padding: '0 8px',
          height: 'calc(100vh - 433px)',
          overflow: 'auto',
        }}>{tabs.find(tab => tab.label === activeTab)?.component}</Box>
      </Box>
      <TokenNavbar pumpAddress={token.pumpAddress} decimals={token.decimals}/>
    </Box>
  );
};

export default Token;
