import React, { useEffect, useRef, useState } from 'react';
import { CandlestickSeries, ColorType, createChart, IChartApi } from 'lightweight-charts';
import { Typography, Box, CircularProgress } from '@mui/material';
import TrendingHistory from './TrendingHistory';
import { getCandlesticks } from 'pages/DEX-exchange/api/coinGeckoApi';
import { ReactComponent as ResizeSVG } from 'pages/DEX-exchange/svg/resize.svg';
import { ReactComponent as ResizeOutSVG } from 'pages/DEX-exchange/svg/resize-out.svg';

interface TrendingProps {
  address?: string;
  poolId: string;
  pairId: string;
  name: string;
}

function getResolution(interval: string): string {
  if (interval === '1s') {
    return '1S';
  }
  const unit = interval.slice(-1);
  const value = parseInt(interval.slice(0, -1));
  if (isNaN(value)) {
    return interval;
  }
  switch (unit) {
    case 'm':
      return String(value);
    case 'h':
      return String(value * 60);
    case 'd':
      return String(value * 1440);
    default:
      return interval;
  }
}

const Trending: React.FC<TrendingProps> = ({ poolId, pairId, address, name }) => {

  const intervals = ['1m', '15m', '1h', '4h'];
  const [activeInterval, setActiveInterval] = useState(intervals[0]);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Рассчитываем временной диапазон: за последние 7 дней
  const toTimestamp = Math.floor(Date.now() / 1000);
  const fromTimestamp = toTimestamp - 7 * 86400;

  const setChartInterval = (interval: string) => {
    setActiveInterval(interval);
  };

  // Рефы для контейнеров графика
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const modalChartContainerRef = useRef<HTMLDivElement>(null);

  let chart: IChartApi;
  let newSeries: any;

  // Загрузка данных с сервера
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getCandlesticks(
          poolId,
          pairId,
          getResolution(activeInterval),
          String(fromTimestamp),
          String(toTimestamp),
          false,   // for_update
          329,     // count_back
          'usd',
          false    // is_inverted
        );
        if (response && response.data) {
          const bars = response.data.map((item: any) => ({
            time: Math.floor(new Date(item.dt).getTime() / 1000),
            open: item.o,
            high: item.h,
            low: item.l,
            close: item.c,
          }));
          setData(bars);
        } else {
          console.log("Нет данных для отображения");
          setData([]);
        }
      } catch (error) {
        console.error("Ошибка получения данных свечей:", error);
        setData([]);
      } finally {
        setLoading(false);
      }
    }

    // Вызываем запрос сразу при монтировании
    fetchData();

    // Устанавливаем интервал в 60000 мс (1 минута)
    const intervalId = setInterval(fetchData, 60000);

    // Очистка интервала при размонтировании эффекта
    return () => {
      clearInterval(intervalId);
    };
  }, [activeInterval]);


  // Инициализация основного графика
  useEffect(() => {
    if (!data.length) return;
    if (!chartContainerRef.current) return;

    const container = chartContainerRef.current;
    chart = createChart(container, {
      layout: {
        background: {type: ColorType.Solid, color:'black'},
        textColor: '#cbcbcb',
      },
      grid: {
        vertLines: { color: '#171717' },
        horzLines: { color: '#171717' },
      },
      width: container.clientWidth,
      height: 300,
      overlayPriceScales: { mode: 0 },
      rightPriceScale: {
        autoScale: true,
      },
      localization: { priceFormatter: (p: number) => p.toFixed(8) },
    });

    chart.timeScale().applyOptions({ borderColor: '#71649C' });

    // Приводим chart к типу any, чтобы вызвать addCandlestickSeries
    newSeries = chart.addSeries(CandlestickSeries, {
      wickUpColor: '#25a74f',
      upColor: '#25a74f',
      wickDownColor: '#c94064',
      downColor: '#c94064',
      borderVisible: false,
      priceFormat: {
        type: 'price',
        precision: 8,
        minMove: 0.0000001,
      },
    });
    newSeries.setData(data);
    chart.timeScale().applyOptions({
      timeVisible: true,
      secondsVisible: true,
    });

    const handleResize = () => {
      if (chartContainerRef.current) {
        chart.applyOptions({ width: chartContainerRef.current.clientWidth });
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data]);

  // Инициализация модального графика
  useEffect(() => {
    if (!isModalOpen) return;
    if (!modalChartContainerRef.current) return;

    const modalContainer = modalChartContainerRef.current;
    const modalChart = createChart(modalContainer, {
      layout: {
        background: {type: ColorType.Solid, color:'black'},
        textColor: '#cbcbcb',
      },
      grid: {
        vertLines: { color: '#171717' },
        horzLines: { color: '#171717' },
      },
      width: modalContainer.clientWidth,
      height: modalContainer.clientHeight - modalContainer.clientHeight / 10,
    });

    modalChart.timeScale().applyOptions({ borderColor: '#71649C' });

    const modalSeries = modalChart.addSeries(CandlestickSeries, {
      wickUpColor: '#25a74f',
      upColor: '#25a74f',
      wickDownColor: '#c94064',
      downColor: '#c94064',
      borderVisible: false,
    });
    modalSeries.setData(data);

    const handleModalResize = () => {
      if (modalContainer) {
        modalChart.applyOptions({
          width: modalContainer.clientWidth,
          height: modalContainer.clientHeight,
        });
      }
    };

    window.addEventListener('resize', handleModalResize);
    return () => {
      window.removeEventListener('resize', handleModalResize);
      modalChart.remove();
    };
  }, [isModalOpen, data]);


  return (
    <div>
      {loading ?
      <>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress
            size={100}
            sx={{
              '& .MuiCircularProgress-svg': {
                height: 'auto',
                width: 'auto',
              },
            }}
          />
        </Box>
      </>
        :
       <>
         <div style={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
           {intervals.map(interval => (
             <div
               key={interval}
               style={{
                 borderRadius: '4px',
                 fontSize: '14px',
                 color: interval === activeInterval ? '#fff' : '#909090',
                 backgroundColor: interval === activeInterval ? '#242424' : 'transparent',
                 cursor: 'pointer',
                 width: '40px',
                 textAlign: 'center',
                 marginRight: '4px',
               }}
               onClick={() => setChartInterval(interval)}
             >
               {interval}
             </div>
           ))}
           <div style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setIsModalOpen(true)}>
             <ResizeSVG style={{ width: '18px', height: '18px' }} />
           </div>
         </div>

         <div ref={chartContainerRef} />

         {isModalOpen && (
           <div
             style={{
               position: 'fixed',
               top: 0,
               left: 0,
               width: '100vw',
               height: '100vh',
               backgroundColor: 'rgba(0, 0, 0, 0.9)',
               zIndex: 9999,
             }}
           >
             <div style={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
               {intervals.map(interval => (
                 <div
                   key={interval}
                   style={{
                     borderRadius: '4px',
                     fontSize: '14px',
                     color: interval === activeInterval ? '#fff' : '#909090',
                     backgroundColor: interval === activeInterval ? '#242424' : 'transparent',
                     cursor: 'pointer',
                     width: '40px',
                     textAlign: 'center',
                     marginRight: '4px',
                   }}
                   onClick={() => setChartInterval(interval)}
                 >
                   {interval}
                 </div>
               ))}
               <div style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setIsModalOpen(false)}>
                 <ResizeOutSVG style={{ width: '18px', height: '18px', marginLeft: 'auto', marginRight: '10px' }} onClick={() => setIsModalOpen(false)} />
               </div>
             </div>

             <div ref={modalChartContainerRef} style={{ width: '100%', height: '100%' }} />
           </div>
         )}
       </>
      }

      <Typography sx={{ marginTop: '30px', fontSize: '16px', color:'#fff' }}>History</Typography>
      <TrendingHistory address={address!} name={name} />
    </div>
  );
};

export default Trending;
