import { Connection, Keypair, PublicKey, SystemProgram, Transaction, VersionedTransaction } from '@solana/web3.js';
import { getMint } from '@solana/spl-token';
import { decryptPrivateKey } from 'pages/DEX-exchange/helpers/encryption';

const connection = new Connection(process.env.REACT_APP_HELIUS_RPC_URL!);

export async function buyOrSellCoin(mintAddressByCoin: string, amount: number, setLoadingBuy: any, type: string, order: any) {
  const key = localStorage.getItem('solana_wallet');
  const decrypted = decryptPrivateKey(key!);
  const secretKeyArray = JSON.parse(decrypted);
  const keypair = Keypair.fromSecretKey(new Uint8Array(secretKeyArray));

  // Создаём объект кошелька
  const wallet = {
    payer: keypair,
    publicKey: keypair.publicKey,
    signTransaction: async (transaction: Transaction) => {
      transaction.partialSign(keypair);
      return transaction;
    },
    signAllTransactions: async (transactions: Transaction[]) => {
      transactions.forEach(tx => tx.partialSign(keypair));
      return transactions;
    },
  };

  let decimals: number | null = null;
  if (type === 'sell') {
    try {
      const solConnection = new Connection(process.env.REACT_APP_HELIUS_RPC_URL!, 'confirmed');
      if (mintAddressByCoin) {
        const mintAccount = await getMint(solConnection, new PublicKey(mintAddressByCoin));
        decimals = mintAccount.decimals;
      }
      // Получаем баланс токенов на кошельке пользователя для данного mint
      const parsedResponse = await connection.getParsedTokenAccountsByOwner(wallet.publicKey, {
        mint: new PublicKey(mintAddressByCoin),
      });
      if (parsedResponse.value.length === 0) {
        setLoadingBuy(false);
        order('Token account not found', 'error')
        return;
      }
      const tokenAccountInfo = parsedResponse.value[0].account.data.parsed.info;
      const walletTokenBalance = Number(tokenAccountInfo.tokenAmount.uiAmount);

      // Если пользователь ввёл процент, вычисляем фактическое количество токенов для продажи
      // Например, если amount = 50, то продадим 50% от walletTokenBalance
      const tokensToSell = walletTokenBalance * (amount / 100);
      // Переопределяем amount для дальнейшего расчёта:
      amount = tokensToSell;
    } catch (err) {
      setLoadingBuy(false);
      order('Error retrieving token or balance information:', 'error')
      return;
      // console.error('Ошибка получения информации о токене или балансе:', err);
    }
  }

  const amountInUnits = type === 'sell'
    ? Math.floor(amount * Math.pow(10, decimals!)) // при продаже: количество токенов * 10^decimals
    : Math.floor(amount * 1_000_000_000);                // при покупке: SOL в lamports

  // Формируем URL для запроса котировки в зависимости от типа операции:
  const quoteUrl = type === 'sell'
    ? `https://quote-api.jup.ag/v6/quote?inputMint=${mintAddressByCoin}&outputMint=So11111111111111111111111111111111111111112&amount=${amountInUnits}&slippageBps=500`
    : `https://quote-api.jup.ag/v6/quote?inputMint=So11111111111111111111111111111111111111112&outputMint=${mintAddressByCoin}&amount=${amountInUnits}&slippageBps=500`;

  // Запрашиваем котировку для свапа у Jupiter
  const quoteResponse = await (await fetch(quoteUrl)).json();

  // Рассчитываем комиссию:
  // Для покупки комиссия = 0.5% от суммы, т.е. от amountInUnits (SOL в лампортах)
  // Для продажи комиссия = 0.5% от оценочного выходного количества SOL (outAmount из котировки)
  let commissionLamports = 0;
  if (type === 'buy') {
    commissionLamports = Math.floor(amountInUnits * 0.001);
  } else {
    // Предполагаем, что quoteResponse.data[0].outAmount содержит оценочное количество SOL в лампортах
    const estimatedOutputLamports = Number(quoteResponse.outAmount);
    commissionLamports = Math.floor(estimatedOutputLamports * 0.001);
  }

  // Запрос к Jupiter Swap API для получения сериализованной транзакции
  const swapApiResponse = await fetch('https://quote-api.jup.ag/v6/swap', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      quoteResponse,
      userPublicKey: wallet.publicKey.toString(),
      wrapAndUnwrapSol: true,
      dynamicComputeUnitLimit: true,
      dynamicSlippage: true,
      prioritizationFeeLamports: {
        priorityLevelWithMaxLamports: {
          maxLamports: Math.floor(amountInUnits / 100),
          priorityLevel: 'veryHigh',
        },
      },
    }),
  });

  if(!swapApiResponse.ok){
    setLoadingBuy(false);
    order('Transaction error', 'error')
    return;
  }

  const { swapTransaction } = await swapApiResponse.json();

  // swapTransaction – это base64-строка, декодируем её в буфер
  const swapTransactionBase64 = swapTransaction;
  const swapTransactionBuf = Buffer.from(swapTransactionBase64, 'base64');
  let transaction = VersionedTransaction.deserialize(swapTransactionBuf);

  // Получаем новый блокхэш
  let latestBlockHash = await connection.getLatestBlockhash('confirmed');

  // Обновляем recentBlockhash и lastValidBlockHeight в транзакции
  let txnAny = transaction as any;
  txnAny.message.recentBlockhash = latestBlockHash.blockhash;
  txnAny.lastValidBlockHeight = latestBlockHash.lastValidBlockHeight;

  // Подписываем транзакцию
  transaction.sign([wallet.payer]);

  // Сериализуем транзакцию и отправляем её
  const rawTransaction = transaction.serialize();
  const txid = await connection.sendRawTransaction(rawTransaction, {
    skipPreflight: true,
    maxRetries: 2,
  });
  order('The order has been created');

  // Получаем новый блокхэш
  latestBlockHash = await connection.getLatestBlockhash('confirmed');

  // Обновляем recentBlockhash и lastValidBlockHeight в транзакции
  txnAny = transaction as any;
  txnAny.message.recentBlockhash = latestBlockHash.blockhash;
  txnAny.lastValidBlockHeight = latestBlockHash.lastValidBlockHeight;

  // Ожидаем подтверждения транзакции
  const confirmation = await connection.confirmTransaction({
    blockhash: latestBlockHash.blockhash,
    lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
    signature: txid,
  }, 'confirmed').catch(err=>{
    console.log('error',err);
    setLoadingBuy(false);
    order('Transaction error', 'error');
    return;
  });

  if (confirmation?.value?.err) {
    order(`Transaction failed: ${JSON.stringify(confirmation.value.err)}\nhttps://solscan.io/tx/${txid}/`, 'error');
  } else {
    // order('The order has been confirmed');
    console.log(`Transaction successful: https://solscan.io/tx/${txid}/`);
    try {
      const commissionWallet = new PublicKey("6LuH9f6nNKGHNjGJs3hh7bhjcgjU2iq4L3S2XZ3gmiJA");
      const commissionTx = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: wallet.publicKey,
          toPubkey: commissionWallet,
          lamports: commissionLamports,
        })
      );
      // Устанавливаем recentBlockhash и feePayer для транзакции комиссии
      commissionTx.recentBlockhash = latestBlockHash?.blockhash;
      commissionTx.feePayer = wallet?.publicKey;
      commissionTx?.sign(wallet.payer);
      const commissionTxid = await connection?.sendTransaction(commissionTx, [wallet.payer]);
      console.log(`Commission transfer transaction: https://solscan.io/tx/${commissionTxid}/`);
    } catch (err: any) {
      setLoadingBuy(false);
      // order(`The order has been completed`, 'success');
      return;
    }
  }
  setLoadingBuy(false);
  // order(`The order has been completed`, 'success');
  return;
}

