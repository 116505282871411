import { BlockchainService, Transaction as Tx } from './BlockchainService';
import { formatEther, InfuraProvider, parseEther, Provider, Wallet } from 'ethers';

export class EthereumService implements BlockchainService {
  private static instance: EthereumService;
  connection: Provider;

  static getInstance() {
    if (!EthereumService.instance) {
      EthereumService.instance = new EthereumService();
    }
    return EthereumService.instance;
  }

  private constructor() {
    const apiKey = process.env.REACT_APP_INFURA_API_KEY;
    if (!apiKey) {
      throw new Error(
        'Необходимо указать Infura API ключ в переменной окружения REACT_APP_INFURA_API_KEY',
      );
    }
    this.connection = new InfuraProvider('goerli', apiKey);
  }

  async getBalance(address: string): Promise<number> {
    try {
      const balanceBN = await this.connection.getBalance(address);
      return parseFloat(formatEther(balanceBN));
    } catch (error) {
      console.error('Ошибка получения баланса (Ethereum):', error);
      throw error;
    }
  }

  async sendTransaction(
    from: string,
    to: string,
    amount: number,
  ): Promise<string> {
    try {
      const wallet = new Wallet(from, this.connection);
      const tx = await wallet.sendTransaction({
        to: to,
        value: parseEther(amount.toString()),
      });
      await tx.wait();
      return tx.hash;
    } catch (error) {
      console.error('Ошибка отправки транзакции (Ethereum):', error);
      throw error;
    }
  }

  subscribeToBalanceChange(
    address: string,
    callback: (balance: number) => void,
  ): number {
    const intervalId = window.setInterval(async () => {
      try {
        const balance = await this.getBalance(address);
        callback(balance);
      } catch (error) {
        console.error('Ошибка опроса баланса (Ethereum):', error);
      }
    }, 10000);
    return intervalId;
  }

  unsubscribeFromBalanceChange(subscriptionId: number): void {
    clearInterval(subscriptionId);
  }

  async getTransactionHistory(address: string): Promise<Tx[]> {
    return Promise.resolve([
      {
        action: 'Add funds',
        date: '10.01.25',
        price: '+0.5000 ETH',
        amountSol: 100,
        recipient: 'Recipient3',
        icon: 'add',
      },
      {
        action: 'Withdraw',
        date: '09.01.25',
        price: '-0.2000 ETH',
        amountSol: 100,
        recipient: 'Recipient4',
        icon: 'withdraw',
      },
    ]);
  }

  generatePaymentLink(
    address: string,
    amount: number = 1,
    label: string = 'Add Funds',
    message: string = 'Please pay to add funds',
  ): string {
    return '';
  }
}
