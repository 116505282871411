import React from 'react';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import { ReactComponent as TradingSVG } from 'pages/DEX-exchange/svg/trading.svg';
import { ReactComponent as WalletSVG } from 'pages/DEX-exchange/svg/wallet.svg';
import { ReactComponent as PositionsSVG } from 'pages/DEX-exchange/svg/positions.svg';
import { ReactComponent as MenuSVG } from 'pages/DEX-exchange/svg/menu.svg';

interface BottomNavBarProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const BottomNavBar: React.FC<BottomNavBarProps> = ({ activeTab, setActiveTab }) => {
  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        height: '96px',
        bottom: 0,
        backgroundColor: '#000',
        borderTop: '1px solid #373739',
        paddingBottom: '37px',
      }}>
      <BottomNavigation
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        showLabels
        sx={{
          backgroundColor: '#000',
          color: '#FFFFFF66',
          '& .Mui-selected': { color: '#2163FA' },
        }}>
        <BottomNavigationAction
          label="Trading"
          icon={<TradingSVG />}
          value="trading"
          sx={{
            color: '#FFFFFF66',
            '&.Mui-selected': { color: '#2163FA' },
            '& .MuiBottomNavigationAction-label': {
              fontSize: '10px !important',
              lineHeight: '10px',
              marginTop: '6px',
            },
          }}
        />
        <BottomNavigationAction
          label="Wallet"
          icon={<WalletSVG />}
          value="wallet"
          sx={{
            color: '#FFFFFF66',
            '&.Mui-selected': { color: '#2163FA' },
            '& .MuiBottomNavigationAction-label': {
              fontSize: '10px !important',
              lineHeight: '10px',
              marginTop: '6px',
            },
          }}
        />
        <BottomNavigationAction
          label="Positions"
          icon={<PositionsSVG />}
          value="positions"
          sx={{
            color: '#FFFFFF66',
            '&.Mui-selected': { color: '#2163FA' },
            '& .MuiBottomNavigationAction-label': {
              fontSize: '10px !important',
              lineHeight: '10px',
              marginTop: '6px',
            },
          }}
        />
        <BottomNavigationAction
          label="Menu"
          icon={<MenuSVG />}
          value="menu"
          sx={{
            color: '#FFFFFF66',
            '&.Mui-selected': { color: '#2163FA' },
            '& .MuiBottomNavigationAction-label': {
              fontSize: '10px !important',
              lineHeight: '10px',
              marginTop: '6px',
            },
          }}
        />
      </BottomNavigation>
    </Box>
  );
};

export default BottomNavBar;
