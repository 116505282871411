import React from 'react';
import QRCode from 'qrcode-generator';
import { Box } from '@mui/material';

type CustomQRCodeProps = {
  value: string; // Значение для QR-кода
  size?: number; // Размер QR-кода (по умолчанию 200px)
  logoSrc?: string; // Путь к логотипу
  cellColor?: string; // Цвет активных ячеек
  backgroundColor?: string; // Фон QR-кода
};

const CustomQRCode: React.FC<CustomQRCodeProps> = ({
                                                     value,
                                                     size = 200,
                                                     logoSrc = '/img/solana-logo.png',
                                                     cellColor = '#FFFFFF',
                                                     backgroundColor = '#000000',
                                                   }) => {
  // Генерация QR-кода
  const qr = QRCode(0, 'H');
  qr.addData(value);
  qr.make();

  // Получаем матрицу QR-кода
  const moduleCount = qr.getModuleCount();
  const cellSize = size / moduleCount;

  return (
    <Box
      sx={{
        position: 'relative',
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor,
        display: 'grid',
        gridTemplateColumns: `repeat(${moduleCount}, ${cellSize}px)`,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* Рендер ячеек QR-кода */}
      {Array.from({ length: moduleCount }, (_, rowIndex) =>
        Array.from({ length: moduleCount }, (_, colIndex) => {
          // Убираем бордеры для угловых областей 7x7
          const isTopLeftCorner = rowIndex < 7 && colIndex < 7; // Верхний левый угол
          const isTopRightCorner = rowIndex < 7 && colIndex >= moduleCount - 7; // Верхний правый угол
          const isBottomLeftCorner =
            rowIndex >= moduleCount - 7 && colIndex < 7; // Нижний левый угол

          return (
            <Box
              key={`${rowIndex}-${colIndex}`}
              sx={{
                width: `${cellSize}px`,
                height: `${cellSize}px`,
                backgroundColor: qr.isDark(rowIndex, colIndex)
                  ? cellColor
                  : 'transparent',
                // Применяем бордер только для ячеек вне углов
                border:
                  isTopLeftCorner || isTopRightCorner || isBottomLeftCorner
                    ? 'none'
                    : '1px solid black',
                borderRadius:
                  isTopLeftCorner || isTopRightCorner || isBottomLeftCorner
                    ? '0'
                    : '2px',
              }}
            />
          );
        }),
      )}

      {/* Логотип в центре */}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#000',
          borderRadius: '50%',
          width: `${size * 0.2}px`,
          height: `${size * 0.2}px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={logoSrc}
          alt="Logo"
          style={{
            width: '70%',
            height: '70%',
            borderRadius: '50%',
          }}
        />
      </Box>
    </Box>
  );
};

export default CustomQRCode;
