import React from 'react';
import { Box, Divider, Fade, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { closeModal, resetModal } from 'store/slice/modalSlice';

const StandartModal: React.FC = () => {
  const dispatch = useDispatch();
  const { isOpen, title, component } = useSelector((state: RootState) => state.modal);

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      BackdropProps={{
        sx: { backgroundColor: '#15161BCC' },
      }}>
      <Fade
        in={isOpen}
        timeout={200}
        onExited={() => {
          dispatch(resetModal());
        }}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: '#000',
            color: '#fff',
            borderRadius: '12px',
            width: '90%',
            maxHeight: '90vh',
            maxWidth: '400px',
            boxShadow: 24,
            padding: '16px 8px',
            overflowY: 'auto',
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '16px',
              mx: '8px',
            }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 590, lineHeight: '16px' }}>{title}</Typography>
            <IconButton onClick={handleClose} sx={{ color: '#FFFFFF99', padding: '0' }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider sx={{ backgroundColor: '#FFFFFF' }} />
          {component}
        </Box>
      </Fade>
    </Modal>
  );
};

export default StandartModal;
