import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

interface ActiveAddressState {
  address: string;
  marketAddress: string;
}

const initialState: ActiveAddressState = { address: '', marketAddress: '' };

const activeAddressSlice = createSlice({
  name: 'activeAddress',
  initialState,
  reducers: {
    setActiveAddress(state, action: PayloadAction<string>) {
      console.log('setActiveAddress', action.payload);
      state.address = action.payload;
    },
    setActiveMarketAddress(state, action: PayloadAction<string>) {
      console.log('setActiveMarketAddress', action.payload);
      state.marketAddress = action.payload;
    },
  },
});

export const { setActiveAddress, setActiveMarketAddress } = activeAddressSlice.actions;
export default activeAddressSlice.reducer;

export const selectActiveAddress = (state: RootState) => state.activeAddress.address;
export const selectActiveMarketAddress = (state: RootState) => state.activeAddress.marketAddress;
