import React from 'react';
import { Box, Input, Typography } from '@mui/material';

type CustomInputProps = {
  value: string;
  label: string;
  className?: string;
  endAdornment?: React.ReactNode;
  onChange: (value: string) => void;
};

const CustomInput: React.FC<CustomInputProps> = ({ label, value, className, endAdornment, onChange }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <Box
      className={className}
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #FFFFFF33',
        borderRadius: '8px',
        backgroundColor: '#000',
        color: '#fff',
      }}>
      {/* Текст слева */}
      <Typography
        variant="body1"
        sx={{
          marginRight: '8px',
          flexShrink: 0,
          fontSize: '12px',
          fontWeight: 510,
          lineHeight: '12px',
          padding: '11px 8px',
          textAlign: 'left',
          textUnderlinePosition: 'from-font',
          textDecorationSkipInk: 'none',
          color: '#FFFFFF99',
          borderRight: '1px solid #FFFFFF33',
        }}>
        {label}
      </Typography>

      <Input
        value={value}
        onChange={handleChange}
        sx={{
          width: '100%',
          color: '#fff',
          fontSize: '12px',
        }}
        disableUnderline
      />
      {endAdornment}
    </Box>
  );
};

export default CustomInput;
