import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as SearchSVG } from 'pages/DEX-exchange/svg/search.svg';
import { ReactComponent as SearchCrossSVG } from 'pages/DEX-exchange/svg/search-cross.svg';
import { ReactComponent as ResetSVG } from 'pages/DEX-exchange/svg/reset.svg';
import StandartInput from 'pages/DEX-exchange/common/StandartInput/StandartInput';
import { FilterData, RangeKey } from '../trading.interfaces';
import StandartCheckbox from 'pages/DEX-exchange/common/StandartCheckbox/StandartCheckbox';

const filterConfig = [
  { label: 'Liquidity', key: 'liquidity', symbol: '$' },
  { label: 'FDV', key: 'fdv_in_usd', symbol: '$' },
  { label: 'Volume 24H', key: 'volume_24h', symbol: '$' },
  { label: 'Pool age (hours)', key: 'pool_creation_hours_ago', symbol: 'h' },
  { label: 'Buy tax', key: 'buy_tax', symbol: '%' },
  { label: 'Sell tax', key: 'sell_tax', symbol: '%' },
];

const checkboxOptions = [
  { label: 'Socials', key: 'has_social' },
];

const initialRanges = filterConfig.reduce((acc, cur) => {
  acc[cur.key as RangeKey] = { min: '', max: '' };
  return acc;
}, {} as Record<RangeKey, { min: string; max: string }>);

interface OrderFilterProps {
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
  onApply: (filters: FilterData) => void;
  searchQuery: string;
  appliedFilters: FilterData | null;
}


const Filter: React.FC<OrderFilterProps> = ({ setShowFilter, onApply, searchQuery, appliedFilters }) => {
  const [searchKeyword, setSearchKeyword] = useState(appliedFilters?.searchKeyword || searchQuery);
  const [ranges, setRanges] = useState<Record<RangeKey, { min: string; max: string }>>(
    appliedFilters?.ranges || initialRanges,
  );
  const [selectedFilters, setSelectedFilters] = useState<string[]>(appliedFilters?.selectedFilters || []);
  const handleReset = () => {
    setSearchKeyword('');
    setRanges(initialRanges);
  };

  const handleCheckboxToggle = (key: string) => {
    setSelectedFilters(prev =>
      prev.includes(key) ? prev.filter(k => k !== key) : [...prev, key],
    );
  };

  const handleApply = () => {
    const filterData: FilterData = {
      searchKeyword,
      ranges,
      selectedFilters,
    };

    onApply(filterData);
    setShowFilter(false);
  };

  const handleRangeChange = (key: keyof typeof ranges, field: 'min' | 'max', value: string) => {
    setRanges(prev => ({
      ...prev,
      [key]: { ...prev[key], [field]: value },
    }));
  };

  return (
    <Box
      sx={{
        width: 'calc(100% + 16px)',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 -8px',
      }}>
      <Typography
        variant="h6"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: '#fff',
          marginBottom: '18px',
          padding: '0 16px 17px',
          fontSize: '16px',
          borderBottom: '1px solid #ffffff',
        }}>
        Order filter
        <Button onClick={() => setShowFilter(false)} sx={{ minWidth: 'auto', padding: '0 5px', color: '#fff' }}>
          <SearchCrossSVG style={{ color: '#FFFFFF99', width: '14px' }} />
        </Button>
      </Typography>
      <Box
        sx={{
          margin: '0 16px',
          borderBottom: '1px solid #FFFFFF1A',
        }}>
        {checkboxOptions.map(({ label, key }) => (
          <StandartCheckbox
            key={key}
            label={label}
            value={selectedFilters.includes(key)}
            onChange={() => handleCheckboxToggle(key)}
            sx={{ marginBottom: '12px' }}
          />
        ))}
      </Box>
      <div className="tw-flex tw-items-center tw-justify-between tw-mx-2 tw-mt-4 tw-mb-5 tw-px-2 tw-pb-3.5"
           style={{ borderBottom: '1px solid #FFFFFF1A' }}>
        <Typography sx={{ fontSize: '14px' }}>Search</Typography>
        <StandartInput className={'tw-w-[231px]'} placeholder="Keyword" value={searchKeyword}
                       onChange={e => setSearchKeyword(e.target.value)}
                       endAdornment={<SearchSVG style={{ width: '14px', color: '#FFFFFF66' }}
                                                onClick={() => setSearchKeyword('')} />} />
      </div>

      {filterConfig.map(({ label, key, symbol }) => {
        const typedKey = key as RangeKey; // 👈 Явно говорим TypeScript'у

        return (
          <Box
            key={key}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              marginBottom: '12px',
              padding: '0 16px',
              color: '#fff',
            }}>
            <Typography sx={{ fontSize: '14px', marginRight: 'auto' }}>{label}</Typography>
            <StandartInput
              className={'tw-w-[113px]'}
              placeholder="Min"
              value={ranges[typedKey].min}
              onChange={e => handleRangeChange(typedKey, 'min', e.target.value)}
              endAdornment={<Box sx={{ color: '#FFFFFF66' }}>{symbol}</Box>}
            />
            <StandartInput
              className={'tw-w-[113px]'}
              placeholder="Max"
              value={ranges[typedKey].max}
              onChange={e => handleRangeChange(typedKey, 'max', e.target.value)}
              endAdornment={<Box sx={{ color: '#FFFFFF66' }}>{symbol}</Box>}
            />
          </Box>
        );
      })}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '80px 8px 20px',
          gap: '8px',
        }}>
        <Button
          variant="contained"
          sx={{
            background: '#444',
            color: '#fff',
            borderRadius: '8px',
            height: '40px',
          }}
          onClick={handleReset}>
          <ResetSVG style={{ width: '19px', height: '16px' }} />
        </Button>
        <Button
          variant="contained"
          onClick={handleApply}
          sx={{
            width: '100%',
            background: '#2163FA',
            color: '#fff',
            borderRadius: '8px',
            height: '40px',
            textTransform: 'none',
          }}>
          Apply filters
        </Button>
      </Box>
    </Box>
  );
};

export default Filter;
