import { Dispatch } from "redux";

export const SET_EXCHANGE_RATE = "SET_EXCHANGE_RATE";

export interface SetExchangeRateAction {
  type: typeof SET_EXCHANGE_RATE;
  payload: number;
}

export type ExchangeRateAction = SetExchangeRateAction;

export const setExchangeRate = (rate: number): SetExchangeRateAction => ({
  type: SET_EXCHANGE_RATE,
  payload: rate,
});

export const startExchangeRatePolling = (intervalMs: number = 15000) => {
  return (dispatch: Dispatch<ExchangeRateAction>): (() => void) => {
    const fetchRate = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd"
        );
        const data = await response.json();
        if (data && data.solana && data.solana.usd) {
          dispatch(setExchangeRate(data.solana.usd));
        }
      } catch (error) {
        console.error("Ошибка при получении курса:", error);
      }
    };

    fetchRate();

    const intervalId = setInterval(fetchRate, intervalMs);

    return () => clearInterval(intervalId);
  };
};
