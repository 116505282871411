import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Tab } from '../common/Accordion/Accordion';
import { ReactComponent as RuSVG } from 'pages/DEX-exchange/svg/flags/ru.svg';
import { ReactComponent as USASVG } from 'pages/DEX-exchange/svg/flags/usa.svg';
import { ReactComponent as SearchCrossSVG } from 'pages/DEX-exchange/svg/search-cross.svg';
import StandartInput from 'pages/DEX-exchange/common/StandartInput/StandartInput';
import { ReactComponent as SolanaSVG } from 'pages/DEX-exchange/svg/solana-grey.svg';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../store/slice/modalSlice';

const Settings: React.FC = () => {
  // Дефолтные значения для пресетов
  const defaultBuyPreset = { activeTab: Tab.SOL, inputs: ["0.01", "0.02", "0.5", "1"] };
  const defaultSellPreset = { activeTab: Tab.SOL, inputs: ["25", "50", "75", "100"] };
  const dispatch = useDispatch();
  const [checkbox, setCheckbox] = useState(false);
  const [language, setLanguage] = useState('en');
  const [accordionData, setAccordionData] = useState({
    buyPreset: defaultBuyPreset,
    sellPreset: defaultSellPreset,
    multiWallet: { activeTab: Tab.SOL, inputs: Array(8).fill('3') },
  });

  // При монтировании загружаем настройки из localStorage
  useEffect(() => {
    const savedSettings = localStorage.getItem('settings');
    if (savedSettings) {
      try {
        const parsed = JSON.parse(savedSettings);
        setAccordionData({
          buyPreset: parsed.buyPreset || defaultBuyPreset,
          sellPreset: parsed.sellPreset || defaultSellPreset,
          multiWallet: parsed.multiWallet || { activeTab: Tab.SOL, inputs: Array(8).fill('3') },
        });
        setLanguage(parsed.language || 'en');
        setCheckbox(parsed.showSecurityAudit || false);
      } catch (e) {
        console.error('Error parsing settings from localStorage', e);
      }
    }
  }, []);

  // Функция сохранения настроек в localStorage
  const handleSave = () => {
    const payload = {
      showSecurityAudit: checkbox,
      language,
      buyPreset: accordionData.buyPreset,
      sellPreset: accordionData.sellPreset,
      multiWallet: accordionData.multiWallet,
    };
    localStorage.setItem('settings', JSON.stringify(payload));
    dispatch(closeModal());
  };

  // Функция для обновления конкретного пресета
  const updatePresetInput = (
    presetKey: 'buyPreset' | 'sellPreset',
    index: number,
    value: string
  ) => {
    setAccordionData(prev => {
      const updatedInputs = [...prev[presetKey].inputs];
      updatedInputs[index] = value;
      return {
        ...prev,
        [presetKey]: { ...prev[presetKey], inputs: updatedInputs },
      };
    });
  };

  // Функция сброса пресета на дефолтные значения
  const resetPreset = (presetKey: 'buyPreset' | 'sellPreset') => {
    setAccordionData(prev => ({
      ...prev,
      [presetKey]: presetKey === 'buyPreset' ? defaultBuyPreset : defaultSellPreset,
    }));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#000',
        borderRadius: '12px',
        width: '100%',
        maxWidth: '420px',
        padding: '24px 16px 40px',
        color: '#fff',
      }}>
      {/* Buy preset */}
      <Box sx={{ paddingBottom: '24px', marginBottom: '30px', borderBottom: "1px solid #FFFFFF1A" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="14px">
          <Typography sx={{ fontSize: "12px" }}>Buy preset buttons</Typography>
          <Button
            onClick={() => resetPreset('buyPreset')}
            sx={{
              borderRadius: '6px',
              border: "1px solid #FFFFFF1A",
              maxWidth: '47px',
              minWidth: "47px",
              minHeight: "24px",
              padding: "0px 16px",
            }}>
            <SearchCrossSVG style={{ color: '#FFFFFF99', width: '16px', height: '16px' }} />
          </Button>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
          {accordionData.buyPreset.inputs.map((value, idx) => (
            <StandartInput
              key={`buy-${idx}`}
              value={value}
              onChange={(e) => updatePresetInput('buyPreset', idx, e.target.value)}
              className="tw-h-[28px] tw-w-[100%] tw-order-1 tw-px-2 tw-py-1.5"
              inputStyle={{ color: '#FFFFFF99', fontSize: '12px', height: '28px', "& input": { padding: '0px' } }}
              endAdornment={<SolanaSVG style={{ width: '11px', height: '10px' }} />}
            />
          ))}
        </Box>
      </Box>

      {/* Sell preset */}
      <Box sx={{ paddingBottom: '24px', marginBottom: '24px', borderBottom: "1px solid #FFFFFF1A" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="14px">
          <Typography sx={{ fontSize: "12px" }}>Sell preset buttons</Typography>
          <Button
            onClick={() => resetPreset('sellPreset')}
            sx={{
              borderRadius: '6px',
              border: "1px solid #FFFFFF1A",
              maxWidth: '47px',
              minWidth: "47px",
              minHeight: "24px",
              padding: "0px 16px",
            }}>
            <SearchCrossSVG style={{ color: '#FFFFFF99', width: '16px', height: '16px' }} />
          </Button>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
          {accordionData.sellPreset.inputs.map((value, idx) => (
            <StandartInput
              key={`sell-${idx}`}
              value={value}
              onChange={(e) => updatePresetInput('sellPreset', idx, e.target.value)}
              className="tw-h-[28px] tw-w-[100%] tw-order-1 tw-px-2 tw-py-1.5"
              inputStyle={{ color: '#FFFFFF99', fontSize: '12px', height: '28px', "& input": { padding: '0px' } }}
              endAdornment={<Typography sx={{ color: '#FFFFFF99', fontSize: '12px' }}>%</Typography>}
            />
          ))}
        </Box>
      </Box>

      {/* Language selection */}
      <Typography sx={{ marginTop: '0', marginBottom: '8px', fontSize: '12px', color: '#FFFFFF66' }}>
        Language
      </Typography>
      <Box sx={{ display: 'flex', gap: '9px', marginBottom: '24px' }}>
        <Button
          onClick={() => setLanguage('en')}
          sx={{
            flex: 1,
            padding: '14px',
            borderRadius: '8px',
            textTransform: 'none',
            fontSize: '12px',
            fontWeight: '590',
            lineHeight: '12px',
            color: language === 'en' ? '#fff' : '#FFFFFF99',
            backgroundColor: '#1E1F21',
            border: language === 'en' ? '1px solid #2163FA' : '1px solid #FFFFFF0D',
          }}>
          <USASVG style={{ width: '14px', height: '10px', marginRight: '9px' }} />
          English USA
        </Button>
        <Button
          disabled={true}
          onClick={() => setLanguage('ru')}
          sx={{
            flex: 1,
            padding: '14px',
            borderRadius: '8px',
            textTransform: 'none',
            fontSize: '12px',
            lineHeight: '12px',
            color: language === 'en' ? '#FFFFFF99' : '#fff',
            backgroundColor: '#1E1F21',
            border: language === 'en' ? '1px solid #FFFFFF0D' : '1px solid #2163FA',
            "&:disabled": {
              color:'rgba(133,133,133,0.6)'
            }
          }}>
          <RuSVG style={{ width: '14px', height: '10px', marginRight: '9px' }} />
          Ru (SOON)
        </Button>
      </Box>

      <Button
        variant="contained"
        fullWidth
        sx={{
          backgroundColor: '#2163FA',
          textTransform: 'none',
          fontSize: '14px',
          borderRadius: '8px',
          '&:hover': { backgroundColor: '#1A4ED8' },
        }}
        onClick={handleSave}>
        Save Settings
      </Button>
    </Box>
  );
};

export default Settings;
