import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { ReactComponent as CopySVG } from 'pages/DEX-exchange/svg/copy.svg';
import CustomQRCode from '../common/QR/CustomQRCode';
import { useDispatch } from 'react-redux';
import { showSnackbar } from 'store/slice/snackbarSlice';

interface AddFoundsProps {
  walletAddress: string;
  payLink: string;
}

const AddFounds: React.FC<AddFoundsProps> = ({ walletAddress, payLink }) => {
  const dispatch = useDispatch();

  const handleCopy = () => {
    navigator.clipboard.writeText(walletAddress).then(
      () => dispatch(showSnackbar({ message: 'Wallet address copied to clipboard!' })),
      () => dispatch(showSnackbar({ message: 'Failed to copy wallet address.', status: 'error' })),
    );
  };

  const handleShare = async () => {
    const shareData = {
      title: 'Пополните кошелек',
      text: 'Оплатите перевод для пополнения вашего кошелька',
      url: payLink,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => {
        })
        .catch(() => {
          const url = encodeURIComponent(payLink);
          const text = encodeURIComponent('My wallet in PROTON');
          const shareUrl = `https://t.me/share/url?url=${payLink}&text=${text}`;
          window.open(shareUrl, '_blank');
        });
    }
  };

  return (
    <>
      <Divider sx={{ backgroundColor: '#FFFFFF' }} />
      <Typography sx={{ fontSize: '16px', lineHeight: '11px' }} textAlign="center" mb={'40px'} mt={'40px'}>
        SOL <span style={{ color: '#FFFFFF66' }}>(Solana)</span>
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: '68px',
        }}>
        <Box
          sx={{
            position: 'relative',
            p: 0,
            backgroundColor: '#fff',
            borderRadius: '8px',
          }}>
          <CustomQRCode value={payLink} size={259} logoSrc="./DEX/images/svg/solana-mock.svg" cellColor="#FFFFFF"
                        backgroundColor="#000000" />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          border: '1px solid #FFFFFF33',
          borderRadius: '8px',
          p: '12px',
          mb: 3,
          textAlign: 'center',
        }}>
        <Typography sx={{ fontSize: '12px', lineHeight: '12px' }} color="#888" mb={'12px'}>
          Your address is SOL
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: '16px',
            lineHeight: '16px',
            wordBreak: 'break-word',
            mb: '24px',
            width: '210px',
            textAlign: 'center',
            textJustify: 'inter-word',
          }}>
          {walletAddress}
        </Typography>
        <Button
          variant="outlined"
          onClick={handleCopy}
          sx={{
            borderColor: '#444',
            color: '#fff',
            textTransform: 'none',
            fontSize: '14px',
            border: '1px solid #FFFFFF0D',
            width: '100%',
            '&:hover': { borderColor: '#fff' },
            backgroundColor: '#1a1a1a',
          }}>
          <CopySVG style={{ width: '14px', marginRight: '7px' }} />
          Copy
        </Button>
      </Box>
      <Button
        variant="contained"
        fullWidth
        onClick={handleShare}
        sx={{
          backgroundColor: '#0049F0',
          textTransform: 'none',
          fontSize: '14px',
          borderRadius: '8px',
          '&:hover': { backgroundColor: '#0030A1' },
        }}>
        To share
      </Button>
    </>
  );
};

export default AddFounds;
