import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as LightningSVG } from 'pages/DEX-exchange/svg/lighting.svg';
import { ReactComponent as ArrowDownSVG } from 'pages/DEX-exchange/svg/sell-settings.svg';
import { ReactComponent as SolanaGraySVG } from 'pages/DEX-exchange/svg/solana-grey.svg';
import CustomInput from '../common/CustomInput/CustomInput';

enum SettingType {
  BUY = 'buy',
  SELL = 'sell',
}

const TradesSettings: React.FC = () => {
  const [activeTab, setActiveTab] = useState<SettingType>(SettingType.BUY);
  const [settings, setSettings] = useState({
    [SettingType.BUY]: {
      priority: '0.01',
      slippageLimit: '30',
      mevProtection: false,
      bribe: '0.01',
    },
    [SettingType.SELL]: {
      priority: '0.01',
      slippageLimit: '30',
      mevProtection: false,
      bribe: '0.01',
    },
  });

  const handleInputChange = (field: string, value: string) => {
    setSettings(prev => ({
      ...prev,
      [activeTab]: {
        ...prev[activeTab],
        [field]: value,
      },
    }));
  };

  const handleToggleMevProtection = () => {
    setSettings(prev => ({
      ...prev,
      [activeTab]: {
        ...prev[activeTab],
        mevProtection: !prev[activeTab].mevProtection,
      },
    }));
  };

  const handleSave = () => {
    console.log('Отправляем настройки на бэкенд:', settings[activeTab]);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#000',
        borderRadius: '12px',
        width: '100%',
        maxWidth: '420px',
        padding: '22px 16px 16px',
        color: '#fff',
      }}>
      {/* Табы */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '20px', gap: '8px' }}>
        <Button
          onClick={() => setActiveTab(SettingType.BUY)}
          sx={{
            width: '100%',
            textTransform: 'none',
            fontSize: '14px',
            color: activeTab === SettingType.BUY ? '#2163FA' : '#2163FA',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            borderRadius: '0',
            borderBottom: activeTab === SettingType.BUY ? '1px solid #2163FA' : '1px solid #FFFFFF33',
          }}>
          <LightningSVG style={{ width: '10px', height: '15px' }} />
          Buy settings
        </Button>
        <Button
          onClick={() => setActiveTab(SettingType.SELL)}
          sx={{
            width: '100%',
            textTransform: 'none',
            fontSize: '14px',
            color: activeTab === SettingType.SELL ? '#C35268' : '#C35268',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            borderRadius: '0',
            borderBottom: activeTab === SettingType.SELL ? '1px solid #C35268' : '1px solid #FFFFFF33',
          }}>
          <ArrowDownSVG style={{ width: '14px', height: '14px' }} />
          Sell settings
        </Button>
      </Box>

      {/* Поля формы */}
      <>
        <Typography
          sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '12px', color: '#888', mb: '8px' }}>
          {activeTab === SettingType.BUY ? 'Buy priority' : 'Sell priority'}{' '}
          <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
            prio{' '}
            <Typography sx={{ color: '#6EE297', fontSize: '12px', marginLeft: '5px' }}>
              +0.01 <SolanaGraySVG style={{ width: '11px', height: '10px', marginLeft: '6px' }} />
            </Typography>
          </Typography>
        </Typography>
        <CustomInput className="tw-mb-6" value={settings[activeTab].priority} label={'PRIO'}
                     onChange={value => handleInputChange('priority', value)} endAdornment={<SolanaGraySVG style={{
          width: '11px',
          height: '10px',
          minWidth: '11px',
          minHeight: '10px',
          marginLeft: '6px',
          marginRight: '15px',
        }} />} />

        <Typography sx={{
          fontSize: '14px',
          color: '#888',
          mb: '8px',
        }}>{activeTab === SettingType.BUY ? 'Buy slippage limit' : 'Sell slippage limit'}</Typography>
        <CustomInput className="tw-mb-6" value={settings[activeTab].slippageLimit} label={'MAX %'}
                     onChange={value => handleInputChange('slippageLimit', value)} />

        <Typography sx={{
          fontSize: '14px',
          color: '#888',
          mb: '8px',
        }}>{activeTab === SettingType.BUY ? 'Buy mev protection' : 'Sell mev protection'}</Typography>
        <Box sx={{ display: 'flex', gap: '8px', mb: '24px' }}>
          <Button
            onClick={handleToggleMevProtection}
            sx={{
              flex: 1,
              padding: '11px 50px',
              borderRadius: '8px',
              textTransform: 'none',
              fontSize: '12px',
              lineHeight: '12px',
              color: settings[activeTab].mevProtection ? '#FFFFFF99' : '#fff',
              backgroundColor: '#1E1F21',
              border: settings[activeTab].mevProtection ? '1px solid #FFFFFF0D' : '1px solid #2163FA',
            }}>
            Disabled
          </Button>
          <Button
            onClick={handleToggleMevProtection}
            sx={{
              flex: 1,
              padding: '11px 50px',
              borderRadius: '8px',
              textTransform: 'none',
              fontSize: '12px',
              lineHeight: '12px',
              color: settings[activeTab].mevProtection ? '#fff' : '#FFFFFF99',
              backgroundColor: '#1E1F21',
              border: settings[activeTab].mevProtection ? '1px solid #2163FA' : '1px solid #FFFFFF0D',
            }}>
            Enabled
          </Button>
        </Box>

        <Typography sx={{
          fontSize: '14px',
          color: '#888',
          mb: '8px',
        }}>{activeTab === SettingType.BUY ? 'Buy bribe' : 'Sell bribe'}</Typography>
        <CustomInput className="tw-mb-6" value={settings[activeTab].bribe} label={'BRIBE'}
                     onChange={value => handleInputChange('bribe', value)} endAdornment={<SolanaGraySVG style={{
          width: '11px',
          height: '10px',
          minWidth: '11px',
          minHeight: '10px',
          marginLeft: '6px',
          marginRight: '15px',
        }} />} />
      </>

      {/* Кнопка сохранения */}
      <Button
        variant="contained"
        fullWidth
        sx={{
          backgroundColor: '#2163FA',
          textTransform: 'none',
          fontSize: '14px',
          borderRadius: '8px',
          '&:hover': { backgroundColor: '#1A4ED8' },
        }}
        onClick={handleSave}>
        Save Settings
      </Button>
    </Box>
  );
};

export default TradesSettings;
