import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface MiniModalState {
  isOpen: boolean;
  status: 'success' | 'error' | 'info' | null;
  title: string;
  description: string;
  buttonText?: string;
  onButtonClick?: () => void;
}

const initialState: MiniModalState = {
  isOpen: false,
  status: null,
  title: '',
  description: '',
  buttonText: 'OK',
  onButtonClick: undefined,
};

const miniModalSlice = createSlice({
  name: 'miniModal',
  initialState,
  reducers: {
    openMiniModal: (
      state,
      action: PayloadAction<{
        status: MiniModalState['status'];
        title: string;
        description: string;
        buttonText?: string;
        onButtonClick?: () => void;
      }>,
    ) => {
      state.isOpen = true;
      state.status = action.payload.status;
      state.title = action.payload.title;
      state.description = action.payload.description;
      state.buttonText = action.payload.buttonText || 'OK';
      state.onButtonClick = action.payload.onButtonClick;
    },
    closeMiniModal: state => {
      state.isOpen = false;
      state.status = null;
      state.title = '';
      state.description = '';
      state.buttonText = 'OK';
      state.onButtonClick = undefined;
    },
  },
});

export const { openMiniModal, closeMiniModal } = miniModalSlice.actions;
export const selectMiniModal = (state: RootState) => state.miniModal;
export default miniModalSlice.reducer;
