import { configureStore } from '@reduxjs/toolkit';
import exchangeRateReducer from './reducers/exchangeRateReducer';
import wallet from './slice/walletSlice';
import snackbar from './slice/snackbarSlice';
import modal from './slice/modalSlice';
import miniModal from './slice/miniModalSlice';
import tokens from './slice/tokensSlice';
import token from './slice/tokenSlice';
import chart from './slice/chartSlice';
import activeAddress from './slice/activeAddressSlice';
import tokenStats from './slice/tokenStatsSlice';

export const store = configureStore({
  reducer: {
    exchangeRate: exchangeRateReducer,
    wallet: wallet,
    snackbar: snackbar,
    modal: modal,
    miniModal: miniModal,
    tokens: tokens,
    token: token,
    chart: chart,
    activeAddress: activeAddress,
    tokenStats: tokenStats,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
