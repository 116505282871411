import React from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
} from '@mui/material';
import { ReactComponent as CopySVG } from 'pages/DEX-exchange/svg/copy.svg';
import { ReactComponent as FundsSVG } from 'pages/DEX-exchange/svg/funds.svg';
import { ReactComponent as WithrawSVG } from 'pages/DEX-exchange/svg/withraw.svg';
import { Transaction } from './services/BlockchainService';
import { ReactComponent as WalletSVG } from 'pages/DEX-exchange/svg/wallet.svg';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from 'store/slice/snackbarSlice';
import { RootState } from '../../../../store/store';

type HistoryTableProps = {
  transactions: Transaction[];
  loading: boolean;
};

const HistoryTable: React.FC<HistoryTableProps> = ({ transactions, loading }) => {
  const dispatch = useDispatch();
  const rate = useSelector((state: RootState) => state.exchangeRate.rate);

  const handleCopy = (str: string) => {
    if (str.trim()) {
      navigator.clipboard.writeText(str).then(
        () => dispatch(showSnackbar({ message: 'Wallet address copied to clipboard!' })),
        () => dispatch(showSnackbar({ message: 'Failed to copy wallet address.', status: 'error' }))
      );
    } else {
      dispatch(showSnackbar({ message: 'No wallet address to copy.', status: 'error' }));
    }
  };

  // Если загрузка, генерируем 20 пустых элементов; иначе – используем транзакции
  const rowsToDisplay = loading ? Array.from({ length: 20 }) : transactions;

  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          color: '#fff',
          marginBottom: '20px',
          fontSize: '16px',
          fontWeight: 510,
          lineHeight: '22px',
          textAlign: 'left',
        }}
      >
        History
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontSize: '10px',
                  color: '#888',
                  borderBottom: '1px solid #333',
                  padding: '0 0 12px',
                  lineHeight: '10px',
                }}
              >
                Action
              </TableCell>
              <TableCell
                sx={{
                  fontSize: '10px',
                  color: '#888',
                  borderBottom: '1px solid #333',
                  padding: '0 0 12px',
                  lineHeight: '10px',
                }}
              >
                Price
              </TableCell>
              <TableCell
                sx={{
                  fontSize: '10px',
                  color: '#888',
                  borderBottom: '1px solid #333',
                  padding: '0 0 12px',
                  lineHeight: '10px',
                }}
              >
                Recipient
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsToDisplay.map((row: any, index: number) => (
              <TableRow
                key={index}
                sx={{
                  borderBottom: '1px solid #333',
                  height: 'auto',
                  '&:last-child': { border: 0 },
                }}
              >
                <TableCell sx={{ color: '#fff', borderBottom: 'none', padding: '12px 0px' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {loading ? (
                      <Skeleton variant="rectangular" width={23} height={23} sx={{ mr: "7px", bgcolor: 'rgba(255,255,255,0.8)' }} />
                    ) : row.icon === 'withdraw' ? (
                      <WithrawSVG style={{ width: '23px', marginRight: '7px' }} />
                    ) : (
                      <FundsSVG style={{ width: '23px', marginRight: '7px' }} />
                    )}
                    <Box>
                      <Typography sx={{ fontSize: '12px' }}>
                        {loading ? (
                          <Skeleton variant="text" width={60} height={18} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                        ) : (
                          row.action
                        )}
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#888', fontSize: '10px' }}>
                        {loading ? (
                          <Skeleton variant="text" width={50} height={14} sx={{ bgcolor: 'rgba(255,255,255,0.8)', mt: 0.5 }} />
                        ) : (
                          row.date
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    color: loading ? undefined : row.price.startsWith('-') ? '#FF5A5A' : '#57F287',
                    borderBottom: 'none',
                    padding: '12px 0px',
                  }}
                >
                  <Typography sx={{ fontSize: '12px' }}>
                    {loading ? (
                      <Skeleton variant="text" width={127} height={18} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                    ) : (
                      row.price
                    )}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#888', fontSize: '10px' }}>
                    {loading ? (
                      <Skeleton variant="text" width={127} height={14} sx={{ bgcolor: 'rgba(255,255,255,0.8)', mt: 0.5 }} />
                    ) : (
                      `~$${(row.amountSol * rate!).toFixed(2)}`
                    )}
                  </Typography>
                </TableCell>
                <TableCell sx={{ color: '#fff', borderBottom: 'none', padding: '12px 0px' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        maxWidth: '100px',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        color: '#FFFFFF99',
                      }}
                    >
                      {loading ? (
                        <Skeleton variant="text" width={55} height={18} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} />
                      ) : (
                        row.recipient.slice(0, 4) +
                        '_' +
                        row.recipient.slice(row.recipient.length - 5, row.recipient.length - 1)
                      )}
                    </Typography>
                    <IconButton
                      onClick={() => handleCopy(row && !loading ? row.recipient : '')}
                      size="small"
                      sx={{ color: '#888', ml: 1, p: 0 }}
                    >
                      <CopySVG style={{ width: '10px', height: '10px' }} />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && !transactions.length && (
        <Typography
          variant="h6"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF99',
            marginBottom: '20px',
            fontSize: '16px',
            lineHeight: '16px',
            textAlign: 'left',
            marginTop: '40px',
          }}
        >
          <WalletSVG style={{ marginBottom: '10px' }} />
          You haven't made a transaction yet
        </Typography>
      )}
    </Box>
  );
};

export default HistoryTable;
