import { Box, SxProps, Theme, Typography } from '@mui/material';

interface CheckboxProps {
  label: string;
  value: boolean;
  sx?: SxProps<Theme>;
  onChange: (value: boolean) => void;
}

const StandartCheckbox: React.FC<CheckboxProps> = ({ label, value, sx, onChange }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0',
        ...sx,
      }}>
      <Typography sx={{ color: '#fff', fontSize: '12px' }}>{label}</Typography>
      <Box
        onClick={() => {
          onChange(!value);
        }}
        sx={{
          width: 24,
          height: 24,
          borderRadius: '6px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          border: value ? '1px solid #2163FA' : '1px solid #FFFFFF33',
          backgroundColor: value ? '#2163FA' : 'transparent',
          transition: 'all 0.2s ease-in-out',
        }}>
        {value && (
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 6L9 17L4 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        )}
      </Box>
    </Box>
  );
};

export default StandartCheckbox;
