import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { ReactComponent as ArrowFilterDownSVG } from 'pages/DEX-exchange/svg/arrow-filter-down.svg';
import { ReactComponent as SearchSVG } from 'pages/DEX-exchange/svg/search.svg';
import { ReactComponent as SettingsSVG } from 'pages/DEX-exchange/svg/settings.svg';
import TradingItem from './Components/TradingItem';
import { ReactComponent as SearchCrossSVG } from 'pages/DEX-exchange/svg/search-cross.svg';
import { ReactComponent as SortSVG } from 'pages/DEX-exchange/svg/sort.svg';
import { ReactComponent as SortUpSVG } from 'pages/DEX-exchange/svg/sort-up.svg';
import { ReactComponent as SortDownSVG } from 'pages/DEX-exchange/svg/sort-down.svg';
import Filter from './Components/Filter';
import { FilterData } from './trading.interfaces';
import { PeriodEnum, SortOrder, TrendingEnum } from './ts/TradingEnums';
import { getTrends } from 'pages/DEX-exchange/api/coinGeckoApi';
import { formatTimeDifference } from 'pages/DEX-exchange/helpers/formatTimeDifference';
import { calculatePercentChange, generateChartData } from 'pages/DEX-exchange/helpers/parseChartData';
import { formatMarketCap } from 'pages/DEX-exchange/helpers/formatToThausandOrMillions';
import useDebounce from 'pages/DEX-exchange/customHooks/useDebounce';
import { formatSmallPrice } from 'pages/DEX-exchange/helpers/formatSmallPrice';

const trendingItems = [TrendingEnum.TRENDING, TrendingEnum.NEW_PAIRS];
const periodItems = [PeriodEnum.TIME_1M, PeriodEnum.TIME_5M, PeriodEnum.TIME_1H, PeriodEnum.TIME_6H, PeriodEnum.TIME_12H, PeriodEnum.TIME_24H];

const Trading: React.FC = () => {
  const [anchorTrending, setAnchorTrending] = useState<null | HTMLElement>(null);
  const [anchorPeriod, setAnchorPeriod] = useState<null | HTMLElement>(null);
  const [activeTrending, setActiveTrending] = useState(trendingItems[1]);
  const [activePeriod, setActivePeriod] = useState(periodItems[5]);
  const [appliedFilters, setAppliedFilters] = useState<FilterData | null>(null);
  const [sortBy, setSortBy] = useState('created_at');
  const [sortOrderBy, setSortOrderBy] = useState<SortOrder | null>(null);

  const openTrending = Boolean(anchorTrending);
  const openPeriod = Boolean(anchorPeriod);

  const [showSearch, setShowSearch] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [tokens, setTokens] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showSearch && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showSearch]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const fetchData = async (withLoading = false) => {
      if (withLoading) setLoading(true);

      try {
        // Если есть поисковый запрос и уже отфильтрованные токены — пропускаем
        if (debouncedSearchQuery && (showSearch && searchQuery.trim()
          ? tokens.filter(
            (token: any) =>
              token.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
              token.address.toLowerCase().includes(searchQuery.toLowerCase()),
          )
          : tokens).length) {
          return;
        }
        const trends = await getTrends({ activeTrending, activePeriod, searchQuery: debouncedSearchQuery, appliedFilters });
        console.log('trends', trends);
        let data;
        if (trends.data) {
          data = trends.data.map((trend: any) => {
            const baseToken = trends.included.find(
              (item: any) => item.type === 'token' && item.id === trend.attributes.base_token_id
            );
            const chart = generateChartData(trend.attributes.price_percent_changes);
            const percent = calculatePercentChange(chart);
            return {
              id: trend.id,
              avatar: baseToken.attributes.image_url,
              name: trend.attributes.name,
              time: formatTimeDifference(trend.attributes.pool_created_at),
              address: trend.attributes.address,
              pumpAddress: baseToken.attributes.address,
              priceUsd: `$${formatSmallPrice(+trend.attributes.price_in_usd, 7)}`,
              // Сохраним и числовое значение marketCap для сортировки
              marketCap: +trend.attributes.to_volume_in_usd,
              price: `$${formatMarketCap(trend.attributes.to_volume_in_usd) || 'N/A'}`,
              chart: chart,
              percent: `${+percent > 1000 ? '>1000.00' : (+calculatePercentChange(chart))?.toFixed(2)}%`,
            };
          });
        } else {
          data = trends.map((trend: any) => {
            const chart = generateChartData(trend.data.attributes.price_percent_changes);
            const baseToken = trend.included.find(
              (item: any) => item.type === 'token' && item.id === trend.data.attributes.base_token_id
            );
            return {
              id: trend.data.id,
              avatar: baseToken.attributes.image_url,
              name: trend.data.attributes.name,
              time: formatTimeDifference(trend.data.attributes.pool_created_at),
              address: trend.data.attributes.address,
              pumpAddress: baseToken.attributes.address,
              priceUsd: `$${(+trend.data.attributes.price_in_usd)?.toFixed(7)}`,
              marketCap: +trend.data.attributes.to_volume_in_usd,
              price: `$${formatMarketCap(trend.data.attributes.to_volume_in_usd) || 'N/A'}`,
              chart: chart,
              percent: `${(+calculatePercentChange(chart))?.toFixed(2)}%`,
            };
          });
          console.log('datadatadata',data);
        }
        setTokens(data);
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      } finally {
        if (withLoading) setLoading(false);
      }
    };

    fetchData(true); // При изменении параметров — с loading

    intervalId = setInterval(() => {
      if (!searchQuery) {
        fetchData(false); // Каждые 3 сек — без loading
      }
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [activeTrending, activePeriod, debouncedSearchQuery, appliedFilters]);

  function closeSearch() {
    setShowSearch(false);
    setSearchQuery('');
    if(searchQuery.trim()){
      setTokens([])
    }
  }

  const changeSortOrder = () => {
    let newOrder: SortOrder | null;
    if (sortOrderBy === null) {
      newOrder = SortOrder.ASC;
    } else if (sortOrderBy === SortOrder.ASC) {
      newOrder = SortOrder.DESK;
    } else {
      newOrder = null;
    }
    setSortOrderBy(newOrder);
    // Если сортировка отключена — возвращаем sortBy к исходному состоянию
    setSortBy(newOrder === null ? 'created_at' : 'market_cap');
  };

  // Открытие/закрытие меню Trending
  const handleOpenTrending = (event: MouseEvent<HTMLElement>) => {
    setAnchorTrending(event.currentTarget);
  };
  const handleCloseTrending = () => {
    setAnchorTrending(null);
  };
  const handleSelectTrending = (item: TrendingEnum) => {
    setActiveTrending(item);
    handleCloseTrending();
  };

  // Открытие/закрытие меню Period
  const handleOpenPeriod = (event: MouseEvent<HTMLElement>) => {
    setAnchorPeriod(event.currentTarget);
  };
  const handleClosePeriod = () => {
    setAnchorPeriod(null);
  };
  const handleSelectPeriod = (item: PeriodEnum) => {
    setActivePeriod(item);
    handleClosePeriod();
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // Фильтрация токенов по строке поиска (если включен поиск)
  // Сортировка токенов по market cap, если выбрана сортировка
  const tokensToDisplay = showSearch && !searchQuery.trim()
    ? tokens.filter(
      (token: any) =>
        token.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        token.address.toLowerCase().includes(searchQuery.toLowerCase()),
    )
    : tokens;
  const sortedTokens =
    sortOrderBy !== null && sortBy === 'market_cap'
      ? tokensToDisplay.slice().sort((a, b) => {
        const aVal = a.marketCap || 0;
        const bVal = b.marketCap || 0;
        return sortOrderBy === SortOrder.ASC ? aVal - bVal : bVal - aVal;
      })
      : tokensToDisplay;

  if (showFilter) {
    return (
      <Filter
        setShowFilter={setShowFilter}
        appliedFilters={appliedFilters}
        searchQuery={searchQuery}
        onApply={(filters) => {
          setSearchQuery(filters.searchKeyword);
          setAppliedFilters(filters);
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        width: 'calc(100% + 16px)',
        maxHeight: 'calc(100vh - 187px)',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 -8px',
        overflow: 'hidden',
      }}>
      {/* Верхняя панель с фильтрами (Trending / Period / Поиск / Фильтр) */}
      {showSearch ? (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#FFFFFF1A',
              borderRadius: '8px',
              border: `1px solid #2163FA`,
              padding: '0 13px',
              margin: '0 16px 12px',
            }}>
            <TextField
              fullWidth
              placeholder="Поиск"
              value={searchQuery}
              onChange={handleSearchChange}
              variant="standard"
              inputRef={inputRef}
              InputProps={{
                disableUnderline: true,
                sx: {
                  color: '#fff',
                  fontSize: '14px',
                  fontWeight: 500,
                  height: '36px',
                  '&::placeholder': { color: '#888' },
                },
              }}
            />
            <Button onClick={() => closeSearch()} sx={{ minWidth: 'auto', padding: '0 10px', color: '#fff' }}>
              <SearchCrossSVG style={{ width: '14px' }} />
            </Button>
          </Box>
          <div className="tw-bg-white/10 tw-h-[1px]"></div>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '0 16px 12px',
            borderBottom: '1px solid #ffffff',
          }}>
          {/* Trending dropdown */}
          <Box sx={{ position: 'relative', mr: 2 }}>
            <Button
              onClick={handleOpenTrending}
              sx={{
                color: openTrending ? '#2163FA' : '#fff',
                textTransform: 'none',
                fontSize: 12,
                p: 0,
                lineHeight: '12px',
              }}
              endIcon={
                <ArrowFilterDownSVG
                  style={{
                    width: '10px',
                    height: '5px',
                    transform: `rotate(${openTrending ? 180 : 0}deg)`,
                    transition: '0.2s',
                  }}
                />
              }>
              {activeTrending}
            </Button>
            <Menu
              anchorEl={anchorTrending}
              open={openTrending}
              onClose={handleCloseTrending}
              MenuListProps={{ sx: { p: 0 } }}
              PaperProps={{
                sx: {
                  bgcolor: '#1c1c1c',
                  color: '#fff',
                  mt: 1,
                },
              }}>
              {trendingItems.map((item) => (
                <MenuItem
                  key={item}
                  sx={{
                    fontSize: '12px',
                    py: '8px',
                    borderBottom: `0.5px solid ${item === activeTrending ? '#2163FA' : '#FFFFFF0D'}`,
                    lineHeight: '12px',
                    minHeight: 'auto',
                    color: item === activeTrending ? '#2163FA' : '#fff',
                  }}
                  onClick={() => handleSelectTrending(item)}>
                  {item}
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ display: activeTrending === TrendingEnum.TRENDING ? 'flex' : 'none', alignItems: 'center' }}>
            <div className="tw-bg-[#FFFFFF1A] tw-h-5 tw-w-[1px]"></div>

            {/* Period dropdown */}
            <Box sx={{ position: 'relative', mr: 'auto' }}>
              <Button
                onClick={handleOpenPeriod}
                sx={{
                  color: openPeriod ? '#2163FA' : '#fff',
                  textTransform: 'none',
                  fontSize: 12,
                  p: 0,
                  lineHeight: '12px',
                }}
                endIcon={
                  <ArrowFilterDownSVG
                    style={{
                      width: '10px',
                      height: '5px',
                      transform: `rotate(${openPeriod ? 180 : 0}deg)`,
                      transition: '0.2s',
                    }}
                  />
                }>
                {activePeriod}
              </Button>
              <Menu
                anchorEl={anchorPeriod}
                open={openPeriod}
                onClose={handleClosePeriod}
                MenuListProps={{ sx: { p: 0 } }}
                PaperProps={{
                  sx: {
                    bgcolor: '#1c1c1c',
                    color: '#fff',
                    mt: 1,
                  },
                }}>
                {periodItems.map((item) => (
                  <MenuItem
                    key={item}
                    sx={{
                      fontSize: '12px',
                      py: '8px',
                      borderBottom: `0.5px solid ${item === activePeriod ? '#2163FA' : '#FFFFFF0D'}`,
                      lineHeight: '12px',
                      minHeight: 'auto',
                      color: item === activePeriod ? '#2163FA' : '#fff',
                    }}
                    onClick={() => handleSelectPeriod(item)}>
                    {item}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>

          <div className="tw-bg-[#FFFFFF1A] tw-h-5 tw-w-[1px] tw-ml-auto"></div>
          <SearchSVG
            onClick={() => setShowSearch((prev) => !prev)}
            style={{
              width: '16px',
              height: '16px',
              margin: '0 20px',
              cursor: 'pointer',
              color: '#fff',
            }}
          />
          <div className="tw-bg-[#FFFFFF1A] tw-h-5 tw-w-[1px]"></div>
          <SettingsSVG
            onClick={() => setShowFilter((prev) => !prev)}
            style={{
              width: '16px',
              height: '12px',
              margin: '0 0 0 20px',
              cursor: 'pointer',
            }}
          />
        </Box>
      )}
      {/* Вывод таблицы с токенами */}
      <Box sx={{ flex: 1, overflowY: 'auto', padding: '0 8px' }}>
        <TableContainer component={Paper} sx={{ backgroundColor: '#000' }}>
          <Table sx={{ width: '100%', backgroundColor: '#000' }} aria-label="tokens table">
            {!showSearch && (
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      color: '#FFFFFF99',
                      fontSize: 10,
                      lineHeight: '10px',
                      borderBottom: '1px solid #333',
                      padding: '21px 0px 13px 0px',
                    }}>
                    Token
                  </TableCell>
                  <TableCell
                    sx={{
                      color: '#FFFFFF99',
                      fontSize: 10,
                      lineHeight: '10px',
                      borderBottom: '1px solid #333',
                      padding: '21px 0px 13px 0px',
                    }}
                    align="left"
                    onClick={changeSortOrder}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      Price/mkt cap
                      {sortOrderBy === null && (
                        <SortSVG style={{ width: '12px', height: '10px', marginLeft: '2px' }} />
                      )}
                      {sortOrderBy === SortOrder.ASC && (
                        <SortUpSVG style={{ width: '12px', height: '10px', marginLeft: '2px' }} />
                      )}
                      {sortOrderBy === SortOrder.DESK && (
                        <SortDownSVG style={{ width: '12px', height: '10px', marginLeft: '2px' }} />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: '#FFFFFF99',
                      fontSize: 10,
                      lineHeight: '10px',
                      borderBottom: '1px solid #333',
                      padding: '21px 0px 13px 0px',
                      textAlign: 'end',
                    }}
                    align="left">
                    %
                  </TableCell>
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {loading
                ? // Если идет загрузка, отображаем 6 строк TradingItem с prop loading
                Array.from(new Array(20)).map((_, index) => <TradingItem key={index} loading />)
                : // Иначе отображаем реальные данные
                sortedTokens.map((token: any) => <TradingItem key={token.id} token={token} />)}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Trading;
