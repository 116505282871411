import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SnackbarState {
  message: string | null;
  isOpen: boolean;
  status: 'success' | 'error' | 'warning' | 'waiting' | 'done' | 'default';
}

const initialState: SnackbarState = {
  message: null,
  isOpen: false,
  status: 'default',
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackbar: (state, action: PayloadAction<{ message: string; status?: SnackbarState['status'] }>) => {
      state.message = action.payload.message;
      state.isOpen = true;
      state.status = action.payload.status || 'default';
    },
    hideSnackbar: state => {
      state.message = null;
      state.isOpen = false;
      state.status = 'default';
    },
  },
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
