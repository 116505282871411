import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface ModalState {
  isOpen: boolean;
  title: string;
  component: React.ReactNode | null;
}

const initialState: ModalState = {
  isOpen: false,
  title: '',
  component: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<{ title: string; component: React.ReactNode }>) => {
      state.isOpen = true;
      state.title = action.payload.title;
      state.component = action.payload.component;
    },
    closeModal: state => {
      state.isOpen = false;
    },
    resetModal: state => {
      state.title = '';
      state.component = null;
    },
  },
});

export const { openModal, closeModal, resetModal } = modalSlice.actions;
export const selectModal = (state: RootState) => state.modal;
export default modalSlice.reducer;
