import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.KEY_FOR_SERVER || 'default-key';

export function encryptPrivateKey(privateKey: string): string {
  return CryptoJS.AES.encrypt(privateKey, SECRET_KEY).toString();
}

export function decryptPrivateKey(cipherText: string): string {
  const bytes = CryptoJS.AES.decrypt(cipherText, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
}
