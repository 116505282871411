import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactComponent as CloseSVG } from 'pages/DEX-exchange/svg/close.svg';
import { ReactComponent as SolanaSVG } from 'pages/DEX-exchange/svg/solana-settings.svg';
import { ReactComponent as TronSVG } from 'pages/DEX-exchange/svg/tron-settings.svg';
import { ReactComponent as SolanaGraySVG } from 'pages/DEX-exchange/svg/solana-grey.svg';
import { ReactComponent as TronGraySVG } from 'pages/DEX-exchange/svg/tron-grey.svg';
import StandartInput from '../StandartInput/StandartInput';
import Grid from '@mui/material/Grid2';

export enum Tab {
  SOL = 'SOL',
  TRON = 'TRON',
}

interface AccordionProps {
  title: string;
  data: {
    activeTab: Tab;
    inputs: any[];
  };
  isOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
  onDataChange?: (data: { activeTab: Tab; inputs: string[] }) => void;
}

const Accordion: React.FC<AccordionProps> = ({ title, isOpen: propIsOpen, data, onToggle, onDataChange }) => {
  const [isOpen, setIsOpen] = useState(propIsOpen || false);
  const [activeTab, setActiveTab] = useState<Tab>(data.activeTab);
  const [activeToken, setActiveToken] = useState<ReactElement | null>(null);
  const [inputs, setInputs] = useState<string[]>(data.inputs);

  const toggleAccordion = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    if (onToggle) onToggle(newState);
  };

  useEffect(() => {
    setActiveToken(activeTab === Tab.SOL ? <SolanaGraySVG
        style={{ width: '11px', height: '10px', minWidth: '11px', minHeight: '10px', marginLeft: '2px' }} /> :
      <TronGraySVG
        style={{ width: '11px', height: '10px', minWidth: '11px', minHeight: '10px', marginLeft: '2px' }} />);
  }, [activeTab]);

  // Передаём изменения в родительский компонент
  useEffect(() => {
    if (onDataChange) {
      onDataChange({ activeTab, inputs });
    }
  }, [activeTab, inputs, onDataChange]);

  const handleInputChange = (index: number, newValue: string) => {
    const newInputs = [...inputs];
    newInputs[index] = newValue;
    setInputs(newInputs);
  };

  return (
    <Box sx={{ borderBottom: '1px solid #FFFFFF1A', padding: '18px 0' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '12px', color: '#fff' }}>{title}</Typography>
        {isOpen ? (
          <Button
            onClick={toggleAccordion}
            sx={{
              color: '#fff',
              textTransform: 'none',
              fontSize: '13px',
              lineHeight: '14px',
              borderRadius: '6px',
              padding: '7px 18px',
              minWidth: '47px',
              maxWidth: '47px',
              border: '1px solid #FFFFFF1A',
              '&:hover': { background: 'none' },
            }}>
            <CloseSVG style={{ width: '9px', height: '9px' }} />
          </Button>
        ) : (
          <Button
            onClick={toggleAccordion}
            sx={{
              background: '#1E1F21',
              color: '#fff',
              textTransform: 'none',
              fontSize: '13px',
              lineHeight: '14px',
              borderRadius: '6px',
              padding: '4px 12px',
              minWidth: '47px',
              maxWidth: '47px',
              border: '1px solid #FFFFFF0D',
              '&:hover': { background: '#333' },
            }}>
            Edit
          </Button>
        )}
      </Box>

      <AnimatePresence>
        {isOpen && (
          <motion.div initial={{ opacity: 0, height: 0 }} animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }} transition={{ duration: 0.1, ease: 'easeInOut' }}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                border: '1px solid #FFFFFF0D',
                backgroundColor: '#1E1F21',
                borderRadius: '8px',
                padding: '1px',
                marginTop: '14px',
                marginBottom: '12px',
              }}>
              <Button
                onClick={() => setActiveTab(Tab.SOL)}
                sx={{
                  fontSize: '12px',
                  lineHeight: '12px',
                  width: '100%',
                  background: '#1E1F21',
                  color: '#fff',
                  padding: '9px',
                  borderRadius: '8px',
                  border: activeTab === Tab.SOL ? '1px solid #2163FA' : '1px solid transparent',
                }}>
                <SolanaSVG style={{ width: '10px', height: '9px', marginRight: '5px' }} />
                {Tab.SOL}
              </Button>
              <Button
                onClick={() => setActiveTab(Tab.TRON)}
                sx={{
                  fontSize: '12px',
                  lineHeight: '12px',
                  width: '100%',
                  background: '#1E1F21',
                  color: '#fff',
                  padding: '9px',
                  borderRadius: '8px',
                  border: activeTab === Tab.TRON ? '1px solid #2163FA' : '1px solid transparent',
                }}>
                <TronSVG style={{ width: '12px', height: '12px', marginRight: '5px' }} />
                {Tab.TRON}
              </Button>
            </Box>
            <Grid sx={{ marginBottom: '6px' }} container spacing={1} columns={4}>
              {Array.from({ length: 8 }).map((_, index) => (
                <Grid key={index} size={1}>
                  <StandartInput value={inputs[index]} onChange={e => handleInputChange(index, e.target.value)}
                                 endAdornment={activeToken} />
                </Grid>
              ))}
            </Grid>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default Accordion;
