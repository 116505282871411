import React, { useState } from 'react';
import { Box, TextField } from '@mui/material';

interface StandartInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  endAdornment?: React.ReactNode;
  className?: string;
  placeholder?: string;
  inputStyle?: any;
}

const StandartInput: React.FC<StandartInputProps> = ({ value, onChange, endAdornment, className, placeholder, inputStyle }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '8px',
        border: isFocused ? '1px solid #2163FA' : '1px solid #FFFFFF33',
        padding: '0 13px',
        transition: 'border 0.2s ease-in-out',
      }}>
      <TextField
        fullWidth
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        variant="standard"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        InputProps={{
          disableUnderline: true,
          sx: {
            width: '100%',
            color: '#fff',
            fontSize: '14px',
            fontWeight: 500,
            height: '36px',
            paddingRight: '8px',
            '&::placeholder': { color: '#888' },
            ...inputStyle,
          },
        }}
      />
      {endAdornment}
    </Box>
  );
};

export default StandartInput;
