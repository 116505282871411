import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

const WalletCard: React.FC<{
  balance: number;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ balance, setIsModalOpen }) => {
  const rate = useSelector((state: RootState) => state.exchangeRate.rate);

  const navigate = useNavigate();

  const handleWithdrawClick = () => {
    navigate('/dex/scan-wallet');
  };
  return (
    <Box
      sx={{
        borderRadius: '16px',
        overflow: 'hidden',
        padding: '16px',
        backgroundImage: `url('/img/png/background-wallet.png')`,
        backgroundSize: 'cover',
        boxShadow: '0px -1px 3.9px 0px #0049F030 inset',
        backgroundPosition: 'center',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '40px',
      }}>
      {/* Верхняя часть */}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '114px',
        }}>
        <Typography
          variant="body2"
          sx={{
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '12px',
            textAlign: 'left',
          }}>
          Total balance
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '12px',
            textAlign: 'left',
          }}>
          SOL
        </Typography>
      </Box>

      {/* Баланс */}
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
        }}>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '16px',
            marginBottom: '16px',
            textAlign: 'left',
          }}>
          {balance && rate ? '$'+(rate && balance * rate)?.toFixed(2):<Skeleton variant="text" width={55} height={16}/> }
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            fontSize: '40px',
            lineHeight: '32px',
            textAlign: 'left',
          }}>
          {balance ? balance?.toFixed(6)+ ' SOL': <Skeleton variant="text" width={300} height={32}/>}
        </Typography>
      </Box>
      <div className="tw-mt-4.5 tw-mb-4 tw-w-full tw-h-[1px] tw-bg-[#FFFFFF33]" />

      {/* Кнопки */}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '16px',
        }}>
        <Button
          onClick={() => setIsModalOpen(true)}
          startIcon={<AddIcon />}
          sx={{
            flex: 1,
            border: '1px solid #2163FA',
            borderRadius: '8px',
            padding: '8px',
            color: '#fff',
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 500,
            backgroundColor: '#09132b',
            height: '40px',
            '&:hover': {
              backgroundColor: 'rgba(0, 94, 255, 0.1)',
            },
          }}>
          Add funds
        </Button>
        <Button
          onClick={handleWithdrawClick}
          startIcon={<DownloadIcon />}
          sx={{
            flex: 1,
            border: '1px solid #2163FA',
            borderRadius: '8px',
            padding: '8px',
            color: '#fff',
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 500,
            backgroundColor: '#09132b',
            height: '40px',
            '&:hover': {
              backgroundColor: 'rgba(0, 94, 255, 0.1)',
            },
          }}>
          Withdraw
        </Button>
      </Box>
    </Box>
  );
};

export default WalletCard;
