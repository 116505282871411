export function formatMarketCap(value: number): string {
  const abs = Math.abs(value);

  if (abs >= 1e9 - 1e6) {
    return (value / 1e9).toFixed(2) + 'B';
  } else if (abs >= 1e6) {
    return (value / 1e6).toFixed(2) + 'M';
  } else if (abs >= 1e3) {
    return (value / 1e3).toFixed(2) + 'K';
  }
  return (+value)?.toFixed(2);
}
