import React from 'react';
import { Box, Typography } from '@mui/material';

interface MiniChartProps {
  data: number[]; // Точки для графика
  percent?: string; // Текст с процентом, например "+126.98%"
  width?: number; // Ширина «полезной» части (без отступов) — по умолчанию 60
  height?: number; // Высота «полезной» части (без отступов) — по умолчанию 40
  strokeColor?: string; // Цвет линии (по умолчанию #4caf50)
  fillColor?: string; // Цвет градиента (по умолчанию #4caf50)
  tension?: number; // Коэффициент плавности (0.2 по умолчанию)
  chartMargin?: number[]; // Отступы [top, right, bottom, left] (в пикселях)
}

/**
 * Catmull-Rom → Cubic Bezier с учётом tension
 */
function catmullRom2bezier(points: { x: number; y: number }[], tension: number): string {
  if (points.length < 2) return '';

  let d = `M${points[0].x},${points[0].y}`;
  for (let i = 0; i < points.length - 1; i++) {
    const p0 = i === 0 ? points[0] : points[i - 1];
    const p1 = points[i];
    const p2 = points[i + 1];
    const p3 = i + 2 < points.length ? points[i + 2] : p2;

    // Контрольные точки
    const cp1x = p1.x + ((p2.x - p0.x) / 6) * tension;
    const cp1y = p1.y + ((p2.y - p0.y) / 6) * tension;
    const cp2x = p2.x - ((p3.x - p1.x) / 6) * tension;
    const cp2y = p2.y - ((p3.y - p1.y) / 6) * tension;

    d += ` C${cp1x},${cp1y} ${cp2x},${cp2y} ${p2.x},${p2.y}`;
  }
  return d;
}

const MiniChart: React.FC<MiniChartProps> = ({
                                               data,
                                               percent,
                                               width = 60,
                                               height = 40,
                                               strokeColor = '#4caf50',
                                               fillColor = '#4caf50',
                                               tension = 0.2,
                                               // По умолчанию отступы = 0 по всем сторонам
                                               chartMargin = [0, 0, 0, 0],
                                             }) => {
  // Если данных мало, нет смысла строить
  if (!data || data.length < 2) {
    return (
      <Box textAlign="center" color={strokeColor}>
        <Typography>{percent}</Typography>
        <Typography sx={{ fontSize: 12 }}>No chart data</Typography>
      </Box>
    );
  }

  // Деструктурируем отступы
  const [top, right, bottom, left] = chartMargin;

  // Итоговые размеры всего контейнера (полезная область + отступы)
  const totalWidth = width + left + right;
  const totalHeight = height + top + bottom;

  // Находим min/max, чтобы растянуть данные по вертикали
  const minVal = Math.min(...data);
  const maxVal = Math.max(...data);
  const range = maxVal - minVal || 1;

  // Шаг между точками по оси X
  const stepX = (width - 0) / (data.length - 1);

  // Переводим data в координаты (x, y) + учитываем отступы
  const points = data.map((val, i) => {
    const x = left + i * stepX;
    // Инвертируем Y, чтобы 0 был внизу:
    const y = top + (height - ((val - minVal) / range) * height);
    return { x, y };
  });

  // Генерируем сглаженный путь
  const linePath = catmullRom2bezier(points, tension);

  // Последняя точка для закрытия заливки
  const last = points[points.length - 1];

  // Заливка: замыкаем путь внизу (y = totalHeight) на x = last.x, затем x = left
  const fillPath = `${linePath} 
    L ${last.x},${totalHeight} 
    L ${left},${totalHeight} 
    Z`;

  // Уникальный ID для линейного градиента
  const gradientId = React.useId();

  return (
    <span
      style={{
        position: 'relative',
        width: `${totalWidth}px`,
        height: `${totalHeight}px`,
        // border: "1px dashed red", // Для отладки
      }}>
      <svg style={{ width: '100%', height: '100%', display: 'block' }} viewBox={`0 0 ${totalWidth} ${totalHeight}`}
           fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={fillColor} stopOpacity="0.3" />
            <stop offset="100%" stopColor={fillColor} stopOpacity="0" />
          </linearGradient>
        </defs>

        {/* Заливка */}
        <path d={fillPath} fill={`url(#${gradientId})`} />

        {/* Линия */}
        <path d={linePath} stroke={strokeColor} strokeWidth={2} fill="none" />

        {/* Точка на конце */}
        <circle cx={last.x} cy={last.y} r={3} fill={strokeColor} />
      </svg>

      {/* Текст под графиком (абсолютное позиционирование) */}
      <span
        style={{
          position: 'absolute',
          bottom: -4,
          left: 0,
          width: '100%',
          textAlign: 'center',
          transform: 'translateY(100%)',
        }}>
        <Typography sx={{ fontSize: 10, lineHeight: '10px', color: strokeColor }}>{percent}</Typography>
      </span>
    </span>
  );
};

export default MiniChart;
