import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Option } from "pages/DEX-exchange/Components/Wallet/Wallet";

interface WalletState {
  destinationWalletAddress: string;
  amount: number;
  amountSent: number;
  address: string;
  blockchain: Option;
  currencyTransfer: "SOL" | "USD";
}

const initialState: WalletState = {
  destinationWalletAddress: "",
  amount: 0,
  amountSent: 0,
  address: '',
  blockchain: { value: "solana", label: "SOL", currency: "$" },
  currencyTransfer: "SOL",
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setDestinationWallet(state, action: PayloadAction<string>) {
      state.destinationWalletAddress = action.payload;
    },
    setAmount(state, action: PayloadAction<number>) {
      state.amount = action.payload;
    },
    setAmountSent(state, action: PayloadAction<number>) {
      state.amountSent = action.payload;
    },
    setAddress(state, action: PayloadAction<string>) {
      state.address = action.payload;
    },
    setBlockchain(state, action: PayloadAction<Option>) {
      state.blockchain = action.payload;
    },
    setCurrencyTransfer(state, action: PayloadAction<"SOL" | "USD">) {
      state.currencyTransfer = action.payload;
    },
  },
});

export const { setDestinationWallet, setAmount, setAmountSent, setCurrencyTransfer, setAddress,setBlockchain } =
  walletSlice.actions;
export default walletSlice.reducer;
