import { Connection, Keypair, LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction } from '@solana/web3.js';
import { BlockchainService, Transaction as Tx } from './BlockchainService';
import { decryptPrivateKey } from 'pages/DEX-exchange/helpers/encryption';
import { getAllTransactions } from 'pages/DEX-exchange/Components/Positions/services/transactions';
import { formatDate } from 'pages/DEX-exchange/helpers/formatDate';

export class SolanaService implements BlockchainService {
  private static instance: SolanaService;
  connection: Connection;

  private constructor(network: string) {
    this.connection = new Connection(network, 'confirmed');
  }

  static getInstance(network: string) {
    if (!SolanaService.instance) {
      SolanaService.instance = new SolanaService(network);
    }
    return SolanaService.instance;
  }

  async getBalance(address: string): Promise<number> {
    try {
      const publicKey = new PublicKey(address);
      const lamports = await this.connection.getBalance(publicKey);
      return lamports / LAMPORTS_PER_SOL;
    } catch (error) {
      console.error('Ошибка получения баланса (Solana):', error);
      throw error;
    }
  }

  async sendTransaction(
    from: string,
    to: string,
    amount: number,
  ): Promise<string> {
    try {
      const fromPublicKey = new PublicKey(from);
      const toPublicKey = new PublicKey(to);
      const key = localStorage.getItem('solana_wallet');
      const decrypted = decryptPrivateKey(key!)
      const fromKeypair = Keypair.fromSecretKey(
        Uint8Array.from(JSON.parse(decrypted)),
      );

      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: fromPublicKey,
          toPubkey: toPublicKey,
          lamports: Math.round(amount * LAMPORTS_PER_SOL),
        }),
      );

      const signature = await this.connection.sendTransaction(transaction, [
        fromKeypair,
      ]);
      await this.connection.confirmTransaction(signature);
      return signature;
    } catch (error) {
      console.error('Ошибка отправки транзакции (Solana):', error);
      throw error;
    }
  }

  subscribeToBalanceChange(
    address: string,
    callback: (balance: number) => void,
  ): number {
    const publicKey = new PublicKey(address);

    const checkBalance = async () => {
      try {
        const lamports = await this.connection.getBalance(publicKey);
        callback(lamports / LAMPORTS_PER_SOL);
      } catch (error) {
        console.error('Ошибка получения баланса:', error);
      }
    };

    // Запускаем интервал (например, проверяем баланс раз в 10 секунд)
    const intervalId = setInterval(checkBalance, 10000);
    return intervalId as unknown as number;
  }

  unsubscribeFromBalanceChange(subscriptionId: number): void {
    clearInterval(subscriptionId);
  }

  async getTransactionHistory(address: string): Promise<Tx[]> {
    try {
      const publicKey = new PublicKey(address);
      // Получаем последние 20 транзакций
      const signaturesInfo = await this.connection.getSignaturesForAddress(
        publicKey,
        { limit: 100 },
      );

      const allSignatures = signaturesInfo.map(item => item.signature);
      const allTransactions = await getAllTransactions(allSignatures);

      const transactions: Tx[] = [];
      console.log('signatures',allSignatures, allTransactions);

      for (const tx of allTransactions) {
        // Отбираем tokenTransfers, которые касаются данного токена
        const transfer = tx.accountData.find((t: any) => t.account === address);
        console.log(tx);
        const fiftyPercent = (Math.abs(transfer.nativeBalanceChange) / 100 * 50);
        if (!transfer) continue;
        let transaction = {} as any;
        if (transfer.nativeBalanceChange < 0) {
          const dataWhereTransactionGoes = tx.accountData.find((t: any) =>{
            return t.account.toLowerCase() !== address.toLowerCase() && t.nativeBalanceChange !== 1 && Math.abs(transfer.nativeBalanceChange) + fiftyPercent > t.nativeBalanceChange && t.nativeBalanceChange > Math.abs(transfer.nativeBalanceChange) - fiftyPercent;
          })
          transaction = {
            action: 'Withdraw',
            icon: 'withdraw',
            recipient: dataWhereTransactionGoes?.account,
            price: '-' + Math.abs((transfer.nativeBalanceChange / 1e9)).toFixed(6) + ' SOL',
            amountSol: Math.abs((transfer.nativeBalanceChange / 1e9)),
            date:formatDate(tx.timestamp)
          }
        } else {
          const dataWhereTransactionGoes = tx.accountData.find((t: any) =>{
            return t.account.toLowerCase() !== address.toLowerCase() && t.nativeBalanceChange !== 1 && transfer.nativeBalanceChange + fiftyPercent > Math.abs(t.nativeBalanceChange) && Math.abs(t.nativeBalanceChange) > transfer.nativeBalanceChange - fiftyPercent;
          })
          transaction = {
            action: 'Add funds',
            icon: 'add',
            recipient: dataWhereTransactionGoes?.account,
            price: '+' + Math.abs((transfer.nativeBalanceChange / 1e9)).toFixed(6) + ' SOL',
            amountSol: Math.abs((transfer.nativeBalanceChange / 1e9)),
            date:formatDate(tx.timestamp)
          }
        }
        if(transaction.recipient?.toLowerCase() && transaction.recipient?.toLowerCase() !== '6LuH9f6nNKGHNjGJs3hh7bhjcgjU2iq4L3S2XZ3gmiJA'.toLowerCase()){
          transactions.push({ ...transaction });
        }
      }

      console.log('transactions',transactions);
      return transactions;
    } catch (error) {
      console.error('Ошибка получения истории транзакций (Solana):', error);
      throw error;
    }
  }

  generatePaymentLink(address: string, amount?: number): string {
    const params: string[] = [];
    if (amount !== undefined) {
      params.push(`amount=${amount}`);
    }
    return `solana:${address}?${params.join('&')}`;
  }
}
