import React from 'react';
import { Box, IconButton, Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { ReactComponent as SolanaSVG } from 'pages/DEX-exchange/svg/solana-mock.svg';
import { ReactComponent as TabletSVG } from 'pages/DEX-exchange/svg/tablet.svg';
import { ReactComponent as CopySVG } from 'pages/DEX-exchange/svg/copy.svg';
import MiniChart from 'pages/DEX-exchange/common/MiniChart/MiniChart';
import { useDispatch } from 'react-redux';
import { showSnackbar } from 'store/slice/snackbarSlice';
import { useNavigate } from 'react-router-dom';
import { getPercentColor } from 'pages/DEX-exchange/helpers/getPercentColor';

// Модель данных токена
interface TokenData {
  id: number;
  avatar: string;
  name: string;
  time: string;
  address: string;
  pumpAddress: string;
  marketAddress: string;
  price: string;
  priceUsd: string;
  percent: string;
  chart: number[];
}

// Добавляем опциональное свойство loading, чтобы при его значении true отображался скелетон
interface TradingItemProps {
  token?: TokenData;
  loading?: boolean;
}

// Стили для аватарки
const AvatarImg = styled('img')({
  width: 36,
  height: 36,
  borderRadius: '50%',
  objectFit: 'cover',
});

// Вспомогательная функция для сокращения адреса
const shortAddress = (address: string) => {
  if (address.length <= 8) return address;
  return address.slice(0, 4) + '...' + address.slice(-4);
};

const TradingItem: React.FC<TradingItemProps> = ({ token, loading = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Если идет загрузка или токен не передан, отрисовываем строку с Skeleton вместо реальных данных
  if (loading || !token) {
    return (
      <TableRow sx={{ borderBottom: '1px solid #333' }}>
        {/* Первый столбец: аватар, название и время */}
        <TableCell sx={{ verticalAlign: 'middle', width: '50%', padding: '14px 0', borderBottom:'none' }}>
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <Skeleton variant="circular" width={36} height={36} />
            </Box>
            <Box display="flex" flexDirection="column">
              <Skeleton variant="text" width={80} height={12} />
              <Skeleton variant="text" width={120} height={10} sx={{ mt: 0.5 }} />
            </Box>
          </Box>
        </TableCell>
        {/* Второй столбец: цена */}
        <TableCell align="left" sx={{ verticalAlign: 'middle', padding: '14px 0', borderBottom:'none' }}>
          <Skeleton variant="text" width={70} height={12} />
          <Skeleton variant="text" width={90} height={10} sx={{ mt: 0.5 }} />
        </TableCell>
        {/* Третий столбец: мини-график */}
        <TableCell align="right" sx={{ verticalAlign: 'middle', padding: '5px 0 12px 0', borderBottom:'none' }}>
          {/*<Skeleton variant="rectangular" width={80} height={51} />*/}
        </TableCell>
      </TableRow>
    );
  }

  // Если загрузка завершена, отрисовываем реальный TradingItem
  const { avatar, name, time, address, price, priceUsd, percent, chart, pumpAddress } = token;
  const percentColor = getPercentColor(percent);

  // Функция копирования адреса
  const handleCopy = (event: React.MouseEvent<HTMLButtonElement>, data: string) => {
    event.stopPropagation();
    navigator.clipboard.writeText(data).then(
      () => dispatch(showSnackbar({ message: 'Wallet address copied to clipboard!' })),
      () => dispatch(showSnackbar({ message: 'Failed to copy wallet address.', status: 'error' }))
    );
  };

  const openToken = () => {
    navigate(`/dex/token/${address}`);
  };

  return (
    <TableRow
      onClick={openToken}
      sx={{
        borderBottom: '1px solid #333',
        '&:last-of-type': { borderBottom: '1px solid #333' },
      }}
    >
      {/* Первый столбец: токен (аватар, имя, время, адрес) */}
      <TableCell
        sx={{
          color: '#fff',
          fontSize: 12,
          borderBottom: '1px solid #333',
          verticalAlign: 'middle',
          width: '50%',
          padding: '14px 0',
        }}
      >
        <Box display="flex" alignItems="center">
          {/* Аватар + иконка Solana */}
          <Box position="relative" mr={2}>
            <AvatarImg src={avatar} alt="token avatar" />
            <SolanaSVG
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: '12px',
                height: '12px',
              }}
            />
          </Box>

          {/* Название и дополнительная информация */}
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              <TabletSVG style={{ width: '12px', height: '12px', marginRight: '4px' }} />
              <Typography sx={{ fontSize: 12, color: '#fff' }}>
                {name.slice(0, 10) + '...'}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center">
              <Typography sx={{ fontSize: 10, color: '#888' }}>
                {time} • {shortAddress(pumpAddress)}
              </Typography>
              <IconButton onClick={(event) => handleCopy(event, pumpAddress)} sx={{ m: 0.5, ml: 1, p: 0 }}>
                <CopySVG style={{ width: '14px', height: '14px' }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </TableCell>

      {/* Второй столбец: цена */}
      <TableCell
        sx={{
          color: '#fff',
          fontSize: 12,
          borderBottom: '1px solid #333',
          verticalAlign: 'middle',
          width: 'auto',
          padding: '14px 0',
        }}
        align="left"
      >
        <Typography sx={{ fontSize: 12 }}>{price}</Typography>
        <Typography sx={{ fontSize: 10, color: '#777' }}>{priceUsd}</Typography>
      </TableCell>

      {/* Третий столбец: процент и мини-график */}
      <TableCell
        sx={{
          fontSize: 12,
          borderBottom: '1px solid #333',
          verticalAlign: 'middle',
          color: percentColor,
          padding: '5px 0 12px 0',
        }}
        align="right"
      >
        <span className="tw-flex tw-justify-end">
          <MiniChart
            data={chart}
            percent={percent}
            strokeColor={percentColor}
            fillColor={percentColor}
            chartMargin={[5, 5, 5, 5]}
            width={52}
            height={23}
            tension={1}
          />
        </span>
      </TableCell>
    </TableRow>
  );
};

export default TradingItem;
