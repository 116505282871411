import { useEffect, useRef, useState } from "react"
import translations from "../i18n"
import Language from "types/language";
import AppContextType from "types/app-context";
import getUserData from "api/get-user-data";
import updateLevel from "api/update-level";
import UserData from "types/user-data";
import AppContext from "utils/app-context";
import getLang from "utils/get-lang";
import { toast } from "react-toastify";
import tg from "utils/tg";
const inviteLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(1)) || ""
export default () => {
    const [language, setLanguage] = useState<Language>(getLang());
    const [user, setUser] = useState<UserData | null>(null)
    const [showGameRules, setshowGameRules] = useState(false)
    const t = <T extends keyof typeof translations['en']>(section: T): typeof translations['en'][T] => {
        return translations[language][section];
    };

    const errors = t('errors')

    const interval = useRef<NodeJS.Timer | null>(null)
    const updateUserInfo = async () => {
        const result = await getUserData(language)
        if (result) setUser(result)
        // if(result?.error) {
        //     tg.showPopup({message:errors.appError,buttons:[{type:'close',text:errors.quit}]},() => {
        //         tg.close()
        //         tg.openTelegramLink(inviteLink)
        //     })
        // }
        
    }

      const updateUserLevel = async () => {
        const result = await updateLevel()
          if (result) {
            await updateUserInfo()
        }
        // if(result?.error) {
        //     tg.showPopup({message:errors.appError,buttons:[{type:'close',text:errors.quit}]},() => {
        //         tg.close()
        //         tg.openTelegramLink(inviteLink)
        //     })
        // }
        
    }

    const AppContextValue:AppContextType = {
        lang:language,
        setLanguage,
        t,
        user,
        updateUserInfo,
        setUser,
        showGameRules,
        setshowGameRules,
        updateUserLevel
    }

    const checkAlert = () => {
        console.log("🚀 ~ checkAlert ~ user?.alert:", user?.alert)
        if(user?.alert){
            toast(user?.alert,{icon:<img src="./img/coin.svg" />});
            setUser({...user,alert:null})
        }
    }

    useEffect(() => {
        updateUserInfo()
    }, [])
    useEffect(() => {
        localStorage.setItem('lang',language)
        updateUserInfo()
    }, [language])
    useEffect(() => {
        checkAlert()
        if(interval.current) clearInterval(interval.current)
        if (!user || !user.energy_seconds) return;

        // const { lang, max_energy, energy_speed } = user;
        // const { energy_speed } = user;
    
        // Обновляем язык, если он изменился
        // if (lang !== language) setLanguage(lang || 'en');
        
        interval.current = setInterval(() => {
            setUser((prevUser) => {
                //* user?.max_energy / 14400
                if (!prevUser) return prevUser;
    
                const { energy, max_energy, energy_seconds } = prevUser;
                prevUser.energy_seconds -= 1;
    
                // Если энергия меньше максимальной, прибавляем
                if (energy < max_energy) {
               
                    let nextValue = Math.floor(max_energy * (1 - energy_seconds / 14400));
                    nextValue = nextValue > max_energy ? max_energy : nextValue;
                    return { ...prevUser, energy: nextValue };
                }
                    
                return prevUser;
            });
        }, 1000);
    
        return () => {
            if(interval.current) clearInterval(interval.current)
        };
    }, [user])
    return {AppContext,AppContextValue}
}
