import { Box, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMyPosition, getStats } from 'pages/DEX-exchange/api/coinGeckoApi';
import { showSnackbar } from '../../../../../store/slice/snackbarSlice';
import { RootState } from '../../../../../store/store';
import { formatMarketCap } from 'pages/DEX-exchange/helpers/formatToThausandOrMillions';

interface StatsState {
  address: string;
  pairAddress: string;
  priceInUsd: string;
}

const Stats: React.FC<StatsState> = ({ address, pairAddress, priceInUsd }) => {
  const rate = useSelector((state: RootState) => state.exchangeRate.rate);
  const dispatch = useDispatch();
  const walletAddress = useSelector((state: RootState) => state.wallet.address);
  const [isFakeStats, setIsFakeStats] = useState(true);
  const [token, setToken] = useState({
    positions: {
      label: 'My position',
      items: [
        { title: 'Invested', value: `-` },
        { title: 'Sold', value: '-' },
        { title: 'Remaining', value: `-` },
        { title: 'PnL', value: `-` },
        { title: 'PnL (%)', value: `-` },
      ],
    },
    statistics: {
      label: 'Token’s statistic',
      tabs: [
        {
          label: '-',
          value: `-`,
          index: 0,
          contentLeft: [
            { title: 'Txns', value: `-` },
            { title: 'Volume', value: `-` },
            { title: 'Makers', value: '-' },
          ],
          contentRight: [
            { titleLeft: 'Txns', titleRight: 'Sells', valueLeft: `0`, valueRight: '0' },
            { titleLeft: 'Buy vol', titleRight: 'Sells vol', valueLeft: '0', valueRight: '0' },
            { titleLeft: 'Buyers', titleRight: 'Sellers', valueLeft: `0`, valueRight: `0` },
          ],
        },
        {
          label: '-',
          value: `-`,
          index: 1,
          contentLeft: [
            { title: 'Txns', value: `-` },
            { title: 'Volume', value: `-` },
            { title: 'Makers', value: '-' },
          ],
          contentRight: [
            { titleLeft: 'Txns', titleRight: 'Sells', valueLeft: `0`, valueRight: '0' },
            { titleLeft: 'Buy vol', titleRight: 'Sells vol', valueLeft: '0', valueRight: '0' },
            { titleLeft: 'Buyers', titleRight: 'Sellers', valueLeft: `0`, valueRight: `0` },
          ],
        },
        {
          label: '-',
          value: `-`,
          index: 2,
          contentLeft: [
            { title: 'Txns', value: `-` },
            { title: 'Volume', value: `-` },
            { title: 'Makers', value: '-' },
          ],
          contentRight: [
            { titleLeft: 'Txns', titleRight: 'Sells', valueLeft: `0`, valueRight: '0' },
            { titleLeft: 'Buy vol', titleRight: 'Sells vol', valueLeft: '0', valueRight: '0' },
            { titleLeft: 'Buyers', titleRight: 'Sellers', valueLeft: `0`, valueRight: `0` },
          ],
        },
        {
          label: '-',
          value: `-`,
          index: 3,
          contentLeft: [
            { title: 'Txns', value: `-` },
            { title: 'Volume', value: `-` },
            { title: 'Makers', value: '-' },
          ],
          contentRight: [
            { titleLeft: 'Txns', titleRight: 'Sells', valueLeft: `0`, valueRight: '0' },
            { titleLeft: 'Buy vol', titleRight: 'Sells vol', valueLeft: '0', valueRight: '0' },
            { titleLeft: 'Buyers', titleRight: 'Sellers', valueLeft: `0`, valueRight: `0` },
          ],
        },
      ],
    },
  } as any);
  const [loadingState, setLoadingState] = useState(true);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const tokens = await getStats(address);
        if (tokens && tokens.pairs) {
          let token = tokens.pairs.find((token: any) =>
            token.pairAddress.toLowerCase() === pairAddress.toLowerCase() && token.dexId === 'pumpswap',
          );
          if (token) {
            setToken((prev: any) => ({
              ...prev,
              ...formatStatsFromBackend(token),
            }));
            setIsFakeStats(false);
          } else {
            dispatch(showSnackbar({ message: 'Token not found', status: 'error' }));
          }
        } else {
          console.log('Нет данных для отображения');
        }
      } catch (error) {
        console.error('Ошибка получения данных статистики:', error);
      } finally {
        setLoadingState(false);
      }
      try {
        const position = await getMyPosition({ tokenMint: address, walletAddress, priceInUsd, rate });
        if (position) {
          setToken((prev: any) => ({
            ...prev,
            positions: position,
          }));
        }
      } catch (error) {
        console.error('Ошибка получения данных статистики:', error);
      } finally {
        setLoadingState(false);
      }
    }

    fetchData();

    const intervalId = setInterval(fetchData, 1000);
    return () => clearInterval(intervalId);
  }, []);

  function formatStatsFromBackend(newToken: any) {
    const timeframes = [
      { label: '5M', value: 'm5' },
      { label: '1H', value: 'h1' },
      { label: '6H', value: 'h6' },
      { label: '24H', value: 'h24' },
    ];
    const tabs = timeframes.map((tf, index) => {
      const buys = newToken.txns[tf.value]?.buys || 0;
      const sells = newToken.txns[tf.value]?.sells || 0;
      const txns = buys + sells;
      const volume = newToken.volume[tf.value] || 0;
      const priceChange = newToken.priceChange[tf.value] || 0;

      return {
        label: tf.label.toUpperCase(),
        value: `${priceChange > 0 ? '+' : ''}${priceChange.toFixed(2)}%`,
        color: priceChange > 0 ? '#6EE297' : '#C35268',
        index,
        contentLeft: [
          { title: 'Txns', value: `${txns}` },
          { title: 'Volume', value: `$${formatMarketCap(volume)}` },
          { title: 'Makers', value: '-' },
        ],
        contentRight: [
          { titleLeft: 'Txns', titleRight: 'Sells', valueLeft: `${txns}`, valueRight: `${sells}` },
          { titleLeft: 'Buy vol', titleRight: 'Sells vol', valueLeft: '-', valueRight: '-' },
          { titleLeft: 'Buyers', titleRight: 'Sellers', valueLeft: `${buys}`, valueRight: `${sells}` },
        ],
      };
    });
    return {
      statistics: {
        label: 'Token’s statistic',
        tabs,
      },
    };
  }

  return (
    <Box>
      <Typography sx={{ margin: '16px 0 8px', color: '#FFFFFF66', fontSize: '12px' }}>
        {token.positions.label}
      </Typography>
      <Box sx={{ display: 'grid', gap: '8px', marginBottom: '8px', gridTemplateColumns: 'repeat(6, 1fr)' }}>
        {token.positions.items.map((item: any, index: number) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '16px',
              border: '1px solid #FFFFFF33',
              borderRadius: '8px',
              gap: '10px',
              fontSize: '12px',
              lineHeight: '8px',
              gridColumn: `span ${index < 3 ? 2 : 3}`,
              color: item?.color ?? '#fff',
            }}
          >
            <Typography sx={{ fontSize: '10px', color: '#FFFFFF66', lineHeight: '7px' }}>
              {item.title}
            </Typography>
            {loadingState || isFakeStats ?
              <Skeleton variant="text" width={50} height={16} sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} /> : item.value}
          </Box>
        ))}
      </Box>
      <Typography sx={{ margin: '24px 0 8px', color: '#FFFFFF66', fontSize: '12px' }}>
        {token.statistics.label}
      </Typography>
      <Box sx={{ border: '1px solid #FFFFFF33', borderRadius: '8px', padding: '12px' }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '8px',
          paddingBottom: '12px',
          marginBottom: '8px',
          borderBottom: '1px solid #FFFFFF1A',
        }}>
          {token.statistics.tabs.map((item: any, index: number) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                flex: '1',
                padding: '6px',
                border: `1px solid #${item.index === activeTab ? '2163FA' : 'FFFFFF33'}`,
                borderRadius: '8px',
                gap: '10px',
                fontSize: '12px',
                lineHeight: '8px',
                color: '#6EE297',
                backgroundColor: '#1E1F21',
              }}
              onClick={() => setActiveTab(index)}
            >
              <Typography sx={{ color: item.active ? '#fff' : '#FFFFFF99', fontSize: '12px', lineHeight: '8px' }}>
                {item.label}
              </Typography>
              <Typography sx={{ color: item.color, fontSize: '12px', lineHeight: '8px' }}>
                {loadingState || isFakeStats ? <Skeleton variant="text" width={30} height={12}
                                                         sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} /> : item.value}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box>
          {token.statistics.tabs.map((item: any) => {
            return item.index === activeTab ? (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '20%',
                  gap: '24px',
                  padding: '13px 12px 0 0',
                  borderRight: '1px solid #FFFFFF1A',
                }}>
                  {item.contentLeft.map((content: any, idx: number) => (
                    <Box key={idx}>
                      <Typography sx={{
                        marginBottom: '10px',
                        fontSize: '10px',
                        color: '#FFFFFF99',
                        lineHeight: '7px',
                      }}>
                        {content.title}
                      </Typography>
                      <Typography sx={{ fontSize: '12px', color: '#FFF', lineHeight: '8px' }}>
                        {loadingState || isFakeStats ? <Skeleton variant="text" width={40} height={14}
                                                                 sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} /> : content.value}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '80%', padding: '13px 0 0 12px' }}>
                  {item.contentRight.map((content: any, idx: number) => (
                    <Box key={idx} sx={{ marginBottom: '13px' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                        <Box>
                          <Typography sx={{
                            marginBottom: '10px',
                            fontSize: '10px',
                            color: '#FFFFFF99',
                            lineHeight: '7px',
                          }}>
                            {content.titleLeft}
                          </Typography>
                          <Typography sx={{ fontSize: '12px', color: '#FFF', lineHeight: '8px' }}>
                            {content.valueLeft}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography sx={{
                            marginBottom: '10px',
                            fontSize: '10px',
                            color: '#FFFFFF99',
                            lineHeight: '7px',
                          }}>
                            {loadingState || isFakeStats ? <Skeleton variant="text" width={20} height={10}
                                                                     sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} /> : content.titleRight}
                          </Typography>
                          <Typography sx={{
                            fontSize: '12px',
                            color: '#FFF',
                            lineHeight: '8px',
                            textAlign: 'end',
                          }}>
                            {loadingState || isFakeStats ? <Skeleton variant="text" width={40} height={14}
                                                                     sx={{ bgcolor: 'rgba(255,255,255,0.8)' }} /> : content.valueRight}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', gap: '2px' }}>
                        <Box sx={{
                          width: `${loadingState || isFakeStats ? 50 : (+content.valueLeft / (+content.valueLeft + +content.valueRight)) * 100}%`,
                          height: '3px',
                          backgroundColor: '#6EE297',
                          borderRadius: '10px',
                        }}></Box>
                        <Box sx={{
                          width: `${loadingState || isFakeStats ? 50 : (+content.valueRight / (+content.valueLeft + +content.valueRight)) * 100}%`,
                          height: '3px',
                          backgroundColor: '#C35268',
                          borderRadius: '10px',
                        }}></Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            ) : (
              ''
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
export default Stats;
