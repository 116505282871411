import React, { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { SolanaService } from '../Components/Wallet/services/SolanaService';
import { EthereumService } from '../Components/Wallet/services/EthereumService';
import { BlockchainService } from '../Components/Wallet/services/BlockchainService';

const BlockchainServiceContext = createContext<BlockchainService | undefined>(undefined);

export const useBlockchainService = () => {
  const context = useContext(BlockchainServiceContext);
  if (!context) throw new Error('BlockchainServiceContext not provided');
  return context;
};

export const BlockchainServiceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { blockchain } = useSelector((state: RootState) => state.wallet);

  const blockchainService = useMemo(() => {
    if (!blockchain) return undefined;
    if (blockchain.value === 'solana') {
        return SolanaService.getInstance(process.env.REACT_APP_HELIUS_RPC_URL!) as BlockchainService;
    } else if (blockchain.value === 'ethereum') {
      return EthereumService.getInstance() as BlockchainService;
    }
  }, [blockchain]);

  if (!blockchainService) return null;

  return <BlockchainServiceContext.Provider value={blockchainService}>{children}</BlockchainServiceContext.Provider>;
};
